import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { t } from "i18next";

const rootId = {
  works: "works",
};
type RootId = (typeof rootId)[keyof typeof rootId];
const rootTitle = {
  works: t("word.作品"),
};

export interface SelectedContentState {
  rootId: RootId;
  rootTitle: string;
  workId: string;
  workTitle: string;
  episodeId: string;
  episodeTitle: string;
  cutId: string;
  cutTitle: string;
  processId: string;
  processTitle: string;
}

const initialState: SelectedContentState = {
  rootId: rootId.works,
  rootTitle: rootTitle.works,
  workId: "",
  workTitle: "",
  episodeId: "",
  episodeTitle: "",
  cutId: "",
  cutTitle: "",
  processId: "",
  processTitle: "",
};

interface SelectedContentPayload {
  id: string;
  title: string;
}

const slice = createSlice({
  name: "selectedContent",
  initialState,
  reducers: {
    initSelectedContent: () => initialState,
    resetSelectedContent: (state) => {
      state.rootId = initialState.rootId;
      state.rootTitle = initialState.rootTitle;
      state.workId = initialState.workId;
      state.episodeId = initialState.episodeId;
      state.cutId = initialState.cutId;
      state.processId = initialState.processId;
    },
    setSelectedWorks: (state) => {
      state.rootId = rootId.works;
      state.rootTitle = rootTitle.works;
      state.workId = initialState.workId;
      state.episodeId = initialState.episodeId;
      state.cutId = initialState.cutId;
      state.processId = initialState.processId;
    },
    setSelectedWork: (state, action: PayloadAction<{ work: SelectedContentPayload }>) => {
      state.rootId = rootId.works;
      state.rootTitle = rootTitle.works;
      state.workId = action.payload.work.id;
      state.workTitle = action.payload.work.title;
      state.episodeId = initialState.episodeId;
      state.cutId = initialState.cutId;
      state.processId = initialState.processId;
    },
    setSelectedEpisode: (
      state,
      action: PayloadAction<{ work: SelectedContentPayload; episode: SelectedContentPayload }>,
    ) => {
      state.rootId = rootId.works;
      state.rootTitle = rootTitle.works;
      state.workId = action.payload.work.id;
      state.workTitle = action.payload.work.title;
      state.episodeId = action.payload.episode.id;
      state.episodeTitle = action.payload.episode.title;
      state.cutId = initialState.cutId;
      state.processId = initialState.processId;
    },
    setSelectedCut: (
      state,
      action: PayloadAction<{
        work: SelectedContentPayload;
        episode: SelectedContentPayload;
        cut: SelectedContentPayload;
      }>,
    ) => {
      state.rootId = rootId.works;
      state.rootTitle = rootTitle.works;
      state.workId = action.payload.work.id;
      state.workTitle = action.payload.work.title;
      state.episodeId = action.payload.episode.id;
      state.episodeTitle = action.payload.episode.title;
      state.cutId = action.payload.cut.id;
      state.cutTitle = action.payload.cut.title;
      state.processId = initialState.processId;
    },
    setSelectedProcess: (
      state,
      action: PayloadAction<{
        work: SelectedContentPayload;
        episode: SelectedContentPayload;
        cut: SelectedContentPayload;
        process: SelectedContentPayload;
      }>,
    ) => {
      state.rootId = rootId.works;
      state.rootTitle = rootTitle.works;
      state.workId = action.payload.work.id;
      state.workTitle = action.payload.work.title;
      state.episodeId = action.payload.episode.id;
      state.episodeTitle = action.payload.episode.title;
      state.cutId = action.payload.cut.id;
      state.cutTitle = action.payload.cut.title;
      state.processId = action.payload.process.id;
      state.processTitle = action.payload.process.title;
    },
  },
});

export const selectedContentReducer = slice.reducer;

export const {
  initSelectedContent,
  resetSelectedContent,
  setSelectedWorks,
  setSelectedWork,
  setSelectedEpisode,
  setSelectedCut,
  setSelectedProcess,
} = slice.actions;

export const selectSelectedContent = (state: RootState) => state.selectedContent;
