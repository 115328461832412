import React from "react";
import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { Control, Controller } from "react-hook-form";

// styled component
const TextFieldComponent = styled(TextField)({
  background: "#fff",
  margin: 5,
  height: 80,
  width: 250,
});

export type InputTextProps = {
  name: string;
  label: string;
  control: Control | undefined;
  defaultValue: string | undefined;
  rules: { required: string };
  type: string;
  autoFocus?: boolean;
};

export const InputText: React.FC<InputTextProps> = (props: InputTextProps) => {
  const { name, label, control, rules, defaultValue, type, autoFocus } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <TextFieldComponent
          {...field}
          type={type}
          label={label}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          size="medium"
          color="primary"
          autoComplete={type === "password" ? "current-password" : "username"}
          autoFocus={autoFocus}
        />
      )}
    />
  );
};
