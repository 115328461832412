import styled from "@emotion/styled";
import React from "react";
import { t } from "i18next";

const StyledStatusChips = styled("div")(() => ({
  borderRadius: "10px",
  width: "48px",
  height: "23px",
  paddingTop: "3px",
  color: "#000000",
  fontSize: "13px",
  textAlign: "center",
  backgroundColor: "rgb(255,237,112)",
}));

export const DougaStatusChips: React.FC = () => {
  return <StyledStatusChips>{t("word.動画")}</StyledStatusChips>;
};
