import { Thumbnail } from "../modules/api.types";
import { makeURL } from "./cdnUtil";

/**
 * srcに設定する指定サイズのサムネイルURLを作成
 *
 * @param {string} cdnUrl CDN URL
 * @param {Thumbnail} thumbnail サムネイル
 * @param {string} size サムネイルサイズ original, large, medium, small
 * @returns {string} サムネイルURL "cdnUrl/../../size.webp"
 */
export const createSrc = (cdnUrl: string, thumbnail: Thumbnail, size: string): string => {
  return makeURL(cdnUrl, thumbnail.path + size + "." + thumbnail.ext, thumbnail.updatedAt);
};

/**
 * srcSetに設定する largeとmediumサイズのサムネイル画像URLを作成
 *
 * @param {string} cdnUrl CDN URL
 * @param {Thumbnail} thumbnail サムネイル
 * @returns {string} サムネイルURL srcSet "cdnUrl/../../large.webp 1.5x, cdnUrl/../../medium.webp"
 */
export const createSrcSet = (cdnUrl: string, thumbnail: Thumbnail): string => {
  const thumbnails = [createSrc(cdnUrl, thumbnail, "large"), createSrc(cdnUrl, thumbnail, "medium")];

  type condition = "large" | "medium";
  const condDict = {
    large: "1.5x",
    medium: "",
  };
  let res = "";
  for (const v of thumbnails) {
    const filename = (v.split("/").at(-1) ?? "").split(".")[0];
    const c = filename as condition;
    res += (res === "" ? "" : ", ") + v + (condDict[c] ? " " + condDict[c] : "");
  }
  return res;
};
