import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useAddEpisodeMutation } from "../../../modules/api";
import { useMessage } from "../../../hooks/useMessage";
import { compareString } from "../../../utils/sortUtil";
import { paddingEpisodeNo } from "../../../utils/makeTitleUtil";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface AddEpisodeDialogProps {
  open: boolean;
  workId: string;
  episodeTitles: Array<{ no: string; subtitle: string }>;
  onClose: () => void;
}

export const AddEpisodeDialog: React.FC<AddEpisodeDialogProps> = (props) => {
  const { open, workId, episodeTitles, onClose } = props;
  const minNo = 1;
  const maxNo = 99;
  const [isUseNo, setIsUseNo] = useState<boolean>(true);
  const [newNo, setNewNo] = useState<number>(minNo);
  const [newSubTitle, setNewSubTitle] = useState<string>("");
  const [newNoError, setNewNoError] = useState<string | null>(null);
  const [newSubTitleError, setNewSubTitleError] = useState<string | null>(null);
  const showMessage = useMessage();
  const [addEpisode] = useAddEpisodeMutation();

  // newNoの初期値設定
  useEffect(() => {
    if (episodeTitles.length > 0) {
      const last = episodeTitles
        .map((item) => {
          return { no: item.no.replace(/[^0-9]/g, "") };
        })
        .sort((a, b) => compareString(b.no, a.no))
        .at(0);
      setNewNo((last?.no ? Number.parseInt(last.no) : 0) + 1);
    } else {
      setNewNo(minNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (isUseNo: boolean, no: string | undefined, subTitle: string | undefined): string | null => {
    // console.log({ no }); // debug
    if (isUseNo) {
      if (!no || no.length === 0) return t("message.話数を入力してください");
      if (no.match(/[e.,+-]/i)) return t("message.1 ～ 99 の整数を入力してください");
      const num = Number.parseInt(no);
      if (num < 1 || 99 < num) return t("message.1 ～ 99 を入力してください");
      return episodeTitles.every((item) => Number.parseInt(item.no?.replace(/[^0-9]/g, "")) !== num)
        ? null
        : t("message.number があるので登録できません", { number: paddingEpisodeNo(String(num)) });
    } else {
      if (!subTitle || subTitle.length === 0) return t("message.タイトルを入力してください");
      return episodeTitles.every((item) => item.subtitle !== subTitle)
        ? null
        : t("message.title は既に登録済みです", { title: subTitle });
    }
  };

  const setValidationResult = (isCheckedUseNo: boolean, result: string | null) => {
    // console.log(`Episode setValidationResult( isUseNo: ${isUseNoChecked}, result: ${result})`); // debug
    if (isCheckedUseNo) {
      // noあり（対象はnoのみ。subTitleのエラーはクリア）
      setNewNoError(result);
      setNewSubTitleError(null);
    } else {
      // noなし（対象はsubtitleのみ。noのエラーはクリア）
      setNewNoError(null);
      setNewSubTitleError(result);
    }
  };

  const handleChangeCheck = (checked: boolean) => {
    // console.log(`AddEpisodeDialog: onChange(checked: ${checked}), isUseNo: ${isUseNo}`); // debug
    setIsUseNo(checked);
    const result = validate(checked, newNo > 0 ? String(newNo) : undefined, newSubTitle);
    setValidationResult(checked, result);
  };
  const handleChangeNo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const no = e.target.value;
    setNewNo(Number.parseInt(no));
    const result = validate(isUseNo, no, newSubTitle);
    setValidationResult(isUseNo, result);
  };
  const handleChangeSubTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const subTitle = e.target.value;
    setNewSubTitle(subTitle);
    const result = validate(isUseNo, newNo > 0 ? String(newNo) : undefined, subTitle);
    setValidationResult(isUseNo, result);
  };

  const makeNewTitle = (): string => {
    if (isUseNo && newNo && newSubTitle) {
      return String(newNo) + " - " + newSubTitle;
    } else if (isUseNo && newNo) {
      return String(newNo);
    } else if (newSubTitle) {
      return newSubTitle;
    }
    return "";
  };

  const onSubmit = () => {
    const newTitle = makeNewTitle();
    // console.log(`Episode( isUseNo: ${isUseNo}, newNo: ${newNo}, newSubTitle: ${newSubTitle}) => ${newTitle}`); // debug
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(isUseNo, newNo > 0 ? String(newNo) : undefined, newSubTitle);
    if (newNoError || newSubTitleError || err) {
      return;
    }

    const no = isUseNo ? String(newNo) : "";
    const msgTitle = isUseNo ? newTitle : newSubTitle;
    // console.log(`ADD an Episode: no="${newNo}", subtitle="${newSubTitle}"`); // debug
    addEpisode({
      workId: workId,
      episode: { no: no, subtitle: newSubTitle ?? "" },
    })
      .unwrap()
      .then(() => {
        showMessage({ message: t("message.msgTitle を追加しました。", { msgTitle }) });
      })
      .catch((addEpisodeResError: unknown) => {
        console.log({ addEpisodeResError });
        showMessage({ message: t("message.msgTitle の追加に失敗しました。", { msgTitle }) });
      });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.話数を追加")}
      submitButtonText={t("word.追加")}
      submitButtonDisabled={newNoError !== null || newSubTitleError !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <Stack spacing={1}>
          <Stack direction={"row"}>
            <FormControlLabel
              id="add-episode-use-no"
              control={<Checkbox />}
              sx={{ paddingBottom: "10px" }}
              label={t("phrase.先頭に話数を表示")}
              checked={isUseNo}
              onChange={(e, checked) => handleChangeCheck(checked)}
            />
            <AutoFocusTextField
              id="add-episode-new-no"
              sx={{ marginTop: "10px", height: "64px", width: "250px" }}
              type={"number"}
              inputProps={{ min: minNo, max: maxNo }}
              disabled={!isUseNo}
              error={newNoError ? true : false}
              helperText={newNoError}
              value={Number.isNaN(newNo) ? "" : newNo}
              onChange={(e) => handleChangeNo(e)}
            />
          </Stack>
          <TextField
            id="add-episode-new-title"
            sx={{ height: "64px", width: "426px" }}
            label={t("phrase.話数のタイトル")}
            helperText={newSubTitleError}
            inputProps={{ min: minNo, max: maxNo, "aria-label": "episode-text-of-additional-episode" }}
            error={newSubTitleError ? true : false}
            value={newSubTitle}
            onChange={(e) => handleChangeSubTitle(e)}
          />
        </Stack>
      </FormGroup>
    </FormDialog>
  );
};
