import React, { useEffect, useRef } from "react";
import { useMessage } from "./useMessage";
import { useAddWorkThumbnailMutation } from "../modules/api";
import { useConfirmDialog } from "./useConfirmDialog";
import { t } from "i18next";

export const useRegisterThumbnail = (): {
  inputFileRef: React.RefObject<HTMLInputElement>;
  doRegisterThumbnail: (id: string) => void;
} => {
  const [addWorkThumbnail] = useAddWorkThumbnailMutation();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const registerThumbnailTargetId = useRef<string>();
  const showMessage = useMessage();
  const showConfirmDialog = useConfirmDialog();

  const doRegisterThumbnail = (id: string) => {
    registerThumbnailTargetId.current = id;
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const selectFileHandler = (ev: Event): void => {
    const e = ev as unknown as React.ChangeEvent<HTMLInputElement>;
    if (e.target && e.target.files && registerThumbnailTargetId.current) {
      const workId = registerThumbnailTargetId.current;
      const file = e.target.files[0];
      // Lambdaの制限があるので6MB以下のファイルのみ追加可能にする
      if (file.size > 6 * 1024 * 1024) {
        console.log(`Too large image. (fileName: ${file.name}, size: ${file.size})`);
        showConfirmDialog({
          title: "",
          message: t("message.追加できる画像ファイルのサイズは6MB以下です。"),
          buttons: [
            {
              text: t("word.閉じる"),
              defaultButton: true,
              onClick: () => {
                // do nothing
              },
            },
          ],
        });
      } else {
        addWorkThumbnail({ workId: workId, file: file })
          .unwrap()
          .then(() => {
            // console.log(`thumbnail(${file.name}) has registered to ${workId}`); // debug
          })
          .catch((error) => {
            console.log(`addWorkThumbnail Error: ${JSON.stringify(error)}`);
            showMessage({ message: t("message.画像を追加できませんでした。") });
          });
      }
    }
    e.preventDefault();
    e.stopPropagation();
    registerThumbnailTargetId.current = undefined;
    // もう一度選択可能にするためにクリア
    e.target.value = "";
  };

  useEffect(() => {
    if (inputFileRef.current) {
      const inputFile = inputFileRef.current;
      inputFile.addEventListener("change", selectFileHandler);
      return () => inputFile.removeEventListener("change", selectFileHandler);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { inputFileRef, doRegisterThumbnail };
};
