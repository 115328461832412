import React from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "@emotion/styled";

const StyledButton = styled(Button)<SelectButtonProps>((props) => ({
  margin: "6px 2px 14px",
  padding: "8px",
  transition: "0s",
  color: "#424242",
  boxShadow: props.boxshadow ? props.boxshadow : "none",
  borderRadius: "0%",
  background: props.selected ? "#E0EEFF" : "#FFFFFF",
  "&:hover": {
    background: props.selected ? "#E0EEFF" : "#ECF4FE",
    boxShadow: props.boxshadow ? props.boxshadow : "none",
  },
  "&:active": { background: "#E0EEFF", boxShadow: props.boxshadow ? props.boxshadow : "none" },
  textTransform: "none", // サムネイルビューのカード上の英語が全部大文字になってしまうのを抑止
  fontWeight: "400", // デフォルトは500
}));

type SelectButtonProps = {
  selected: boolean;
  boxshadow?: string;
} & ButtonProps;

const ItemButton: React.FC<SelectButtonProps> = (props) => {
  return <StyledButton {...props} disableRipple disableElevation />;
};

export default ItemButton;
