import * as tagTypes from "./tagTypes";
import { GetWorksResponse } from "../api.types";

// 作品のタグ
const typeItems = tagTypes.Work;

// 作品一覧取得時の提供タグ
export const providesWorksTags = (result: GetWorksResponse | undefined) =>
  result
    ? [...result.items.map(({ id }) => ({ type: typeItems, id })), { type: typeItems, id: tagTypes.ListId }]
    : [{ type: typeItems, id: tagTypes.ListId }];
