import React, { useEffect, useRef, useState } from "react";
import { Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface TreeItemLabelProps {
  to: string;
  title: string;
}

export const TreeItemLabel = (props: TreeItemLabelProps) => {
  const [disableTooltip, setDisableTooltip] = useState(true);
  const elementRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (elementRef.current && elementRef.current.scrollWidth > elementRef.current.clientWidth) {
        setDisableTooltip(false);
      } else {
        setDisableTooltip(true);
      }
    });
    elementRef.current && resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Tooltip title={props.title} disableHoverListener={disableTooltip}>
      <Link
        ref={elementRef}
        component={RouterLink}
        to={props.to}
        underline="none"
        color="#424242"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        display="block"
        fontSize="14px"
        height="32px"
        paddingTop="5px"
      >
        {props.title}
      </Link>
    </Tooltip>
  );
};
