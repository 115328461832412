import { Buffer } from "buffer";

/**
 * ID Tokenをデコードして有効性チェックに必要なペイロードを取得する
 * @param idToken ID Token
 * @returns ID Tokenをデコードしたオブジェクト
 */
const decodeIdToken = (idToken: string): object => {
  try {
    const payload = idToken.split(".")[1];
    return JSON.parse(Buffer.from(payload, "base64").toString("utf8")) as object;
  } catch (err) {
    return {};
  }
};

/**
 * ID Tokenが有効かチェックする
 * @param idToken ID Token
 * @param authTime ID Tokenを取得した時間
 * @returns true if valid, false if invalid.
 */
export const isValidToken = (idToken: string | null, authTime: number | null): boolean => {
  if (!idToken || !authTime) {
    return false;
  }
  const payload = decodeIdToken(idToken);
  if (!("exp" in payload && "iat" in payload)) {
    return false;
  }
  const currentSeconds = Math.floor(new Date().valueOf() / 1000);
  const exp = payload.exp as number;
  const iat = payload.iat as number;
  const clockDrift = authTime - iat;
  // console.log(
  //   `isValidToken(currentSeconds: ${currentSeconds}, idToken.exp: ${exp}, authTime: ${authTime}, idToken.issuedAt: ${iat} -> remain: ${
  //     exp - currentSeconds - clockDrift
  //   }) => ${String(currentSeconds - clockDrift < exp)}`,
  // );
  // debug
  return currentSeconds - clockDrift < exp;
};
