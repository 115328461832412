import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
import { ContentIcon } from "../ContentIcons";

interface ListViewIconProps {
  icon:
    | SvgIconComponent
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
}

export const ListViewIcon: React.FC<ListViewIconProps> = (props) => {
  const { icon } = props;
  return <ContentIcon icon={icon} iconStyle={{ height: "30px" }} />;
};
