import styled from "@emotion/styled";
import React from "react";
import { t } from "i18next";

const StyledStatusChips = styled("div")(() => ({
  borderRadius: "10px",
  width: "80px",
  height: "23px",
  paddingTop: "4px",
  paddingBottom: "2px",
  color: "#fff",
  fontSize: "12px",
  textAlign: "center",
  backgroundColor: "#BC405D",
}));

export const LayoutStatusChips: React.FC = () => {
  return <StyledStatusChips>{t("word.レイアウト")}</StyledStatusChips>;
};
