import React from "react";
import { Button, Tooltip } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import styled from "@emotion/styled";

export interface IconButtonProps {
  tooltip: string;
  icon: SvgIconComponent;
  iconStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const StyledButton = styled(Button)({
  backgroundColor: "#FFF",
  color: "#666",
  "&:hover": {
    background: "#FFF",
  },
  "&.MuiButton-root": {
    minWidth: "32px",
  },
});

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { tooltip: title, icon: Icon, iconStyle, buttonStyle, onClick } = props;
  return (
    <Tooltip title={title}>
      <StyledButton onClick={onClick} style={buttonStyle} disableRipple>
        <Icon style={iconStyle} />
      </StyledButton>
    </Tooltip>
  );
};
