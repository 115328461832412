import React from "react";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { BaseButton } from "./BaseButton";
import { t } from "i18next";

interface NextButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const NextButton: React.FC<NextButtonProps> = (props) => {
  return (
    <div style={{ position: "relative", top: "50%", float: "right", marginRight: "20px" }}>
      <BaseButton {...props} tooltip={t("word.次へ")} icon={ChevronRightOutlinedIcon} />
    </div>
  );
};
