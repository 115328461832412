import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import styled from "@emotion/styled";

const StyledWarningIcon = styled(WarningAmberIcon)({
  fontSize: 20,
  color: "#9e9d24",
});

export const WarningIcon: React.FC = () => {
  return <StyledWarningIcon />;
};
