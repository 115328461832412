import React from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form";
import { t } from "i18next";

import { useAppDispatch } from "../../../hooks/rtk-hooks";
import { useMessage } from "../../../hooks/useMessage";
import { useLoginMutation } from "../../../modules/api";
import { setIdToken, setRefreshToken } from "../../../modules/authSlice";
import { InputText } from "../../atoms/InputText";
import AppLogo from "../../atoms/AppLogo";

interface LoginProps {
  onSetNeedChallenge: (session: string, userId: string) => void;
  email: string;
}

const StyledLoginTitle = styled("div")({
  marginBottom: 30,
  fontSize: "25px",
});
const StyledLoginButtonContainer = styled("div")({
  marginTop: 10,
});

type LoginFormInputs = {
  password?: string | undefined;
};

export const Login: React.FC<LoginProps> = (props) => {
  const { onSetNeedChallenge, email } = props;
  const navigate = useNavigate();
  const showMessage = useMessage();
  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();

  const defaultValues: DefaultValues<LoginFormInputs> = { password: "" };
  const validationRules = { password: { required: t("message.パスワードを入力してください。") } };
  const { control, handleSubmit, reset } = useForm<LoginFormInputs>({ defaultValues });

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data: LoginFormInputs) => {
    // validationRulesを設定しているので、data.email, data.passwordは空ではない状態のはず。
    process.env.NODE_ENV === "development" &&
      console.log(`{"email": "${email ?? ""}", "password": "${data.password ?? ""}"}`);

    // ログイン認証を実行する
    await login({ email: email, password: data.password as string })
      .unwrap()
      .then((loginResponse) => {
        // console.log({ loginResponse }); // debug
        if ("ChallengeName" in loginResponse) {
          // 新規にパスワード設定が必要
          const session = loginResponse["Session"];
          const userId = loginResponse["ChallengeParameters"]["USER_ID_FOR_SRP"];
          reset();
          onSetNeedChallenge(session, userId);
        } else if ("AuthenticationResult" in loginResponse) {
          // ログイン成功
          const idToken = loginResponse["AuthenticationResult"]["IdToken"];
          const currentSeconds = Math.floor(new Date().valueOf() / 1000);
          dispatch(setIdToken({ idToken: idToken, authTime: currentSeconds }));
          // console.log({ idToken }); // debug
          const refreshToken = loginResponse["AuthenticationResult"]["RefreshToken"];
          dispatch(setRefreshToken({ refreshToken: refreshToken }));
          // console.log({ refreshToken }); // debug
          // 作品一覧へ遷移
          navigate("/works");
        }
      })
      .catch((loginError: unknown) => {
        // ログイン失敗
        console.log({ loginError });
        showMessage({ message: t("message.ログインに失敗しました") });
      });
  };

  return (
    <>
      <StyledLoginTitle>
        <div>
          <AppLogo />
        </div>
        <div>{t("word.ログイン")}</div>
      </StyledLoginTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputText
            name="password"
            label={t("word.パスワード")}
            control={control}
            defaultValue={defaultValues.password}
            rules={validationRules.password}
            type="password"
            autoFocus={true}
          />
        </div>
        <StyledLoginButtonContainer>
          <Button variant="contained" type="submit">
            {t("word.ログイン")}
          </Button>
        </StyledLoginButtonContainer>
      </form>
    </>
  );
};
