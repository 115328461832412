import React from "react";
import { ProgressBar, ProgressProps } from "../ProgressBar";
import { AlertBar, AlertProps } from "../AlertBar";

export type MessageBarProps = ProgressProps | AlertProps;

export const MessageBar: React.FC<MessageBarProps> = (props) => {
  if (props.messages.length === 0) return null;
  if ("alertType" in props) return <AlertBar {...props} />;
  else return <ProgressBar {...props} />;
};
