import React, { createContext, RefObject, useContext, useRef } from "react";

export const ScrollRestorationContext = createContext<{
  onScrollForRestoration: (path: string, e: React.UIEvent<Element, UIEvent>) => void;
  restoreAndStartStoring: (path: string, ref: RefObject<HTMLDivElement>) => void;
}>({
  onScrollForRestoration: () => {
    // do nothing
  },
  restoreAndStartStoring: () => {
    // do nothing
  },
});

export const useScrollRestoration = () => useContext(ScrollRestorationContext);

export const ScrollRestorationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // pageType(Mapのkey)毎に復元対象のidとそのスクロール位置を保持。
  const scrollPositions = useRef<Map<string, { id: string; top: number }>>(new Map());
  const getPageTypeAndId = (path: string): string[] => {
    // pathは基本的にLocation.pathnameで "/{pageType}/{id}" の形式
    const paths = path.split("/");
    const type = paths[1];
    const id = paths.length > 2 ? paths[2] : "always";
    return [type, id];
  };
  const onScrollForRestoration = (path: string, e: React.UIEvent<Element, UIEvent>) => {
    const [pageType, id] = getPageTypeAndId(path);
    const page = scrollPositions.current.get(pageType);
    // console.log(
    //   `useScrollRestoration.onScroll() path: ${path}, scrollTop: ${e.currentTarget.scrollTop} => page: ${page} (id: ${page?.id})`,
    // ); // debug
    if (page && page.id === id) {
      scrollPositions.current.set(pageType, { id: id, top: e.currentTarget.scrollTop });
    }
  };
  const restoreAndStartStoring = (path: string, ref: RefObject<HTMLDivElement>) => {
    const [pageType, id] = getPageTypeAndId(path);
    const page = scrollPositions.current.get(pageType);
    // console.log(
    //   `useScrollRestoration.restore() path: ${path}, ref.current: ${ref.current}, page: ${page} (id: ${page?.id}, top: ${page?.top})`,
    // ); // debug
    if (page && page.id === id && page.top) {
      if (ref.current) {
        ref.current.scrollTop = page.top;
      }
    } else {
      // console.log(`useScrollRestoration.restore() RESET scroll position!`); // debug
      if (ref.current) {
        ref.current.scrollTop = 0;
      }
      scrollPositions.current.set(pageType, { id: id, top: 0 });
    }
  };
  return (
    <ScrollRestorationContext.Provider value={{ onScrollForRestoration, restoreAndStartStoring }}>
      {children}
    </ScrollRestorationContext.Provider>
  );
};
