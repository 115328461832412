import { CutsLatestFilesItem } from "../../modules/api.types";
import { FolderIcon } from "./FolderIcon";

export class CutFolderIcon extends FolderIcon {
  /**
   * CutFolderIcon
   * @param latestFiles カットの工程情報（エピソード情報（カット一覧）取得APIのレスポンス参照）
   */
  constructor(protected readonly latestFiles: Array<CutsLatestFilesItem> | undefined) {
    let isUploaded = false; // 工程情報がない場合はファイルが「アップロードされていない」ものとする
    if (latestFiles) {
      // 工程の情報あり。
      // latestFileUpdatedAtが設定されている工程が見つかったらファイルがアップロードされている
      isUploaded = latestFiles.some((v) => v.latestFileUpdatedAt !== null);
    }
    super(isUploaded);
  }
}
