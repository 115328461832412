import React from "react";
import { IconButton, IconButtonProps } from "../IconButton";

export const BaseButton: React.FC<IconButtonProps> = (props) => {
  return (
    <IconButton
      {...props}
      iconStyle={{ minWidth: "24px" }}
      buttonStyle={{
        padding: "6px",
        minWidth: "24px",
        minHeight: "24px",
        borderRadius: "50%",
        color: "#f5f5f5",
        backgroundColor: "rgba(0, 0, 0, .85)",
        zIndex: "1",
      }}
    />
  );
};
