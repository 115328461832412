import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface TimeSheetFileIconProps {
  style?: React.CSSProperties;
}

export const TimeSheetFileIcon: React.FC<TimeSheetFileIconProps> = (props) => {
  return <AccessTimeIcon {...props} />;
};
