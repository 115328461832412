import React, { useEffect, useRef } from "react";
import { DriveFolderUploadOutlined as DriveFolderUploadOutlinedIcon } from "@mui/icons-material";
import { UploadButton } from "../../atoms/UploadButton/UploadButton";
import { t } from "i18next";
export interface FolderUploadButtonProps {
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FolderUploadButton: React.FC<FolderUploadButtonProps> = (props) => {
  const { onInputFileChange } = props;
  const folderInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // 下記はReactのinput要素のtypeに含まれていないので後から追加
    folderInputRef.current?.setAttribute("directory", "");
    folderInputRef.current?.setAttribute("webkitdirectory", "");
  }, []);

  return (
    <UploadButton
      tooltip={t("phrase.フォルダのアップロード")}
      kind={"folder"}
      icon={DriveFolderUploadOutlinedIcon}
      iconStyle={{ color: "#666", cursor: "pointer" }}
      onInputFileChange={onInputFileChange}
      inputRef={folderInputRef}
    />
  );
};
