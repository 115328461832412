import * as tagTypes from "./tagTypes";
import { GetEpisodeResponse, UpdateEpisodeResponse } from "../api.types";

// エピソード情報のタグ
const type = tagTypes.Episode;

// エピソード情報に含まれる一覧項目のタグ
const typeItems = tagTypes.Cut;

// 絵コンテが追加された場合、絵コンテ情報がエピソード情報取得APIで取得できるなら、
// ・エピソード情報取得時の提供タグにエピソードID指定を追加
// ・エピソードID指定の無効タグを追加して、絵コンテアップロードAPIに設定

// エピソード情報取得時の提供タグ
export const providesEpisodeTags = (result: GetEpisodeResponse | undefined) =>
  result
    ? [
        { type: type, id: result.episode.id },
        ...result.cuts.map(({ id }) => ({ type: typeItems, id })),
        { type: typeItems, id: tagTypes.ListId },
      ]
    : [{ type: typeItems, id: tagTypes.ListId }];

// エピソード情報一覧に影響がある場合の無効タグ
export const invalidatesEpisodeListTags = [{ type: type, id: tagTypes.ListId }];

// エピソード情報(カット一覧含まず)に影響がある場合の無効タグ
export const invalidatesUpdateEpisodeTags = (result: UpdateEpisodeResponse | undefined) =>
  result ? [{ type: type, id: result.id }] : [];
