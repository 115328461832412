import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: { boxShadow: "none", variant: "dense", component: "div" },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiGrid: {
      defaultProps: {
        container: true,
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        color: "#424242",
      },
    },
    MuiImageList: {
      defaultProps: {
        gap: 0,
        cols: 0,
      },
    },
    MuiImageListItem: {
      defaultProps: {
        sx: { height: "100%", justifyContent: "space-between" },
      },
    },
    MuiListItem: {
      defaultProps: {
        sx: { backgroundColor: "#ffffff", overflowWrap: "break-word" },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: { cursor: "pointer" },
        disableRipple: true,
      },
    },
    MuiTable: {
      defaultProps: {
        sx: { borderCollapse: "separate", borderSpacing: "0px" },
      },
    },
    MuiTableContainer: {
      defaultProps: {
        sx: { boxShadow: 0 },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
        disableFocusListener: true,
        disableTouchListener: true,
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        color: "text.primary",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});
