import * as tagTypes from "./tagTypes";
import { GetCutResponse, UpdateCutResponse } from "../api.types";

// カット情報のタグ
const type = tagTypes.Cut;

// カット情報に含まれる一覧項目のタグ
const typeItems = tagTypes.Process;

// カット情報取得時の提供タグ
export const providesCutTags = (result: GetCutResponse | undefined) =>
  result
    ? [
        { type: type, id: result.cut.id },
        ...result.processes.map(({ id }) => ({ type: typeItems, id })),
        { type: typeItems, id: tagTypes.ListId },
      ]
    : [{ type: typeItems, id: tagTypes.ListId }];

// カット情報一覧に影響がある場合の無効タグ
export const invalidatesCutListTags = [{ type: type, id: tagTypes.ListId }];

// カット情報(工程一覧含まず)に影響がある場合の無効タグ
export const invalidatesUpdateCutTags = (result: UpdateCutResponse | undefined) =>
  result ? [{ type: type, id: result.id }] : [];
