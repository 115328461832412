import React from "react";
import { IconInterface } from "./IconInterface";
import { ContentIcon, NotImageFileIcon, TimeSheetFileIcon } from "../../components/atoms/ContentIcons";
import { isFileTimeSheet } from "../../utils/fileNameUtil";
import NoImage from "../../components/atoms/NoImage";
import { ListViewIcon } from "../../components/atoms/ListViewIcon";
import ListViewNoImage from "../../components/atoms/ListViewNoImage";

export class FileIcon implements IconInterface {
  protected listViewIcon: React.ReactNode = null;
  protected cardViewIcon: React.ReactNode = null;
  protected iconStyleSize: React.CSSProperties = {};
  protected width = 0;
  protected height = 0;
  constructor(protected readonly path: string, protected readonly imageFileRegexp?: RegExp) {
    // リストビュー用アイコン
    if (isFileTimeSheet(this.path)) {
      // タイムシートファイル
      this.listViewIcon = <ListViewIcon icon={TimeSheetFileIcon} />;
    } else if (this.imageFileRegexp && !this.path.match(this.imageFileRegexp)) {
      // 画像ファイル以外
      this.listViewIcon = <ListViewIcon icon={NotImageFileIcon} />;
    } else {
      // サムネイルのない画像ファイル
      this.listViewIcon = <ListViewNoImage />;
    }
  }
  setSize(iconStyleSize: React.CSSProperties, width: number, height: number): IconInterface {
    this.iconStyleSize = iconStyleSize;
    this.width = width;
    this.height = height;
    // カードビュー用アイコン
    if (isFileTimeSheet(this.path)) {
      // タイムシートファイル
      this.cardViewIcon = <ContentIcon icon={TimeSheetFileIcon} iconStyle={this.iconStyleSize} />;
    } else if (this.imageFileRegexp && !this.path.match(this.imageFileRegexp)) {
      // 画像ファイル以外
      this.cardViewIcon = <ContentIcon icon={NotImageFileIcon} iconStyle={this.iconStyleSize} />;
    } else {
      // サムネイルのない画像ファイル
      this.cardViewIcon = (
        <NoImage width={`${this.width}px`} height={`${this.height}px`} iconStyle={this.iconStyleSize} />
      );
    }
    return this;
  }
  get listView(): React.ReactNode {
    return this.listViewIcon;
  }
  get cardView(): React.ReactNode {
    return this.cardViewIcon;
  }
}
