import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { TableCell, TableCellProps, Tooltip } from "@mui/material";

const StyledMuiTableCell = styled(TableCell)({
  paddingTop: "3px",
  paddingBottom: "3px",
  paddingLeft: "0px",
  align: "left",
  minWidth: "128px",
  fontSize: "13px",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: "256px", //暫定
  "th&:first-of-type": {
    paddingLeft: "16px",
  },
});

export const StyledTableCell: React.FC<TableCellProps> = (props) => {
  const [isShowEllipsis, setIsShowEllipsis] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);

  // 表示領域サイズ変更リスナ登録/解除
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (elementRef.current && elementRef.current.offsetWidth < elementRef.current.scrollWidth) {
        setIsShowEllipsis(true);
      } else {
        setIsShowEllipsis(false);
      }
    });
    elementRef.current && resizeObserver.observe(elementRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  return (
    <>
      {isShowEllipsis ? (
        <Tooltip title={String(props.children)}>
          <StyledMuiTableCell {...props} ref={elementRef}></StyledMuiTableCell>
        </Tooltip>
      ) : (
        <StyledMuiTableCell {...props} ref={elementRef}></StyledMuiTableCell>
      )}
    </>
  );
};
