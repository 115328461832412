import React from "react";
import { Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import { useAppSelector } from "../../../hooks/rtk-hooks";
import { selectSelectedContent } from "../../../modules/titleSlice";

const StyledPageBreadcrumbs = styled("div")({
  textAlign: "left",
  fontSize: 20,
  marginLeft: "16px",
});

interface BreadcrumbsProps {
  height: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { rootId, rootTitle, workId, workTitle, episodeId, episodeTitle, cutId, cutTitle, processId, processTitle } =
    useAppSelector(selectSelectedContent);
  return (
    <StyledPageBreadcrumbs {...props}>
      <div role="presentation">
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} to={`/${rootId}`}>
            {rootTitle}
          </Link>
          {workId.length ? (
            <Link component={RouterLink} to={`/work/${workId}`}>
              {workTitle}
            </Link>
          ) : null}
          {episodeId.length ? (
            <Link component={RouterLink} to={`/episode/${episodeId}`}>
              {episodeTitle}
            </Link>
          ) : null}
          {cutId.length ? (
            <Link component={RouterLink} to={`/cut/${cutId}`}>
              {cutTitle}
            </Link>
          ) : null}
          {processId.length ? (
            <Link component={RouterLink} to={`/process/${processId}`}>
              {processTitle}
            </Link>
          ) : null}
        </MuiBreadcrumbs>
      </div>
    </StyledPageBreadcrumbs>
  );
};
