import React from "react";
import styled from "@emotion/styled";
import { SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

interface UploadButtonProps {
  tooltip: string;
  kind: string; // file / folder
  icon: SvgIconComponent;
  iconStyle: React.CSSProperties;
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
}

const UploadIconTooltip = styled(Tooltip)({
  minWidth: "32px",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
});
const StyledInputFile = styled("input")({ display: "none" });

export const UploadButton: React.FC<UploadButtonProps> = (props) => {
  const { tooltip, kind, icon: Icon, iconStyle, onInputFileChange, accept, inputRef } = props;
  return (
    <UploadIconTooltip title={tooltip}>
      <label htmlFor={`upload-button-for-${kind}`}>
        <StyledInputFile
          type="file"
          id={`upload-button-for-${kind}`}
          data-testid={`upload-button-for-${kind}`}
          onChange={onInputFileChange}
          multiple
          accept={accept}
          ref={inputRef}
        />
        <Icon style={iconStyle} />
      </label>
    </UploadIconTooltip>
  );
};
