import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppDispatch, useAppSelector } from "../hooks/rtk-hooks";
import { SelectedIdProvider } from "../hooks/contexts/SelectedIdProvider";
import { setSelectedEpisode } from "../modules/titleSlice";
import { selectCutsSortOrder, selectCutsViewMode, setCutsViewMode } from "../modules/viewConfigSlice";
import { useGetEpisodeQuery } from "../modules/api";
import { AppDataFrame } from "../components/templates/AppDataFrame";
import { CutListTableComparableItem, CutListTable } from "../components/organisms/ListViews/CutListTable";
import { CardView } from "../components/organisms/CardView";
import { getComparator } from "../utils/sortUtil";
import { makeEpisodeTitle } from "../utils/makeTitleUtil";
import { FileDownloadOutlined } from "@mui/icons-material";
import { AddCutDialog, RenameCutDialog } from "../components/organisms/FormDialogs";
import AddContentCard from "../components/molecules/AddContentCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { CutStatusChip, CutTitle, CutFolderIcon, ComparableDate } from "../common/DisplayInterface.types";
import { useExports } from "../hooks/useExports";
import { ContainerMenuItem } from "../components/organisms/ContextMenu";
import { selectNewestDateString } from "../utils/dateUtil";
import { selectLatestFileUploadedProcessType } from "../utils/processTypeUtil";
import { t } from "i18next";

export const Episode: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const viewMode = useAppSelector(selectCutsViewMode);
  const { order, orderBy } = useAppSelector(selectCutsSortOrder);
  const { episodeId } = useParams<{ episodeId: string | undefined }>();

  // エピソード情報（カット一覧）取得
  const {
    data: cuts,
    cdnUrl,
    isSuccess,
    isUninitialized,
    isLoading,
    work,
    episode,
  } = useGetEpisodeQuery(episodeId ? { episodeId: episodeId } : skipToken, {
    selectFromResult: ({ data, isSuccess, isUninitialized, isLoading }) => ({
      data:
        data?.cuts.map((cut) => ({
          id: cut.id,
          title: new CutTitle(cut.title),
          thumbnail: cut.thumbnail,
          status: new CutStatusChip(
            selectLatestFileUploadedProcessType(
              cut.latestFiles?.map((v) => ({ type: v.type, latestFileUpdatedAt: v.latestFileUpdatedAt })),
            ),
          ),
          updatedAt: new ComparableDate(
            selectNewestDateString({
              candidates: [...(cut.latestFiles?.map((v) => v.latestFileUpdatedAt) ?? []), cut.thumbnail?.updatedAt],
              default: cut.createdAt,
            }),
          ),
          icon: new CutFolderIcon(cut.latestFiles),
        })) ?? [],
      cdnUrl: data?.cdnUrl ?? "",
      isSuccess,
      isUninitialized,
      isLoading,
      work: data?.work,
      episode: data?.episode,
    }),
  });

  // パンくずリスト更新
  useEffect(() => {
    if (work && episode) {
      dispatch(
        setSelectedEpisode({
          work: { id: work.id, title: work.title },
          episode: { id: episode.id, title: makeEpisodeTitle(episode) },
        }),
      );
    }
  }, [work, episode, dispatch]);

  // リストビュー用データ
  const listViewData = useMemo(() => {
    return cuts;
  }, [cuts]);
  // サムネイルビュー用データ
  const thumbnailViewData = useMemo(() => {
    return cuts.sort(getComparator<CutListTableComparableItem>(order, orderBy)).map((value) => ({
      id: value.id,
      title: value.title,
      updatedAt: value.updatedAt,
      thumbnail: value.thumbnail,
      icon: value.icon,
    }));
  }, [cuts, order, orderBy]);
  // SelectedIdProvider 向け ID リスト
  const sortedIds = useMemo(() => {
    return cuts.sort(getComparator<CutListTableComparableItem>(order, orderBy)).map<string>((value) => value.id);
  }, [cuts, order, orderBy]);

  // ページ遷移
  const pageTransition = (cutId: string) => {
    navigate("/cut/" + cutId);
  };

  // カットフォルダ名変更
  const [openRenameCutDialog, setOpenRenameCutDialog] = useState<boolean>(false);
  const [renameTarget, setRenameTarget] = useState<{ id: string; title: string }>();
  const doRename = (targetIds: Array<string>): void => {
    if (targetIds.length === 1) {
      const renameTarget = cuts.find((cut) => cut.id === targetIds[0]);
      if (renameTarget) {
        setRenameTarget({ id: renameTarget.id, title: renameTarget.title.value });
        setOpenRenameCutDialog(true);
      }
    }
  };

  const { exports } = useExports();
  const menuItems: Array<ContainerMenuItem> = [
    { text: t("phrase.名前の変更"), icon: EditOutlinedIcon, handler: doRename },
    {
      text: t("word.ダウンロード"),
      icon: FileDownloadOutlined,
      handler: (ids) => {
        // ダウンロード対象は１つ
        const selectedId = ids[0];
        const title = cuts.find((cut) => cut.id === selectedId)?.title.value;
        if (title && work && episode) {
          exports(ids, title, work.title + "_" + makeEpisodeTitle(episode) + "_" + title);
        }
      },
    },
  ];

  // 登録・追加ダイアログ関連
  const [openAddCutDialog, setOpenAddCutDialog] = useState<boolean>(false);
  const cutTitles = useMemo(() => {
    return cuts.map((item) => {
      return { title: item.title.value };
    });
  }, [cuts]);

  return (
    <AppDataFrame
      viewChangeButtonProps={
        isSuccess && cuts.length && episodeId ? { selecter: selectCutsViewMode, action: setCutsViewMode } : undefined
      }
      addContentButtonProps={
        isSuccess && cuts.length && episodeId
          ? { tooltip: t("phrase.カットを追加"), onClick: () => setOpenAddCutDialog(true) }
          : undefined
      }
    >
      {openAddCutDialog && episodeId ? (
        <AddCutDialog
          open={openAddCutDialog}
          episodeId={episodeId}
          cutTitles={cutTitles}
          onClose={() => {
            // console.log("AddCutDialog closed."); // debug
            setOpenAddCutDialog(false);
          }}
        />
      ) : null}
      {openRenameCutDialog && renameTarget ? (
        <RenameCutDialog
          open={openRenameCutDialog}
          titles={cutTitles}
          currentTitle={renameTarget.title}
          id={renameTarget.id}
          onClose={() => {
            setOpenRenameCutDialog(false);
            // refetch();
          }}
        />
      ) : null}
      <div>
        <SelectedIdProvider sortedIds={sortedIds}>
          {isUninitialized || isLoading ? null : cuts.length === 0 ? (
            <AddContentCard title={t("phrase.カットを追加")} onClick={() => setOpenAddCutDialog(true)} />
          ) : viewMode === "list" ? (
            <CutListTable
              cdnUrl={cdnUrl}
              cutList={listViewData}
              handlePageTransition={pageTransition}
              menuItems={menuItems}
            />
          ) : (
            <CardView
              cdnUrl={cdnUrl}
              items={thumbnailViewData}
              menuItems={menuItems}
              handlePageTransition={pageTransition}
            />
          )}
        </SelectedIdProvider>
      </div>
    </AppDataFrame>
  );
};
