import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/rtk-hooks";
import { useGetWorksQuery } from "../modules/api";
import { setSelectedWorks } from "../modules/titleSlice";
import { CardView } from "../components/organisms/CardView";
import { AppDataFrame } from "../components/templates/AppDataFrame";
import { SelectedIdProvider } from "../hooks/contexts/SelectedIdProvider";
import { AddWorkDialog } from "../components/organisms/FormDialogs";
import AddContentCard from "../components/molecules/AddContentCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { RenameWorkDialog } from "../components/organisms/FormDialogs/RenameWorkDialog";
import { ComparableDate, ComparableString, NoImageIcon } from "../common/DisplayInterface.types";
import { useRegisterThumbnail } from "../hooks/useRegisterThumbnail";
import { HiddenFileSelector } from "../components/atoms/HiddenFileSelector";
import { t } from "i18next";

export const Works: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { inputFileRef, doRegisterThumbnail } = useRegisterThumbnail();

  //  作品一覧取得
  const {
    data: works,
    cdnUrl,
    isSuccess,
    isUninitialized,
    isLoading,
  } = useGetWorksQuery(undefined, {
    selectFromResult: ({ data, isSuccess, isUninitialized, isLoading }) => ({
      data:
        data?.items.map((work) => ({
          id: work.id,
          title: new ComparableString(work.title),
          thumbnail: work.thumbnail,
          updatedAt: new ComparableDate(work.updatedAt),
          icon: new NoImageIcon(),
        })) ?? [],
      cdnUrl: data?.cdnUrl ?? "",
      isSuccess,
      isUninitialized,
      isLoading,
    }),
  });

  // パンくずリスト更新
  useEffect(() => {
    if (works) {
      dispatch(setSelectedWorks());
    }
  }, [works, dispatch]);

  // サムネイルビュー用データ
  const thumbnailViewData = useMemo(() => {
    return works;
  }, [works]);
  // SelectedProvider 向け ID リスト
  const sortedIds = useMemo(() => {
    return works.map<string>((value) => value.id);
  }, [works]);

  // ページ遷移
  const pageTransition = (workId: string) => {
    navigate("/work/" + workId);
  };

  // 登録・追加ダイアログ関連
  const [openAddWorksDialog, setOpenAddWorksDialog] = useState<boolean>(false);
  const workTitles = useMemo(() => {
    return works.map((item) => {
      return { title: item.title.value };
    });
  }, [works]);

  // 作品名変更
  const [openRenameWorkDialog, setOpenRenameWorkDialog] = useState<boolean>(false);
  const [renameTarget, setRenameTarget] = useState<{ id: string; title: string }>();
  const doRename = (targetIds: Array<string>): void => {
    if (targetIds.length === 1) {
      const renameTarget = works.find((work) => work.id === targetIds[0]);
      if (renameTarget) {
        setRenameTarget({ id: renameTarget.id, title: renameTarget.title.value });
        setOpenRenameWorkDialog(true);
      }
    }
  };

  const doRegisterWorkThumbnail = (targetIds: Array<string>): void => {
    if (targetIds.length === 1) {
      const target = works.find((work) => work.id === targetIds[0]);
      if (target) {
        doRegisterThumbnail(target.id);
      }
    }
  };

  // サムネイル画像が登録済みか確認
  const hasThumbnail = (ids: Array<string>): boolean => {
    if (ids.length !== 1) {
      return false;
    }
    const work = works.find((v) => v.id === ids[0]);
    if (work) {
      return work.thumbnail !== undefined;
    }
    return false;
  };

  const menuItems = [
    { text: t("phrase.名前の変更"), icon: EditOutlinedIcon, handler: doRename },
    {
      text: t("phrase.画像を追加"),
      icon: AddPhotoAlternateOutlinedIcon,
      handler: doRegisterWorkThumbnail,
      isInvisible: (ids: Array<string>) => hasThumbnail(ids),
    },
    {
      text: t("phrase.画像を変更"),
      icon: AddPhotoAlternateOutlinedIcon,
      handler: doRegisterWorkThumbnail,
      isInvisible: (ids: Array<string>) => !hasThumbnail(ids),
    },
  ];

  return (
    <AppDataFrame
      addContentButtonProps={
        isSuccess && works.length > 0
          ? {
              tooltip: t("phrase.作品を追加"),
              onClick: () => setOpenAddWorksDialog(true),
            }
          : undefined
      }
    >
      {openAddWorksDialog ? (
        <AddWorkDialog
          open={openAddWorksDialog}
          workTitles={workTitles}
          onClose={() => {
            // console.log("AddEpisodeDialog closed."); // debug
            setOpenAddWorksDialog(false);
          }}
        />
      ) : null}
      {openRenameWorkDialog && renameTarget ? (
        <RenameWorkDialog
          open={openRenameWorkDialog}
          titles={workTitles}
          currentTitle={renameTarget.title}
          id={renameTarget.id}
          onClose={() => {
            setOpenRenameWorkDialog(false);
            // refetch();
          }}
        />
      ) : null}
      <div>
        <SelectedIdProvider sortedIds={sortedIds}>
          {isUninitialized || isLoading ? null : works.length === 0 ? (
            <AddContentCard title={t("phrase.作品を追加")} onClick={() => setOpenAddWorksDialog(true)} />
          ) : (
            <CardView
              cdnUrl={cdnUrl}
              items={thumbnailViewData}
              handlePageTransition={pageTransition}
              ratio={0.7071} // 1 / Math.sqrt(2) == A版縦
              menuItems={menuItems}
            />
          )}
        </SelectedIdProvider>
        <HiddenFileSelector accept={"image/png, image/jpeg"} inputRef={inputFileRef} />
      </div>
    </AppDataFrame>
  );
};
