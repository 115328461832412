import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import * as tagTypes from "./tagTypes";
import { AddWorkThumbnailRequest, AddWorkThumbnailResponse, GetWorkResponse, UpdateWorkResponse } from "../api.types";

// 作品情報のタグ
const type = tagTypes.Work;

// 作品情報に含まれる一覧項目のタグ
const typeItems = tagTypes.Episode;

// 作品情報取得時の提供タグ
export const providesWorkTags = (result: GetWorkResponse | undefined) =>
  result
    ? [
        { type: type, id: result.work.id },
        ...result.episodes.map(({ id }) => ({ type: typeItems, id })),
        { type: typeItems, id: tagTypes.ListId },
      ]
    : [{ type: typeItems, id: tagTypes.ListId }];

// （更新系APIが追加された場合、下記のようにID指定で無効化し、
// providesWorkTagsにも[{ type: type, id: arg.workId }]を追加する）
// 作品情報更新時の無効タグ
export const invalidatesUpdateWorkTags = (result: UpdateWorkResponse | undefined) =>
  result ? [{ type: type, id: result.id }] : [];

// 作品のサムネイル追加時の無効タグ
export const invalidatesAddWorkThumbnailTags = (
  result: AddWorkThumbnailResponse | undefined,
  error: FetchBaseQueryError | undefined,
  arg: AddWorkThumbnailRequest,
) => (result ? [{ type: type, id: arg.workId }] : []);

// 作品一覧に影響がある場合の無効タグ
export const invalidatesWorkListTags = [{ type: type, id: tagTypes.ListId }];
