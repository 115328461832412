import React, { createContext, ReactNode, useEffect, useState } from "react";

export const SelectedIdContext = createContext<{
  isSelectedId: (id: string) => boolean; // idが選択中ならtrue, 非選択ならfalseを返す関数。
  setSelectedId: (id: string) => void; // idを選択中(指定id以外に選択中なし)にする関数。これを最後に操作されたidとする。
  toggleSelectedId: (id: string) => void; // idが選択中なら未選択、未選択ならば選択中にし、これを最後に操作されたidとする。他のidの状態は変えない。
  setFromLastToSelectedId: (id: string) => void; // 最後に操作されたidから指定idまでを選択中にする。他は未選択にする。最後に操作されたidは変更しない。
  addFromLastToSelectedId: (id: string) => void; // 最後に操作されたidから指定idまでを最後に操作されたidと同じ状態にする。他に選択状態があったらその状態を保持する。最後に操作されたidは変更しない。
  resetSelectedId: () => void; // 選択状態をリセット(選択中なしに)する関数。最後に操作されたidは消去する。
  selectedIds: string[]; // 選択中のIDリスト
}>({
  isSelectedId: () => false,
  setSelectedId: () => {
    // do nothing
  },
  toggleSelectedId: () => {
    // do nothing
  },
  setFromLastToSelectedId: () => {
    // do nothing
  },
  addFromLastToSelectedId: () => {
    // do nothing
  },
  resetSelectedId: () => {
    // do nothing
  },
  selectedIds: [],
});
SelectedIdContext.displayName = "SelectedIdContext";

interface SelectedIdProviderProps {
  children: ReactNode;
  sortedIds: string[];
}
export const SelectedIdProvider: React.FC<SelectedIdProviderProps> = ({ children, sortedIds }) => {
  const [selectedIds, setToSelectedIds] = useState<string[]>([]);
  const [lastOperatedId, setLastOperatedId] = useState<string>("");

  useEffect(() => {
    setToSelectedIds((prev) => [...prev.filter((v) => sortedIds.includes(v))]);
    setLastOperatedId((prev) => (sortedIds.includes(prev) ? prev : ""));
  }, [sortedIds]);

  const isSelectedId = (id: string): boolean => {
    return selectedIds.includes(id);
  };

  const setSelectedId = (id: string): void => {
    setToSelectedIds(() => [id]);
    setLastOperatedId(id);
  };

  const toggleSelectedId = (id: string): void => {
    setToSelectedIds((prev) => (prev.includes(id) ? prev.filter((v) => v !== id) : [...prev, id]));
    setLastOperatedId(id);
  };

  const setFromLastToSelectedId = (id: string): void => {
    if (sortedIds) {
      const currentIdIndex = sortedIds.indexOf(id);
      const lastOperatedIdIndex = lastOperatedId ? sortedIds.indexOf(lastOperatedId) : 0;
      const selectedIdList =
        currentIdIndex < lastOperatedIdIndex
          ? sortedIds.slice(currentIdIndex, lastOperatedIdIndex + 1)
          : sortedIds.slice(lastOperatedIdIndex, currentIdIndex + 1);
      // console.log(`newIdList: ${JSON.stringify(selectedIdList)}`); // debug
      setToSelectedIds(selectedIdList);
    }
  };

  const addFromLastToSelectedId = (id: string): void => {
    if (sortedIds) {
      const currentIdIndex = sortedIds.indexOf(id);
      const lastOperatedIdIndex = lastOperatedId ? sortedIds.indexOf(lastOperatedId) : 0;
      const idList =
        currentIdIndex < lastOperatedIdIndex
          ? sortedIds.slice(currentIdIndex, lastOperatedIdIndex)
          : sortedIds.slice(lastOperatedIdIndex + 1, currentIdIndex + 1);
      console.log(`idList: ${JSON.stringify(idList)}`); // debug
      if (!selectedIds.includes(lastOperatedId)) {
        // 最後に選択したidが非選択状態
        const newIdList = selectedIds.filter((v) => !idList.includes(v));
        console.log(`newIdList: ${JSON.stringify(newIdList)}`); // debug
        setToSelectedIds(newIdList);
      } else {
        // 最後に選択したidが選択状態
        const allIdList = selectedIds.concat(idList);
        const newIdList = allIdList.filter((element, index) => allIdList.indexOf(element) === index);
        // console.log(`newIdList: ${JSON.stringify(newIdList)}`); // debug
        setToSelectedIds(newIdList);
      }
    }
  };

  const resetSelectedId = (): void => {
    setToSelectedIds([]);
    setLastOperatedId("");
  };

  const selectedIdValue = {
    isSelectedId,
    setSelectedId,
    toggleSelectedId,
    setFromLastToSelectedId,
    addFromLastToSelectedId,
    resetSelectedId,
    selectedIds,
  };

  return <SelectedIdContext.Provider value={selectedIdValue}>{children}</SelectedIdContext.Provider>;
};
