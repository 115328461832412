import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./i18n";

import { store } from "./modules/store";
import { AppRoutes } from "./AppRoutes";
import { theme } from "./styles/theme";

import { worker } from "./mocks/browser";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
if (process.env.NODE_ENV === "development" && !API_BASE_URL.length) {
  void worker.start();
}

const router = createBrowserRouter(createRoutesFromElements(AppRoutes()));

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
