import React, { createContext, useState } from "react";
import { ContextMenu, ContextMenuItem, ContextMenuProps } from "../../components/organisms/ContextMenu";
import { useLeaveLocation } from "../../hooks/useLeaveLocation";

// export for testWrapper
export const ContextMenuContext = createContext<(contextMenuProps: ContextMenuProps) => void>(() => {
  // do nothing
});
ContextMenuContext.displayName = "ContextMenuContext";

export const ContextMenuProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);
  const [contextMenuProps, setContextMenuProps] = useState<ContextMenuProps>({
    position: null,
    handleClose: () => {
      // do nothing
    },
    items: [],
  });
  const setOpenedLocationPath = useLeaveLocation(() => setOpenContextMenu(false));
  const showContextMenu = ({ position, handleClose, items }: ContextMenuProps): void => {
    // console.log("showContextMenu was called."); // debug
    if (openContextMenu) {
      // 開いていたら閉じる
      setOpenContextMenu(false);
      setOpenedLocationPath(null);
      // 元々開いていたコンポーネントの閉じる処理を実行
      contextMenuProps.handleClose();
      return;
    }
    setContextMenuProps({
      position,
      handleClose: () => {
        setOpenContextMenu(false);
        setOpenedLocationPath(null);
        handleClose();
      },
      items: items.map<ContextMenuItem>((item) => {
        return {
          text: item.text,
          icon: item.icon,
          handleClick: () => {
            // クリックされたら閉じる
            setOpenContextMenu(false);
            setOpenedLocationPath(null);
            handleClose();
            item.handleClick();
          },
        };
      }),
    });
    setOpenedLocationPath(window.location.pathname);
    setOpenContextMenu(true);
  };
  return (
    <ContextMenuContext.Provider value={showContextMenu}>
      {children}
      {openContextMenu ? <ContextMenu {...contextMenuProps} /> : null}
    </ContextMenuContext.Provider>
  );
};
