import { useEffect, useCallback, useLayoutEffect, useRef, useState } from "react";
import * as React from "react";
import ZoomButtons from "../ZoomButtons";

type Point = {
  x: number;
  y: number;
};

const ORIGIN = Object.freeze({ x: 0, y: 0 });

// // adjust to device to avoid blur
// const { devicePixelRatio: ratio = 1 } = window; // 表示領域と一緒にpropsで受ける

function diffPoints(p1: Point, p2: Point) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1: Point, p2: Point) {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1: Point, scale: number) {
  return { x: p1.x / scale, y: p1.y / scale };
}

const ZOOM_SENSITIVITY = 1500; // ホイール１カチリが150

export type CanvasProps = {
  canvasWidth: number;
  canvasHeight: number;
  devicePixelRatio: number;
  img: HTMLImageElement | null;
  imgWidth: number;
  imgHeight: number;
};

const Canvas: React.FC<CanvasProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [offset, setOffset] = useState<Point>(ORIGIN);
  const [mousePos, setMousePos] = useState<Point>(ORIGIN);
  const [viewportTopLeft, setViewportTopLeft] = useState<Point>(ORIGIN);
  const isResetRef = useRef<boolean>(false);
  const lastMousePosRef = useRef<Point>(ORIGIN);
  const lastOffsetRef = useRef<Point>(ORIGIN);

  // update last offset
  useEffect(() => {
    lastOffsetRef.current = offset;
  }, [offset]);

  // reset
  const reset = useCallback(
    (context: CanvasRenderingContext2D) => {
      if (context && !isResetRef.current) {
        // adjust for device pixel density
        context.canvas.width = props.canvasWidth * props.devicePixelRatio;
        context.canvas.height = props.canvasHeight * props.devicePixelRatio;
        context.scale(props.devicePixelRatio, props.devicePixelRatio);
        setScale(1);

        // reset state and refs
        setContext(context);
        setOffset(ORIGIN);
        setMousePos(ORIGIN);
        setViewportTopLeft(ORIGIN);
        lastOffsetRef.current = ORIGIN;
        lastMousePosRef.current = ORIGIN;

        // this thing is so multiple resets in a row don't clear canvas
        isResetRef.current = true;
        // console.log("reset Canvas."); // debug
      }
    },
    [props.canvasWidth, props.canvasHeight, props.devicePixelRatio],
  );

  // functions for panning
  const mouseMove = useCallback(
    (event: MouseEvent) => {
      if (context) {
        const lastMousePos = lastMousePosRef.current;
        const currentMousePos = { x: event.pageX, y: event.pageY }; // use document so can pan off element
        lastMousePosRef.current = currentMousePos;

        const mouseDiff = diffPoints(currentMousePos, lastMousePos);
        setOffset((prevOffset) => addPoints(prevOffset, mouseDiff));
      }
    },
    [context],
  );

  const mouseUp = useCallback(() => {
    document.removeEventListener("mousemove", mouseMove);
    document.removeEventListener("mouseup", mouseUp);
  }, [mouseMove]);

  const startPan = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
      lastMousePosRef.current = { x: event.pageX, y: event.pageY };
    },
    [mouseMove, mouseUp],
  );

  // setup canvas and set context
  useLayoutEffect(() => {
    if (canvasRef.current) {
      // get new drawing context
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        reset(renderCtx);
      }
    }
  }, [reset, props.canvasHeight, props.canvasWidth]);

  // pan when offset or scale changes
  useLayoutEffect(() => {
    if (context && lastOffsetRef.current) {
      // console.log(`pan when offset or scale changes. offset:{x:${offset.x}, y:${offset.y}}, scale: ${scale}`); // debug
      const offsetDiff = scalePoint(diffPoints(offset, lastOffsetRef.current), scale);
      context.translate(offsetDiff.x, offsetDiff.y);
      setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
      isResetRef.current = false;
    }
  }, [context, offset, scale]);

  // draw
  useLayoutEffect(() => {
    if (context) {
      // console.log("draw Canvas.");
      // clear canvas but maintain transform
      const storedTransform = context.getTransform();
      // eslint-disable-next-line no-self-assign
      context.canvas.width = context.canvas.width;
      context.setTransform(storedTransform);

      const x = props.canvasWidth / 2 - props.imgWidth / 2;
      const y = props.canvasHeight / 2 - props.imgHeight / 2;
      const w = props.imgWidth;
      const h = props.imgHeight;
      context.fillStyle = "#f1f4f6";
      context.fillRect(x, y, w, h);
      props.img && context.drawImage(props.img, x, y, w, h);
    }
  }, [
    props.canvasWidth,
    props.canvasHeight,
    props.imgWidth,
    props.imgHeight,
    props.img,
    context,
    scale,
    offset,
    viewportTopLeft,
  ]);

  // tracking mouse position
  const handleUpdateMouse = (event: React.MouseEvent) => {
    // event.preventDefault(); // Unable to preventDefault inside passive event listener invocation.
    if (canvasRef.current) {
      const viewportMousePos = { x: event.clientX, y: event.clientY };
      const topLeftCanvasPos = {
        x: canvasRef.current.offsetLeft,
        y: canvasRef.current.offsetTop,
      };
      setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));
    }
  };

  // zoom event
  const handleWheel = (event: React.WheelEvent) => {
    // event.preventDefault(); // { passive: true }
    if (context) {
      const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;
      zoomChange(zoom, { x: mousePos.x, y: mousePos.y });
    }
  };

  const zoomChange = (zoom: number, origin: Point) => {
    // useCallback?

    // this is tricky. Update the viewport's "origin" such that
    // the mouse doesn't move during scale - the 'zoom point' of the mouse
    // before and after zoom is relatively the same position on the viewport
    if (context) {
      const viewportTopLeftDelta = {
        x: (origin.x / scale) * (1 - 1 / zoom),
        y: (origin.y / scale) * (1 - 1 / zoom),
      };
      const newViewportTopLeft = addPoints(viewportTopLeft, viewportTopLeftDelta);

      context.translate(viewportTopLeft.x, viewportTopLeft.y);
      context.scale(zoom, zoom);
      context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);

      setViewportTopLeft(newViewportTopLeft);
      setScale(scale * zoom);
      // console.log(`scaleChange() scale: ${scale} -> ${scale * zoom} (zoom: ${zoom})`); // debug
      isResetRef.current = false;
    }
  };
  const zoomUp = () => {
    zoomChange(1.1, { x: props.canvasWidth / 2, y: props.canvasHeight / 2 });
  };
  const zoomDown = () => {
    zoomChange(0.9, { x: props.canvasWidth / 2, y: props.canvasHeight / 2 });
  };

  // console.log(`render....   ratio: ${props.devicePixelRatio}`); // debug

  return (
    <div>
      <canvas
        onMouseDown={startPan}
        onMouseMove={handleUpdateMouse}
        onWheel={handleWheel}
        ref={canvasRef}
        width={props.canvasWidth * props.devicePixelRatio}
        height={props.canvasHeight * props.devicePixelRatio}
        style={{
          width: `${props.canvasWidth}px`,
          height: `${props.canvasHeight}px`,
        }}
        aria-label="image-preview-canvas"
      ></canvas>
      <ZoomButtons
        containerWidth={props.canvasWidth}
        onZoomIn={(e) => {
          e.preventDefault();
          e.stopPropagation();
          zoomUp();
        }}
        onZoomOut={(e) => {
          e.preventDefault();
          e.stopPropagation();
          zoomDown();
        }}
        onZoomReset={(e) => {
          e.preventDefault();
          e.stopPropagation();
          context && reset(context);
        }}
      />
    </div>
  );
};

export default Canvas;
