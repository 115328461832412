import React from "react";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";

interface FolderIconProps {
  style?: React.CSSProperties;
}

export const FolderIcon: React.FC<FolderIconProps> = (props) => {
  return <FolderOutlinedIcon {...props} />;
};
