import React from "react";
import styled from "@emotion/styled";

const StyledCardViewItemBar = styled("div")({
  textAlign: "left",
  position: "relative",
  left: "0px",
  right: "0px",
  background: "transparent",
  display: "flex",
  alignItems: "normal",
  width: "100%",
});
const StyledCardViewItemBarTitleWrap = styled("div")({
  flexGrow: "1",
  padding: "6px 0px 0px",
  color: "inherit",
  overflow: "hidden",
});
const StyledCardViewItemBarTitle = styled("div")({
  lineHeight: "24px",
});

const CardViewItemBar: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <StyledCardViewItemBar>
      <StyledCardViewItemBarTitleWrap>
        <StyledCardViewItemBarTitle>{children}</StyledCardViewItemBarTitle>
      </StyledCardViewItemBarTitleWrap>
    </StyledCardViewItemBar>
  );
};

export default CardViewItemBar;
