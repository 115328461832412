import React from "react";
import { ImageList } from "@mui/material";
import styled from "@emotion/styled";
import { useSelectedId } from "../../../hooks/useSelectedId";
import { ContainerMenuItem } from "../ContextMenu";
import ItemButton from "../../atoms/ItemButton";
import CardViewItem from "../../molecules/CardViewItem";
import { useClickEventsToActions } from "../../../hooks/useClickEventsToActions";
import { Thumbnail } from "../../../modules/api.types";
import { DisplayInterface, ComparableDate, IconInterface } from "../../../common/DisplayInterface.types";
import { createSrc, createSrcSet } from "../../../utils/thumbnailUtil";
import { makeURL } from "../../../utils/cdnUtil";
import { canBeDisplayed } from "../../../utils/fileNameUtil";

// styled component
const StyledCardView = styled(ImageList)({
  marginTop: 0,
  marginBottom: 0,
  paddingBottom: 16,
  paddingRight: 16,
  width: "100%",
  height: "100%",
  float: "left",
});

const StyledItemContainer = styled("div")({
  display: "flex",
});

export type CardViewItemType = {
  id: string;
  title: DisplayInterface;
  updatedAt: ComparableDate;
  thumbnail?: Thumbnail | string; // TODO: RDS導入等で過去のデータを削除する場合は、Thumbnail型のみに修正すること
  icon: IconInterface;
};

interface CardViewProps {
  cdnUrl: string;
  items: Array<CardViewItemType>;
  menuItems?: Array<ContainerMenuItem>;
  handlePageTransition: (id: string, title: string) => void;
  ratio?: number;
}

export const CardView: React.FC<CardViewProps> = (props: CardViewProps) => {
  const { cdnUrl, items, menuItems, handlePageTransition, ratio = 1.4142 } = props;

  // 表示領域の幅によって表示するサムネイル(カード)の列数を変える
  const imageWidth = 200; // いずれ可変になる
  const imageHeight = imageWidth / ratio;
  const imageBorder = 1;
  const itemPadding = 8;
  const itemWidth = imageWidth + imageBorder * 2 + itemPadding * 2;
  const width = imageWidth + imageBorder * 2;
  const height = imageHeight + imageBorder * 2;
  const iconStyleSize: React.CSSProperties = {
    width: `${Math.floor(width * 0.3)}px`,
    height: `${Math.floor(width * 0.3)}px`,
    minHeight: `${height}px`,
  };

  // 選択中のID
  const { isSelectedId } = useSelectedId();
  // クリックイベントをアクションにマップする
  const { handleClick, handleDoubleClick, handleContextMenu } = useClickEventsToActions(
    handlePageTransition,
    menuItems,
  );

  return (
    <StyledCardView aria-label="thumbnail-view" sx={{ gridTemplateColumns: `repeat(auto-fill, ${itemWidth}px)` }}>
      {items.map((item) => (
        <StyledItemContainer key={item.id}>
          <ItemButton
            key={`image-list-item-button-${item.id}`}
            id={item.id}
            variant="contained"
            onClick={(e) => handleClick(e, item.id, item.title.value)}
            onDoubleClick={(e) => handleDoubleClick(e, item.id, item.title.value)}
            onContextMenu={(e) => handleContextMenu(e, item.id, item.title.value)}
            selected={isSelectedId(item.id)}
            sx={{ minWidth: `${itemWidth}px`, cursor: "default" }}
          >
            <CardViewItem
              key={`image-list-item-${item.id}`}
              title={item.title.value}
              // TODO: RDS導入等で過去のデータを削除する場合は、Thumbnail型のみに修正すること
              src={
                !item.thumbnail
                  ? ""
                  : // TODO: RDS導入等で過去のデータを削除する場合は、Thumbnail型のみに修正すること start
                  typeof item.thumbnail === "string"
                  ? canBeDisplayed(item.thumbnail)
                    ? makeURL(cdnUrl, item.thumbnail, item.updatedAt.isoDateString)
                    : ""
                  : // TODO: RDS導入等で過去のデータを削除する場合は、Thumbnail型のみに修正すること end
                    createSrc(cdnUrl, item.thumbnail, "medium")
              }
              // TODO: RDS導入等で過去のデータを削除する場合は、Thumbnail型のみに修正すること
              srcSet={
                !item.thumbnail
                  ? undefined
                  : typeof item.thumbnail === "string"
                  ? undefined
                  : createSrcSet(cdnUrl, item.thumbnail)
              }
              thumbnailWidth={typeof item.thumbnail !== "string" ? item.thumbnail?.width : undefined}
              thumbnailHeight={typeof item.thumbnail !== "string" ? item.thumbnail?.height : undefined}
              width={imageWidth + imageBorder * 2}
              height={imageHeight + imageBorder * 2}
              icon={item.icon.setSize(iconStyleSize, width, height)}
            />
          </ItemButton>
        </StyledItemContainer>
      ))}
    </StyledCardView>
  );
};
