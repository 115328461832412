import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import styled from "@emotion/styled";
import AppIcon from "../../atoms/AppIcon";
import AppLogo from "../../atoms/AppLogo";

const TitleBarComponent = styled(AppBar)<TitleBarProps>((props) => ({
  color: "#212121",
  backgroundColor: "#F7FBFF",
  height: props.height,
  flexGrow: 1,
  position: "fixed",
  border: "thin solid #ccc",
}));

interface TitleBarProps {
  height: string;
}

export const TitleBar: React.FC<TitleBarProps> = (props) => {
  return (
    <Box>
      <TitleBarComponent {...props}>
        <Toolbar>
          <AppIcon width={32} height={32} style={{ marginRight: "16px", marginBottom: "6px" }} />
          <AppLogo style={{ marginBottom: "6px" }} />
        </Toolbar>
      </TitleBarComponent>
    </Box>
  );
};
