import React from "react";
import {
  DougaStatusChips,
  EmptyStatusChips,
  GengaStatusChips,
  LayoutStatusChips,
  ShiageStatusChips,
} from "../../components/atoms/StatusChips";
import { ComparableInterface } from "./ComparableInterface";
import { t } from "i18next";

const minou = new RegExp(t("word.未納"));
const layout = new RegExp(t("word.レイアウト"));
const genga = new RegExp(t("word.原画"));
const douga = new RegExp(t("word.動画"));
const shiage = new RegExp(t("word.仕上"));

const typeToStatusId = (type: string | undefined): number => {
  if (!type) {
    return 0;
  }
  switch (true) {
    case minou.test(type):
      return 1;
    case layout.test(type):
      return 2;
    case genga.test(type):
      return 3;
    case douga.test(type):
      return 4;
    case shiage.test(type):
      return 5;
    default:
      return 6; // 規定以外の工程名は一番後ろに順不同で置くようにする
  }
};

const getChip = (statusId: number): React.ReactNode => {
  switch (statusId) {
    case 1:
      return <EmptyStatusChips />;
    case 2:
      return <LayoutStatusChips />;
    case 3:
      return <GengaStatusChips />;
    case 4:
      return <DougaStatusChips />;
    case 5:
      return <ShiageStatusChips />;
    default:
      return null;
  }
};

export class CutStatusChip implements ComparableInterface {
  protected readonly statusId: number;
  protected readonly chipElement: React.ReactNode;
  /**
   * @param {string} processType ""ならば、Chip表示しない（昇順で「未納」の前）。
   *                   ファイルがアップロードされている工程があれば、そのうちの工程順で最後の工程、
   *                   ファイルがアップロードされている工程がなければ 「未納」（昇順で「レイアウト」の前）。
   */
  constructor(protected readonly processType: string) {
    this.statusId = typeToStatusId(processType);
    this.chipElement = getChip(this.statusId);
  }
  get chip(): React.ReactNode {
    return this.chipElement;
  }
  compareTo(target: CutStatusChip): number {
    return this.statusId - target.statusId;
  }
}
