import React from "react";
import { UploadFileOutlined as UploadFileOutlinedIcon } from "@mui/icons-material";
import { UploadButton } from "./UploadButton";
import { t } from "i18next";

export interface FileUploadButtonProps {
  onInputFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
  const { onInputFileChange, accept } = props;
  return (
    <UploadButton
      tooltip={t("phrase.ファイルのアップロード")}
      kind={"file"}
      icon={UploadFileOutlinedIcon}
      iconStyle={{ color: "#666", cursor: "pointer" }}
      onInputFileChange={onInputFileChange}
      accept={accept}
    />
  );
};
