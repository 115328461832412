import { SvgIconComponent } from "@mui/icons-material";
import { ContextMenuItem } from "../../organisms/ContextMenu";

// container(pages)側で用意するメニューアイテム（メニュー実行対象は複数選択可能）
export interface ContainerMenuItem {
  text: string;
  icon?: SvgIconComponent;
  handler: (ids: Array<string>) => void;
  isInvisible?: (ids: Array<string>) => boolean;
  allowMultipleSelect?: boolean;
}

// ContainerMenuItemをContextMenuItemに変換する
export const convertToContextMenuItems = (
  menuItems: Array<ContainerMenuItem> | undefined,
  ids: Array<string>,
): Array<ContextMenuItem> | undefined => {
  if (!menuItems) {
    // メニュー設定がない場合はundefinedを返す
    return undefined;
  }
  // 表示するアイテムを絞り込む
  const visibleItems = menuItems
    .filter((menuItem) => (menuItem.allowMultipleSelect ? true : ids.length === 1))
    .filter((menuItem) => (menuItem.isInvisible ? !menuItem.isInvisible(ids) : true));
  // クリックされた際に対象IDでhandlerを呼び出すように設定
  return visibleItems.map<ContextMenuItem>((item) => ({
    text: item.text,
    icon: item.icon,
    handleClick: () => item.handler(ids),
  }));
};
