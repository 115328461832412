import React, { ReactNode, createContext, useCallback, useEffect, useState } from "react";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import { useConfirmDialog } from "../useConfirmDialog";
import { t } from "i18next";

export const NavigationBlockerContext = createContext<{
  startBlocking: (message: string | string[]) => void;
  stopBlocking: () => void;
}>({
  startBlocking: () => {
    // do nothing
  },
  stopBlocking: () => {
    // do nothing
  },
});

interface NavigationBlockerProviderProps {
  children: ReactNode;
}

// ページ遷移(Route変更)抑止機能プロバイダ
export const NavigationBlockerProvider: React.FC<NavigationBlockerProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<string | string[] | null>(null);
  const isBlocked = !!message;
  const blocker = useBlocker(isBlocked);
  const showConfirmDialog = useConfirmDialog();
  useEffect(() => {
    if (blocker && blocker.state === "blocked") {
      // 遷移を抑止する(reset()は"blocked"の時のみ呼べる。遷移する場合はproceed())
      blocker.reset();
      if (message) {
        showConfirmDialog({
          title: "",
          message: message,
          buttons: [
            {
              text: t("word.閉じる"),
              defaultButton: true,
              onClick: () => {
                // do nothing
              },
            },
          ],
          ignoreLocationChanges: true,
        });
      }
    }
  }, [blocker, message, showConfirmDialog]);
  const startBlocking = useCallback((message: string | string[]): void => setMessage(message), []);
  const stopBlocking = useCallback((): void => setMessage(null), []);
  return (
    <NavigationBlockerContext.Provider value={{ startBlocking, stopBlocking }}>
      {children}
    </NavigationBlockerContext.Provider>
  );
};
