import React, { ReactNode, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { titleBarHeight } from "./AppFrame";
import { ViewChangeButtonProps } from "../organisms/ViewChangeButton";
import { AddContentButtonProps } from "../atoms/AddContentButton";
import { FileUploadButtonProps } from "../atoms/UploadButton";
import { FolderUploadButtonProps } from "../molecules/FolderUploadButton";
import { MessageBarProps } from "../molecules/MessageBar";
import { useScrollRestoration } from "../../hooks/contexts/ScrollRestorationProvider";
import { AppContentsFrame, messageBarHeight } from "./AppContentsFrame";
import { titleHeight } from "../../common/constants/TemplateConstants";

const StyledContentContainer = styled("div")<{ headerHeight: number }>((props) => ({
  width: "100%",
  minWidth: "472px", // サムネイル2列 (218 + 8) * 2 = 452px にスクロールバーが表示されることを前提に 20px 足す
  height: `calc(100vh - ${props.headerHeight}px)`,
  overflow: "auto",
  paddingTop: "12px",
  paddingBottom: "12px",
}));

type AppDataFrameProps = {
  children: ReactNode;
  viewChangeButtonProps?: ViewChangeButtonProps;
  addContentButtonProps?: AddContentButtonProps;
  folderUploadButtonProps?: FolderUploadButtonProps;
  fileUploadButtonProps?: FileUploadButtonProps;
  messageBarProps?: MessageBarProps;
};
export const AppDataFrame: React.FC<AppDataFrameProps> = (props) => {
  const { children, ...otherProps } = props;
  const headerHeight = titleBarHeight + titleHeight + (props.messageBarProps ? messageBarHeight : 0);
  const location = useLocation();
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { onScrollForRestoration, restoreAndStartStoring } = useScrollRestoration();
  useEffect(() => {
    restoreAndStartStoring(location.pathname, scrollElementRef);
  }, [location.pathname, restoreAndStartStoring]);
  return (
    <AppContentsFrame {...otherProps}>
      <StyledContentContainer
        headerHeight={headerHeight}
        ref={scrollElementRef}
        onScroll={(e) => onScrollForRestoration(location.pathname, e)}
      >
        {children}
      </StyledContentContainer>
    </AppContentsFrame>
  );
};
