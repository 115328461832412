import * as tagTypes from "./tagTypes";
import { GetProcessResponse } from "../api.types";

// 工程情報のタグ
const type = tagTypes.Process;

// 工程情報に含まれる一覧項目のタグ
const typeItems = tagTypes.File;

// 工程情報取得時の提供タグ
export const providesProcessTags = (result: GetProcessResponse | undefined) =>
  result
    ? [...result.files.map(({ id }) => ({ type: typeItems, id })), { type: typeItems, id: tagTypes.ListId }]
    : [{ type: typeItems, id: tagTypes.ListId }];

// 工程情報一覧に影響がある場合の無効タグ
export const invalidatesProcessListTags = [{ type: type, id: tagTypes.ListId }];
