import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { t } from "i18next";

interface FormDialogProps {
  open: boolean;
  title: string;
  children: React.ReactNode;
  submitButtonText: string;
  submitButtonDisabled?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FormDialog: React.FC<FormDialogProps> = (props) => {
  const { open, title, children, submitButtonText, submitButtonDisabled, onSubmit, onCancel } = props;
  return (
    <Dialog open={open} maxWidth="sm" aria-labelledby="form-dialogtitle" aria-describedby="form-dialogdescription">
      <DialogTitle id="form-dialogtitle">{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: "0px" }}>{children}</DialogContent>
      <DialogActions>
        <Button key={`form-dialogbutton-cancel`} autoFocus onClick={onCancel}>
          {t("word.キャンセル")}
        </Button>
        <Button key={`form-dialogbutton-submit`} onClick={onSubmit} disabled={submitButtonDisabled}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
