import React from "react";

interface HiddenFileSelectorProps {
  inputRef: React.RefObject<HTMLInputElement>;
  accept?: string;
}

export const HiddenFileSelector: React.FC<HiddenFileSelectorProps> = (props) => {
  const { accept = "*", inputRef } = props;
  return <input type="file" accept={accept} style={{ display: "none" }} ref={inputRef} />;
};
