import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";

export interface SnackbarProps {
  message: string;
  onExited: (node: HTMLElement) => void;
  duration?: number;
}

export const PositionedSnackbar: React.FC<SnackbarProps> = (props: SnackbarProps) => {
  const { message, onExited, duration } = props;
  const [open, setOpen] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={duration || 3000}
      onClose={() => {
        setOpen(false);
      }}
      TransitionProps={{ onExited: onExited }}
      message={message}
      key={"verticalhorizontal"}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    />
  );
};
