import React from "react";
import styled from "@emotion/styled";
import { TableCell, TableCellProps } from "@mui/material";

const StyledMuiTableCell = styled(TableCell)({
  padding: "2px 16px",
  textAlign: "center",
  verticalAlign: "middle",
  minWidth: "78px",
  maxWidth: "78px",
  width: "78px",
  height: "37px",
});

export const StyledTableImageCell: React.FC<TableCellProps> = (props) => {
  return <StyledMuiTableCell {...props}></StyledMuiTableCell>;
};
