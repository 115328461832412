import React from "react";
import { Route } from "react-router-dom";

import { App } from "./App";
import { LoginPage } from "./pages/LoginPage";
import { SamlRedirect } from "./pages/SamlRedirect";
import { Works } from "./pages/Works";
import { Work } from "./pages/Work";
import { Episode } from "./pages/Episode";
import { Cut } from "./pages/Cut";
import { Process } from "./pages/Process";

import { DummySamlIdp } from "./test_env/dummyComponents/DummySamlIdp";

export const AppRoutes = (): React.ReactNode => {
  return (
    <Route path="/" element={<App />}>
      <Route index element={<LoginPage />} />
      <Route path="saml/redirect" element={<SamlRedirect />} />
      <Route path="works" element={<Works />} />
      <Route path="work/:workId" element={<Work />} />
      <Route path="episode/:episodeId" element={<Episode />} />
      <Route path="cut/:cutId" element={<Cut />} />
      <Route path="process/:processId" element={<Process />} />
      {
        // pathは frontend\src\mocks\handlers.ts の /api/user/saml/authorize_endpoint で返す endpoint を参照のこと
        process.env.NODE_ENV === "development" && !process.env.REACT_APP_API_BASE_URL ? (
          <Route path="dummy/saml/authorize" element={<DummySamlIdp />} />
        ) : null
      }
    </Route>
  );
};
