import { store } from "../store";
import { api } from "../api";

export const fileDownload = async (
  downloadKey: string,
  filename: string,
  onSuccess: () => void,
  onError: () => void,
) => {
  const result = store.dispatch(api.endpoints.getFile.initiate(downloadKey));
  await result;
  const selector = api.endpoints.getFile.select(downloadKey);
  const { data, error } = selector(store.getState());
  result.unsubscribe();

  if (data) {
    const url = data.Url;
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Download Error (${res.status} ${res.statusText})`);
        }
        return res.blob();
      })
      .then((blob) => {
        // 取得成功
        // <a>経由でダウンロード
        const url = URL.createObjectURL(blob);
        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = filename;
        aElement.click();
        document.body.removeChild(aElement);
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1e4);
        // console.log(`Download Success!!! (${url})`); // debug
        onSuccess();
      })
      .catch((downloadError: unknown) => {
        console.log(`Download Error (key: ${downloadKey}, url: ${url})`);
        console.log({ downloadError });
        onError();
      });
  } else if (error) {
    console.log(`get download url, Error (key: ${downloadKey})`);
    console.log({ error });
    onError();
  } else {
    // dataかerrorのどちらかが設定されるはずだが一応
    console.log(`get download url, unexpected result (key: ${downloadKey})`);
    onError();
  }
};
