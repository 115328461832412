/**
 * APIのレスポンスの日時(ISOフォーマット、UTC)を画面表示用に変換
 *
 * "2022-04-25T03:15:20+00:00" -> "2022/04/25 12:15"
 * @param {string} date 日付 ( YYYY-MM-DDThh:mm:ss+zz:zz )
 * @returns {string} 画面表示用の日付 ( YYYY/MM/DD hh:mm )
 */
export const convIsoUtcToLocaleDateTime = (date: string): string => {
  if (!date || date.length === 0 || isNaN(Date.parse(date))) return "";
  const moment = new Date(date);
  return moment.toLocaleString("ja-JP", {
    timeZone: "Asia/Tokyo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
};

/**
 * 一番新しい日時文字列を返す
 * @param {Array<string | null | undefined>} dateStrings 日時文字列配列
 * @returns {string | undefined} 一番新しい日時文字列（空配列あるいは全て null あるいは undefined の場合は undefined）
 */
export const getNewestDateString = (...dateStrings: Array<string | null | undefined>): string | undefined => {
  return dateStrings.reduce<string | undefined>((a, b) => {
    if (a && b) {
      if (new Date(a).getTime() > new Date(b).getTime()) {
        return a;
      }
      return b;
    }
    return a ? a : b ?? undefined;
  }, undefined);
};

/**
 * 候補リストから一番新しい日時文字列を返す
 * @param {{}} arg
 *   @property  candidates 候補リスト
 *   @property  default デフォルト
 * @returns {string | undefined} 一番新しい日時文字列（candidatesが空配列、あるいは全て null あるいは undefined の場合は default）
 */
export const selectNewestDateString = (arg: {
  candidates: Array<string | null | undefined>;
  default: string;
}): string => {
  const d = getNewestDateString(...arg.candidates);
  return d ?? arg.default;
};
