import React from "react";
import { ReactComponent as FolderWithFile } from "../../../assets/images/FolderWithFile.svg";

interface FolderWithFileIconProps {
  style?: React.CSSProperties;
}

export const FolderWithFileIcon: React.FC<FolderWithFileIconProps> = (props) => {
  return <FolderWithFile style={{ ...props.style, display: "inline-block" }} />;
};
