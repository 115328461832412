import { Middleware, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { save, load, LoadOptions } from "redux-localstorage-simple";

// reducers
import { authReducer } from "./authSlice";
import { selectedContentReducer } from "./titleSlice";
import { viewConfigReducer } from "./viewConfigSlice";
import { api } from "./api";

import { createLogger } from "redux-logger";

// redux-localstorage-simple
const localstorageOption: LoadOptions = {
  states: ["auth", "selectedContent", "viewConfig"],
  namespace: "app",
  namespaceSeparator: "::",
};

const middlewares: Middleware[] = [api.middleware];
if (process.env.NODE_ENV !== "test") {
  middlewares.push(save(localstorageOption));
}
if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    collapsed: true,
    diff: true,
    duration: true,
  });
  middlewares.push(logger);
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
    selectedContent: selectedContentReducer,
    viewConfig: viewConfigReducer,
    [api.reducerPath]: api.reducer,
  },
  preloadedState: process.env.NODE_ENV !== "test" ? load(localstorageOption) : undefined,
  // CI環境で下記警告が出たのでimmutableCheckとserializableCheckを無効にする。
  // console.warn
  //  ImmutableStateInvariantMiddleware took 33ms, which is more than the warning threshold of 32ms.
  //  If your state or actions are very large, you may want to disable the middleware as it might cause too much of a slowdown in development mode.
  //  See https://redux-toolkit.js.org/api/getDefaultMiddleware for instructions.
  //  It is disabled in production builds, so you don't need to worry about that.
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "test"
      ? getDefaultMiddleware().concat(middlewares)
      : getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(middlewares),
  devTools: process.env.NODE_ENV === "development",
});

// for refetchOnFocus
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
