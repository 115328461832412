import { useContext } from "react";
import { UploadContext } from "./contexts/UploadProvider";

/**
 * ファイルアップロード用の下記プロパティを返す
 * @property upload アップロード依頼関数
 * @property statusUpdated アップロード状態変更通知用のカウンタ
 * @property uploadStatuses アップロード状態（依頼時のidで uploadStatuses[id] のように使う。アップロード中でなければ uploadStatuses[id] は undefined）
 */
export const useUpload = () => {
  return useContext(UploadContext);
};
