import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export interface ProgressCircularProps {
  progress?: number;
  iconStyle?: React.CSSProperties;
}

export const ProgressCircular: React.FC<ProgressCircularProps> = (props) => {
  const { progress, iconStyle } = props;

  return <CircularProgress variant="determinate" value={progress} size={20} style={iconStyle} />;
};
