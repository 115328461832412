export const encodeUriForS3Key = (key: string): string => {
  // encodeURIでエスケープされない文字のうち、エスケープが必要な文字
  // （S3的にセーフ文字でないもの(一部のアプリケーションやプロトコルで問題が発生することがあります。とのこと)。ただし、"/"は除く(エスケープしない)）
  const encodingChar: { [c: string]: string } = {
    "#": "%23",
    // prettier-ignore
    "$": "%24",
    "&": "%26",
    "+": "%2B",
    ",": "%2C",
    ":": "%3A",
    ";": "%3B",
    "=": "%3D",
    "?": "%3F",
    "@": "%40",
    "~": "%7E",
  };
  return encodeURI(key).replace(/[#$&+,:;=?@~]/gi, (match) => {
    return encodingChar[match];
  });
};

export const makeURL = (cdnUrl: string, key: string, createdAt?: string): string => {
  const queryString = createdAt ? "?created=" + createdAt.replace(/\D/g, "") : "";
  const objectUrl = encodeUriForS3Key(key);
  return process.env.NODE_ENV === "development"
    ? `/${objectUrl}${queryString}`
    : `${cdnUrl}/${objectUrl}${queryString}`;
};
