import { convIsoUtcToLocaleDateTime } from "../../utils/dateUtil";
import { DisplayInterface } from "./DisplayInterface";
import { ComparableInterface } from "./ComparableInterface";

export class ComparableDate implements ComparableInterface, DisplayInterface {
  protected readonly numberDate: number;
  protected readonly displayDate: string;
  constructor(public readonly isoDateString: string) {
    this.numberDate = new Date(this.isoDateString).getTime();
    this.displayDate = convIsoUtcToLocaleDateTime(this.isoDateString);
  }
  get value(): string {
    return this.displayDate;
  }
  compareTo(target: ComparableDate): number {
    return this.numberDate - target.numberDate;
  }
}
