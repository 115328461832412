import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/**
 * Location遷移時にcallbackを呼ぶフック（遷移のブロックはできない）
 * (callbackを呼んだら遷移元のLocation pathは自動的に未設定にする)
 * @param onLeave callback
 * @returns 遷移元のLocation pathを設定する関数
 *          引数にnullを設定した場合は未設定になる(Locationが変化してもcallbackを呼ばない)
 */
export const useLeaveLocation = (onLeave: () => void) => {
  const [locationPath, setLocationPath] = useState<string | null>(null);
  // react-router-dom v6はuseHistoryがない。history.listenは使えない
  // 遷移ブロックも現状のv6には入っていない↓（遷移を止める必要がある場合は別途検討する）
  // https://reactrouter.com/docs/en/v6/upgrading/v5#prompt-is-not-currently-supported
  // 本家はlocation変更の検出に↓が便利と書いているので使ってみる
  // https://reactrouter.com/docs/en/v6/hooks/use-location
  const currentLocation = useLocation();
  useEffect(() => {
    // console.log(
    //   `useLeaveLocation hook. useEffect(, [locationPath: ${locationPath}, currentLocation.pathname: ${
    //     currentLocation.pathname
    //   }, onLeave: ${String(onLeave)}])`,
    // ); // debug
    if (locationPath && locationPath !== currentLocation.pathname) {
      setLocationPath(null);
      // console.log("    call onLeave()."); // debug
      onLeave();
    }
  }, [locationPath, currentLocation, onLeave]);
  return setLocationPath;
};
