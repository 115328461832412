import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { FileDownloadOutlined } from "@mui/icons-material";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "../hooks/rtk-hooks";
import { SelectedIdProvider } from "../hooks/contexts/SelectedIdProvider";
import { useGetCutQuery } from "../modules/api";
import { setSelectedCut } from "../modules/titleSlice";
import { selectProcessesSortOrder, selectProcessesViewMode, setProcessesViewMode } from "../modules/viewConfigSlice";
import { ProcessesTable, ProcessesTableComparableItem } from "../components/organisms/ListViews/ProcessesTable";
import { CardView } from "../components/organisms/CardView";
import { selectNewestDateString } from "../utils/dateUtil";
import { getComparator } from "../utils/sortUtil";
import { makeCutTitle, makeEpisodeTitle } from "../utils/makeTitleUtil";
import { ComparableDate, ProcessType, ComparableString, FolderIcon } from "../common/DisplayInterface.types";
import { useExports } from "../hooks/useExports";
import { ContainerMenuItem } from "../components/organisms/ContextMenu";
import { AppDataFrame } from "../components/templates/AppDataFrame";
import { createSelector } from "@reduxjs/toolkit";
import { GetCutResponse, ProcessesItem } from "../modules/api.types";

const ProcessesBodyContainer = styled("div")({});

// カット詳細(工程一覧)
export const Cut: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const viewMode = useAppSelector(selectProcessesViewMode);
  const { order, orderBy } = useAppSelector(selectProcessesSortOrder);
  const { cutId } = useParams<{ cutId: string | undefined }>();

  // 工程一覧取得
  const selectProcesses = useMemo(() => {
    return createSelector(
      (data?: GetCutResponse) => data?.processes ?? [],
      (processes: ProcessesItem[]) =>
        processes.map((process) => ({
          id: process.id,
          title: new ProcessType(process.type),
          thumbnail: process.thumbnail,
          status: new ComparableString(process.status ?? ""),
          updatedAt: new ComparableDate(
            selectNewestDateString({
              candidates: [process.latestFileUpdatedAt, process.thumbnail?.updatedAt],
              default: process.createdAt,
            }),
          ),
          icon: new FolderIcon(process.latestFileUpdatedAt !== null),
        })),
    );
  }, []);
  const {
    data: processesData,
    cdnUrl,
    isUninitialized,
    isLoading,
    work,
    episode,
    cut,
  } = useGetCutQuery(cutId ? { cutId: cutId } : skipToken, {
    selectFromResult: ({ data, isUninitialized, isLoading }) => ({
      data: selectProcesses(data),
      cdnUrl: data?.cdnUrl ?? "",
      isUninitialized,
      isLoading,
      work: data?.work,
      episode: data?.episode,
      cut: data?.cut,
    }),
  });

  // パンくずリスト更新
  useEffect(() => {
    if (work && episode && cut) {
      dispatch(
        setSelectedCut({
          work: { id: work.id, title: work.title },
          episode: { id: episode.id, title: makeEpisodeTitle(episode) },
          cut: { id: cut.id, title: makeCutTitle(cut) },
        }),
      );
    }
  }, [work, episode, cut, dispatch]);

  // リストビュー用データ
  const listViewData = useMemo(() => {
    return processesData;
  }, [processesData]);
  // サムネイルビュー用データ
  const thumbnailViewData = useMemo(() => {
    return processesData.sort(getComparator<ProcessesTableComparableItem>(order, orderBy)).map((value) => ({
      id: value.id,
      title: value.title,
      updatedAt: value.updatedAt,
      thumbnail: value.thumbnail,
      icon: value.icon,
    }));
  }, [processesData, order, orderBy]);
  // SelectedIdProvider 向け ID リスト
  const sortedIds = useMemo(() => {
    return processesData
      .sort(getComparator<ProcessesTableComparableItem>(order, orderBy))
      .map<string>((value) => value.id);
  }, [processesData, order, orderBy]);

  // ページ遷移
  const pageTransition = (id: string) => {
    navigate("/process/" + id);
  };

  const { exports } = useExports();
  const menuItems: Array<ContainerMenuItem> = [
    {
      text: t("word.ダウンロード"),
      icon: FileDownloadOutlined,
      handler: (ids) => {
        // ダウンロード対象は１つ
        const selectedId = ids[0];
        const title = processesData.find((process) => process.id === selectedId)?.title.value;
        if (title && work && episode && cut) {
          exports(ids, title, work.title + "_" + makeEpisodeTitle(episode) + "_" + makeCutTitle(cut) + "_" + title);
        }
      },
    },
  ];

  return (
    <AppDataFrame viewChangeButtonProps={{ selecter: selectProcessesViewMode, action: setProcessesViewMode }}>
      <div>
        <ProcessesBodyContainer>
          <SelectedIdProvider sortedIds={sortedIds}>
            {isUninitialized || isLoading ? null : viewMode === "list" ? (
              <ProcessesTable
                cdnUrl={cdnUrl}
                processes={listViewData}
                menuItems={menuItems}
                handlePageTransition={pageTransition}
              />
            ) : (
              <CardView
                cdnUrl={cdnUrl}
                items={thumbnailViewData}
                menuItems={menuItems}
                handlePageTransition={pageTransition}
              />
            )}
          </SelectedIdProvider>
        </ProcessesBodyContainer>
      </div>
    </AppDataFrame>
  );
};
