import { ComparableInterface } from "../common/DisplayInterface.types";

/**
 * 文字列比較関数
 * @param {string} a 文字列
 * @param {string} b 文字列
 * @returns aが後ろなら正値、bが後ろなら負値、同じであれば 0 (昇順)
 */
export const compareString = (a: string, b: string): number => {
  const as = String(a).replace(/(\d+)/g, (match) => match.padStart(4, "0"));
  const bs = String(b).replace(/(\d+)/g, (match) => match.padStart(4, "0"));
  if (as.toLowerCase() < bs.toLowerCase()) {
    return -1;
  }
  if (as.toLowerCase() > bs.toLowerCase()) {
    return 1;
  }
  return 0;
};

/**
 * sort順
 */
export type SortOrder = "asc" | "desc";

/**
 * Array.sort用比較関数を返す
 * @param {SortOrder} order 昇順(asc)か降順(desc)
 * @param {keyof T} orderBy ソートする項目
 * @returns 昇順では aが後ろなら正値 bが後ろなら負値、降順では aが後ろなら負値 bが後ろなら正値、同じであれば 0 を返す比較関数
 */
export function getComparator<T>(
  order: SortOrder,
  orderBy: keyof T,
): (a: { [key in keyof T]: ComparableInterface }, b: { [key in keyof T]: ComparableInterface }) => number {
  return order === "asc" ? (a, b) => a[orderBy].compareTo(b[orderBy]) : (a, b) => b[orderBy].compareTo(a[orderBy]);
}
