import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/rtk-hooks";
import { useMessage } from "../hooks/useMessage";
import { useSamlExchangeTokenMutation } from "../modules/api";
import { selectSamlState, setIdToken, setRefreshToken } from "../modules/authSlice";
import { t } from "i18next";

export const SamlRedirect: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showMessage = useMessage();
  const samlState = useAppSelector(selectSamlState);
  const [searchParams] = useSearchParams();
  const state = searchParams.get("state");
  const code = searchParams.get("code");
  const [samlExchangeToken, result] = useSamlExchangeTokenMutation();
  const requestSent = useRef(false);

  useEffect(() => {
    if (!state || samlState !== state) {
      showMessage({ message: t("phrase.Invalid state") });
      navigate("/");
    } else if (!code) {
      showMessage({ message: t("phrase.Invalid code") });
      navigate("/");
    } else if (!requestSent.current) {
      void samlExchangeToken({ code });
      // react の strict mode で useEffect が 2 回実行されて 2 回送信されるのを防ぐ
      requestSent.current = true;
    }
  }, [state, samlState, code, showMessage, navigate, samlExchangeToken]);

  useEffect(() => {
    if (result.data) {
      const currentSeconds = Math.floor(new Date().valueOf() / 1000);
      dispatch(setIdToken({ idToken: result.data.IdToken, authTime: currentSeconds }));
      dispatch(setRefreshToken({ refreshToken: result.data.RefreshToken }));
      navigate("/works");
    } else if (result.error) {
      showMessage({ message: JSON.stringify(result.error) });
      navigate("/");
    }
  }, [result, dispatch, navigate, showMessage]);

  return (
    <Grid sx={{ minHeight: "100vh" }}>
      <CircularProgress size={16} />
      <Box sx={{ fontSize: "16px", paddingLeft: "8px" }}>{t("message.ログイン中です...")}</Box>
    </Grid>
  );
};
