import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { FileDownloadOutlined as FileDownloadOutlinedIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/rtk-hooks";
import { useGetProcessQuery } from "../modules/api";
import { setSelectedProcess } from "../modules/titleSlice";
import { selectCutSortOrder, selectCutViewMode, setCutViewMode } from "../modules/viewConfigSlice";
import { useMessage } from "../hooks/useMessage";
import { SelectedIdProvider } from "../hooks/contexts/SelectedIdProvider";
import { fileDownload } from "../modules/api.funcs/fileDownload";
import { fileDelete } from "../modules/api.funcs/fileDelete";
import { UploadFileInfo, isValidUploadFileType } from "../modules/api.funcs/fileUpload";
import { getComparator } from "../utils/sortUtil";
import { makeCutTitle, makeEpisodeTitle } from "../utils/makeTitleUtil";
import { ComparableDate, FilePath, FileIcon } from "../common/DisplayInterface.types";

import { CutDetailTable, CutDetailTableComparableItem } from "../components/organisms/ListViews/CutDetailTable";
import { CardView } from "../components/organisms/CardView";
import { ImagePreview } from "../components/organisms/ImagePreview";
import { ContainerMenuItem } from "../components/organisms/ContextMenu";
import { MessageBarProps } from "../components/molecules/MessageBar";
import { useUpload } from "../hooks/useUpload";
import { useConfirmDeletion } from "../hooks/useConfirmDeletion";
import { useSafetyTrigger } from "../hooks/useSafetyTrigger";
import { t } from "i18next";
import { createSrc } from "../utils/thumbnailUtil";
import { createSelector } from "@reduxjs/toolkit";
import { CutFile, GetProcessResponse } from "../modules/api.types";
import { AppDataFrame } from "../components/templates/AppDataFrame";

const CutDetailBodyContainer = styled("div")({});

const CutDetailUploadTargetArea = styled("div")({
  width: "100%",
  height: "100%",
});

const CutDetailBodyUploadArea = styled("div")({
  display: "flex",
  width: "100%",
  height: 400,
  verticalAlign: "middle",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
});
const CutDetailBodyUploadMessage = styled("div")({
  display: "flex",
  verticalAlign: "middle",
});

// 工程別カット詳細
export const Process: React.FC = () => {
  const viewMode = useAppSelector(selectCutViewMode);
  const { order, orderBy } = useAppSelector(selectCutSortOrder);
  const { processId } = useParams<{ processId: string | undefined }>();
  const dispatch = useAppDispatch();
  const showMessage = useMessage();
  const { upload, statusUpdated, uploadStatuses } = useUpload();
  const [uploadDatetime, setUploadDatetime] = useState(new Date(0));
  const [uploadFilesTotal, setUploadFilesTotal] = useState<number>(0); // アップロードするファイル数(0ならアップロードしていない)
  const [uploadedFiles, setUploadedFiles] = useState<number>(0); // アップロードしたファイル数
  const confirmDeletion = useConfirmDeletion();
  const [needPollingFileList, setNeedPollingFileList] = useState(false);
  const [uploadedFilesInfo, setUploadedFilesInfo] = useState<Omit<UploadFileInfo, "file" | "errorMessage">[]>([]);

  useEffect(() => {
    if (processId) {
      // IDが変わった（あるいはマウント直後）
      // ファイル一覧取得ポーリングを止める
      setNeedPollingFileList(false);
      // アップロード関連stateリセット
      setUploadFilesTotal(0);
      setUploadedFiles(0);
      setUploadedFilesInfo([]);
    }
  }, [processId]);

  // ファイル一覧取得
  const selectFiles = useMemo(() => {
    return createSelector(
      (data?: GetProcessResponse) => data?.files ?? [],
      (files: CutFile[]) =>
        files.map((cutFile) => ({
          id: cutFile.id,
          title: new FilePath((cutFile.path?.length ? cutFile.path + "/" : "") + cutFile.name),
          updatedAt: new ComparableDate(cutFile.lastModified),
          thumbnail: cutFile.thumbnail,
          icon: new FileIcon(cutFile.name),
          // ファイル操作のために必要であろうため下記も返す
          path: cutFile.path,
          name: cutFile.name,
        })),
    );
  }, []);
  const {
    data: cutData,
    cdnUrl: fileCdn,
    isUninitialized,
    isLoading,
    refetch: refetchCut,
    work,
    episode,
    cut,
    process,
  } = useGetProcessQuery(
    { processId: processId ?? "" },
    {
      selectFromResult: ({ data, isUninitialized, isLoading }) => ({
        data: selectFiles(data),
        cdnUrl: data?.cdnUrl ?? "",
        isUninitialized,
        isLoading,
        work: data?.work,
        episode: data?.episode,
        cut: data?.cut,
        process: data?.process,
      }),
      pollingInterval: needPollingFileList ? 1000 : 0,
    },
  );
  const pullRefetchTrigger = useSafetyTrigger(() => void refetchCut());

  // パンくずリスト更新
  useEffect(() => {
    if (work && episode && cut && process) {
      dispatch(
        setSelectedProcess({
          work: { id: work.id, title: work.title },
          episode: { id: episode.id, title: makeEpisodeTitle(episode) },
          cut: { id: cut.id, title: makeCutTitle(cut) },
          process: { id: process.id, title: process.type },
        }),
      );
    }
  }, [work, episode, cut, process, dispatch]);

  // リストビュー用データ
  const listViewData = useMemo(() => {
    return cutData.map((value) => ({
      id: value.id,
      title: value.title,
      updatedAt: value.updatedAt,
      thumbnail: value.thumbnail,
      icon: value.icon,
    }));
  }, [cutData]);
  // サムネイルビュー用データ
  const thumbnailViewData = useMemo(() => {
    return cutData.sort(getComparator<CutDetailTableComparableItem>(order, orderBy)).map((value) => ({
      id: value.id,
      title: value.title,
      updatedAt: value.updatedAt,
      thumbnail: value.thumbnail,
      icon: value.icon,
    }));
  }, [cutData, order, orderBy]);
  // SelectedIdProvider 向け ID リスト
  const sortedIds = useMemo(() => {
    return cutData.sort(getComparator<CutDetailTableComparableItem>(order, orderBy)).map<string>((value) => value.id);
  }, [cutData, order, orderBy]);

  // アップロードprogress更新
  useEffect(() => {
    if (processId) {
      const uploadingStatus = uploadStatuses[processId];
      if (uploadingStatus) {
        // console.log(
        //   `Process: update upload progress (id: ${processId}, ${uploadingStatus.uploadedCount} / ${uploadingStatus.uploadTotal})`,
        // ); // debug
        setUploadedFiles(uploadingStatus.uploadedCount);
        setUploadFilesTotal(uploadingStatus.uploadTotal);
      } else {
        setUploadedFiles(0);
        setUploadFilesTotal(0);
      }
    }
  }, [processId, statusUpdated, uploadStatuses]);

  // アップロード依頼（uploadイベントハンドラ）
  const handleUploadEvent = (e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    if (!processId || !process) {
      return;
    }
    void upload(processId, {
      event: e,
      name: process.type,
      isUploadFileType: isValidUploadFileType,
      onEachSuccess: () => null,
      onEachError: () => {
        // do nothing
      },
      onAllSuccess: (id, name, uploadedFiles, uploadedAllAt) => {
        setUploadDatetime(uploadedAllAt);
        setUploadedFilesInfo(
          uploadedFiles.map((v) => ({
            id: v.id,
            path: v.path,
            name: v.name,
            status: v.status,
            startedAt: v.startedAt,
          })),
        );
        setNeedPollingFileList(true);
        pullRefetchTrigger();
      },
      onSomeErrors: (id, name, uploadedFiles, uploadedAllAt) => {
        setUploadDatetime(uploadedAllAt);
        // アップロードが成功したファイルがある場合のみファイル一覧をポーリングする
        const uploadSuccessFilesInfo = uploadedFiles
          .filter((v) => v.status === "success")
          .map((v) => ({
            id: v.id,
            path: v.path,
            name: v.name,
            status: v.status,
            startedAt: v.startedAt,
          }));
        if (uploadSuccessFilesInfo.length > 0) {
          setUploadedFilesInfo(uploadSuccessFilesInfo);
          setNeedPollingFileList(true);
        }
        pullRefetchTrigger();
      },
    });
  };

  // ファイル一覧取得のポーリング停止判定（アップロード後、サムネイル作成中はしばらくポーリングする）
  useEffect(() => {
    if (needPollingFileList) {
      if (new Date().getTime() > uploadDatetime.getTime() + 30000) {
        // ポーリング時間オーバー
        setNeedPollingFileList(false);
        // console.log(`######## ポーリング時間オーバー`); // debug
        return;
      }
      if (listViewData && listViewData.length > 0) {
        // アップロードしたファイルを抽出（uploadedFilesInfoはアップロード成功の情報のみ。アップロード開始時間も付加）
        const fileAndUploadedInfo = [];
        for (const u of uploadedFilesInfo) {
          const key = "contents/" + u.id + "/" + (u.path ? u.path + "/" : "") + u.name;
          const uploaded = listViewData.find((v) => v.id === key);
          if (uploaded && u.startedAt && !/\.xdts$/i.test(u.name)) {
            // ファイル一覧にあるファイルで、タイムシートではない
            fileAndUploadedInfo.push({ ...uploaded, startedAt: u.startedAt });
          }
        }
        if (fileAndUploadedInfo.length === 0) {
          // アップロード完了後に listViewData が更新されていない（アップロード前のファイル一覧が残っている）
          return;
        }
        // アップロード後にサムネイルがまだ作成されていないファイルがあるか？
        const containsNoThumbnails = fileAndUploadedInfo.some(
          (v) => !v.thumbnail || v.startedAt.getTime() > new Date(v.thumbnail.updatedAt).getTime(),
        );
        if (!containsNoThumbnails) {
          // サムネイル作成完了
          setNeedPollingFileList(false);
          // console.log(`######## サムネイル作成完了`); // debug
        }
      }
    }
  }, [listViewData, needPollingFileList, uploadDatetime, uploadedFilesInfo]);

  // ファイルダウンロード
  const doDownload = (targetIds: Array<string>): void => {
    // console.log("Process.doDownload"); // debug
    // console.log({ targetIds }); // debug
    if (targetIds.length > 0) {
      // 1ファイルのみ
      const downloadKey = targetIds[0];
      const file = cutData.find((file) => file.id === downloadKey);
      const filename = file ? file.name : "";
      void fileDownload(
        downloadKey,
        filename,
        () => {
          // do nothing
        },
        () => showMessage({ message: t("message.ダウンロードが失敗しました。") }),
      );
    }
  };

  // ファイル削除
  const doDelete = (targetIds: Array<string>): void => {
    if (targetIds.length > 0) {
      const target = cutData.find((file) => file.id === targetIds[0]);
      if (target) {
        confirmDeletion(
          target.title.value, // 複数ファイル削除の場合は無視される
          targetIds.length > 1 ? targetIds : target.id,
          t("word.ファイル"),
          fileDelete,
          () => pullRefetchTrigger(),
          () => pullRefetchTrigger(),
        );
      }
    }
  };

  // 右クリックメニューの設定
  const menuItems: Array<ContainerMenuItem> = [
    { text: t("word.ダウンロード"), icon: FileDownloadOutlinedIcon, handler: doDownload },
    { text: t("word.削除"), icon: DeleteIcon, handler: doDelete, allowMultipleSelect: true },
  ];

  // ImagePreviewを開く際の最初に表示するファイルのindex（0以上の場合はImagePreviewを表示する）
  const [imagePreviewInitialIndex, setImagePreviewInitialIndex] = useState<number>(-1);
  // ImagePreviewに渡すitemsを作る関数（サムネイルのないファイルは(タイムシートも)外す）
  const imagePreviewItems = useMemo(() => {
    return cutData
      .filter((item) => item.thumbnail)
      .sort(getComparator<CutDetailTableComparableItem>(order, orderBy))
      .map((item) => {
        return {
          id: item.id,
          title: item.title.value,
          thumbnails: item.thumbnail ? [createSrc(fileCdn, item.thumbnail, "original")] : [],
        };
      });
  }, [cutData, order, orderBy, fileCdn]);

  // ページ遷移（このコンポーネントの場合はImagePreviewを開く）
  const pageTransition = (id: string): void => {
    // console.log(`Double Click! id: ${id}, title: ${title}`); // debug
    // クリックされた画像を初期表示する
    const imagePreviewInitialIndex = imagePreviewItems.findIndex((item) => item.id === id);
    // ImagePreviewを開く
    setImagePreviewInitialIndex(imagePreviewInitialIndex);
  };

  // プログレスバー表示
  const progressMessages = (): MessageBarProps | undefined => {
    const messages: string[] = [];
    if (uploadFilesTotal !== 0) {
      messages.push(
        t("message.アップロード中… (uploadedFiles/uploadFilesTotal)", {
          uploadedFiles: uploadedFiles,
          uploadFilesTotal: uploadFilesTotal,
        }),
      );
    }
    if (messages.length > 0) {
      return { messages: messages };
    }
    return undefined;
  };

  return (
    <AppDataFrame
      viewChangeButtonProps={{ selecter: selectCutViewMode, action: setCutViewMode }}
      folderUploadButtonProps={{ onInputFileChange: handleUploadEvent }}
      fileUploadButtonProps={{ onInputFileChange: handleUploadEvent, accept: ".xdts, .tga, image/*" }}
      messageBarProps={progressMessages()}
    >
      <>
        {imagePreviewInitialIndex < 0 ? null : (
          <ImagePreview
            items={imagePreviewItems}
            initialIndex={imagePreviewInitialIndex}
            onClose={() => setImagePreviewInitialIndex(-1)}
          />
        )}
        <CutDetailUploadTargetArea
          onDragOver={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.dataTransfer.dropEffect = "copy";
          }}
          onDrop={handleUploadEvent}
          aria-label="file-drop-target-area"
        >
          <CutDetailBodyContainer>
            <SelectedIdProvider sortedIds={sortedIds}>
              {isUninitialized || isLoading ? null : listViewData.length === 0 ? (
                <CutDetailBodyUploadArea>
                  <CutDetailBodyUploadMessage>
                    {t("message.ここにファイルをドラッグ＆ドロップしてください")}
                  </CutDetailBodyUploadMessage>
                </CutDetailBodyUploadArea>
              ) : viewMode === "list" ? (
                <CutDetailTable
                  cdnUrl={fileCdn}
                  cutData={listViewData}
                  menuItems={menuItems}
                  handlePageTransition={pageTransition}
                />
              ) : (
                <CardView
                  cdnUrl={fileCdn}
                  items={thumbnailViewData}
                  menuItems={menuItems}
                  handlePageTransition={pageTransition}
                />
              )}
            </SelectedIdProvider>
          </CutDetailBodyContainer>
        </CutDetailUploadTargetArea>
      </>
    </AppDataFrame>
  );
};
