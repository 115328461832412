import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AuthState {
  idToken: string | null;
  refreshToken: string | null;
  authTime: number | null;
  samlState: string | null;
}
const initialState: AuthState = {
  idToken: null,
  refreshToken: null,
  authTime: null,
  samlState: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initAuth: () => initialState,
    setIdToken: (state, action: PayloadAction<{ idToken: string; authTime: number }>) => {
      state.idToken = action.payload.idToken;
      state.authTime = action.payload.authTime;
    },
    resetIdToken: (state) => {
      state.idToken = initialState.idToken;
      state.authTime = initialState.authTime;
    },
    setRefreshToken: (state, action: PayloadAction<{ refreshToken: string }>) => {
      state.refreshToken = action.payload.refreshToken;
    },
    setSamlState: (state, action: PayloadAction<{ samlState: string }>) => {
      state.samlState = action.payload.samlState;
    },
  },
});

export const authReducer = slice.reducer;

export const { initAuth, setIdToken, resetIdToken, setRefreshToken, setSamlState } = slice.actions;

export const selectSamlState = (state: RootState) => state.auth.samlState;
export const selectIdToken = (state: RootState) => state.auth.idToken;
