import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { useAppDispatch } from "../hooks/rtk-hooks";
import { resetSelectedContent } from "../modules/titleSlice";
import { initAuth, setSamlState } from "../modules/authSlice";
import { EmailEntry } from "../components/organisms/EmailEntry";
import { Login } from "../components/organisms/Login";
import { NewPassword } from "../components/organisms/NewPassword";

const StyledLoginPage = styled("div")({
  textAlign: "center",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "calc(10px + 2vmin)",
});

function createSamlState() {
  // 乱数の安全性的には crypto.randomUUID() あたりを使ったほうがよいが、
  // unittest 時に crypto を使うには少し設定が必要なのと、
  // 短期間のみ使用する一時的な値なので、 代わりに Math.random() ベースで作成する。
  // 2 回繰り返しているのは少し長くするため。
  return Math.random().toString(32).substring(2) + Math.random().toString(32).substring(2);
}

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetSelectedContent());
    dispatch(initAuth());
    dispatch(setSamlState({ samlState: createSamlState() }));
    // マウント時に1回だけ実行したい([]にしないといけない)のだが、dispatchが依存リストにないと警告されるので
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [email, setEmail] = useState<string>();
  const onSetNeedPasswordLogin = (email: string) => {
    setEmail(email);
  };

  const [session, setSession] = useState<string>();
  const [userId, setUserId] = useState<string>();
  const [isNeedChallenge, setIsNeedChallenge] = useState<boolean>(false);
  const onSetNeedChallenge = (session: string, userId: string) => {
    setSession(session);
    setUserId(userId);
    setIsNeedChallenge(true);
  };

  return (
    <StyledLoginPage>
      {!email ? (
        <EmailEntry onSetNeedPasswordLogin={onSetNeedPasswordLogin} />
      ) : !isNeedChallenge ? (
        <Login onSetNeedChallenge={onSetNeedChallenge} email={email} />
      ) : (
        <NewPassword session={session as string} userId={userId as string} />
      )}
    </StyledLoginPage>
  );
};
