import React, { createContext, ReactNode } from "react";
import { useExportsMutation } from "../../modules/api";
import { useExportsJobList } from "./../useExportsJobList";
import { useMessage } from "./../useMessage";
import { convertToValidFileName } from "../../utils/fileNameUtil";
import { t } from "i18next";

export const ExportsContext = createContext<{
  exports: (ids: string[], title: string, fileName: string, dir?: string) => void;
}>({
  exports: () => {
    // do nothing
  },
});
ExportsContext.displayName = "ExportsContext";

interface ExportsProviderProps {
  children: ReactNode;
}
export const ExportsProvider: React.FC<ExportsProviderProps> = ({ children }) => {
  const { appendExportsJob } = useExportsJobList();
  const [exportsMutation] = useExportsMutation();
  const showMessage = useMessage();

  const exports = (ids: string[], title: string, fileName: string): void => {
    const archiveName = convertToValidFileName(fileName);
    const exports = exportsMutation({ items: [{ id: ids[0] }], archiveName: archiveName }).unwrap();
    exports
      .then((res) => {
        appendExportsJob(res.exportJob.id, title);
      })
      .catch((error) => {
        console.error(error);
        showMessage({ message: t("message.ダウンロード処理でエラーが発生しました。") });
      });
  };

  const contextValue = { exports };
  return <ExportsContext.Provider value={contextValue}>{children}</ExportsContext.Provider>;
};
