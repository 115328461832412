import React from "react";
import styled from "@emotion/styled";

type ContainerProps = { containerHeight: number; aspectRatio: number };
type Props = React.ComponentProps<"img"> & ContainerProps;

const StyledImage = styled("img")({
  border: "thin solid #eeeeee",
  background: "#F1F4F6",
  display: "block",
  margin: "auto",
  position: "absolute",
  inset: "0px",
  width: "auto",
  height: "auto",
  maxWidth: "100%",
  maxHeight: "100%",
});

const StyledImageContainer = styled("div")<ContainerProps>((props) => ({
  position: "relative",
  height: `${props.containerHeight}px`,
  aspectRatio: `${props.aspectRatio}`,
}));

const ThumbnailImage: React.FC<Props> = (props) => {
  const { containerHeight, aspectRatio, ...rest } = props;
  return (
    <StyledImageContainer containerHeight={containerHeight} aspectRatio={aspectRatio}>
      <StyledImage {...rest} />
    </StyledImageContainer>
  );
};
export default ThumbnailImage;
