import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SvgIconComponent } from "@mui/icons-material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import styled from "@emotion/styled";

const StyledMenuIcon = styled(ListItemIcon)({
  color: "#666",
});
const StyledMenuText = styled(ListItemText)({
  marginRight: "16px",
});

export interface ContextMenuItem {
  text: string;
  icon?: SvgIconComponent;
  handleClick: () => void;
}
export interface ContextMenuProps {
  position: { x: number; y: number } | null;
  handleClose: () => void;
  items: Array<ContextMenuItem>;
}

export const ContextMenu: React.FC<ContextMenuProps> = (props: ContextMenuProps) => {
  const { position, handleClose, items } = props;
  const [open, setOpen] = useState<boolean>(position !== null);
  return (
    <Menu
      open={open}
      onClose={handleClose}
      variant="menu"
      onContextMenu={(e) => {
        e.preventDefault();
        setOpen(false);
        handleClose();
      }}
      anchorReference="anchorPosition"
      anchorPosition={position ? { top: position.y - 10, left: position.x + 10 } : undefined}
      autoFocus={false}
    >
      {items.map((item, index) => (
        <MenuItem key={`context-menu-item-${index}`} onClick={item.handleClick}>
          {item.icon && (
            <StyledMenuIcon>
              <item.icon />
            </StyledMenuIcon>
          )}
          <StyledMenuText>{item.text}</StyledMenuText>
        </MenuItem>
      ))}
    </Menu>
  );
};
