import React, { createContext, ReactNode, useEffect, useState } from "react";
import { DownloadList, DownloadListJobsItem } from "../../components/organisms/DownloadList";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { useMessage } from "../../hooks/useMessage";
import { useConfirmBeforeClose } from "../../hooks/useConfirmBeforeClose";
import { t } from "i18next";
interface ExportsJobsItem {
  jobId: string;
  title: string;
}
type ExportsJobs = Array<ExportsJobsItem>;

export const ExportsJobListContext = createContext<{
  appendExportsJob: (jobId: string, title: string) => void;
}>({
  appendExportsJob: () => {
    // do nothing
  },
});
ExportsJobListContext.displayName = "ExportsJobListContext";

interface ExportsJobListProviderProps {
  children: ReactNode;
}

export const ExportsJobListProvider: React.FC<ExportsJobListProviderProps> = ({ children }) => {
  const [openDownloadList, setOpenDownloadList] = useState<boolean>(false);
  const [exportsJobs, setExportsJobs] = useState<ExportsJobs>([]);
  const showConfirmDialog = useConfirmDialog();
  const showMessage = useMessage();
  const shouldConfirmBeforeClose = useConfirmBeforeClose();

  const appendExportsJob = (jobId: string, title: string) => {
    setExportsJobs((prev) => [...prev, { jobId: jobId, title: title }]);
    setOpenDownloadList(true);
    shouldConfirmBeforeClose("ExportsJobListProvider", true);
  };

  const handleComplete = (jobId: string): void => {
    setExportsJobs((prev) => [...prev.filter((v) => v.jobId !== jobId)]);
  };

  const handleError = (jobId: string): void => {
    setExportsJobs((prev) => [...prev.filter((v) => v.jobId !== jobId)]);
    showMessage({ message: t("message.ダウンロード処理でエラーが発生しました。") });
  };

  const handleCancel = (): void => {
    setExportsJobs([]);
  };

  useEffect(() => {
    if (exportsJobs.length === 0) {
      setOpenDownloadList(false);
      shouldConfirmBeforeClose("ExportsJobListProvider", false);
    }
  }, [exportsJobs, shouldConfirmBeforeClose]);

  const handleConfirm = () => {
    showConfirmDialog({
      title: t("phrase.ダウンロードの中止"),
      message: t("message.ダウンロードは完了していません。ダウンロードを中止しますか？"),
      buttons: [
        {
          text: t("word.続行"),
          defaultButton: true,
          onClick: () => {
            // do nothing
          },
        },
        {
          text: t("word.中止"),
          defaultButton: false,
          onClick: handleCancel,
        },
      ],
    });
  };

  const jobs = (): Array<DownloadListJobsItem> => {
    return exportsJobs.map<DownloadListJobsItem>((v) => ({
      jobId: v.jobId,
      title: v.title,
    }));
  };

  return (
    <ExportsJobListContext.Provider value={{ appendExportsJob }}>
      {children}
      {openDownloadList ? (
        <DownloadList jobs={jobs()} onClose={handleConfirm} onComplete={handleComplete} onError={handleError} />
      ) : null}
    </ExportsJobListContext.Provider>
  );
};
