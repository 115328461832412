import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/rtk-hooks";
import { useMessage } from "../../../hooks/useMessage";
import { useChallengeNewPasswordMutation } from "../../../modules/api";
import { setIdToken, setRefreshToken } from "../../../modules/authSlice";
import { InputText } from "../../atoms/InputText";
import { t } from "i18next";

const StyledNewPasswordButtonContainer = styled("div")({
  marginTop: 10,
});

const InitialPasswordTitle = styled("div")({
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "calc(10px + 2vmin)",
});

const RequirementContainer = styled("div")({
  marginTop: 20,
  marginBottom: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "calc(4px + 2vmin)",
});

const PasswordRequirement = styled("ul")({
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
  fontSize: "calc(4px + 1.2vmin)",
  paddingLeft: "0px",
});

type NewPasswordFormInputs = {
  newPassword?: string | undefined;
  confirmPassword?: string | undefined;
};
const newPasswordDefaultValues: DefaultValues<NewPasswordFormInputs> = {
  newPassword: "",
  confirmPassword: "",
};

interface NewPasswordProps {
  session: string;
  userId: string;
}

export const NewPassword: React.FC<NewPasswordProps> = (props: NewPasswordProps) => {
  const { session, userId } = props;
  const navigate = useNavigate();
  const showMessage = useMessage();
  const dispach = useAppDispatch();

  const {
    control: newPasswordControl,
    handleSubmit: newPasswordHandleSubmit,
    reset: newPasswordReset,
    getValues,
  } = useForm<NewPasswordFormInputs>({ defaultValues: newPasswordDefaultValues });

  const newPasswordValidationRules = {
    newPassword: {
      required: "please enter your new password.",
      pattern: {
        value: /^(?=.*?[A-Z]+)(?=.*?[a-z]+)(?=.*?[0-9]+)(?=.*?[!-/:-@[-`{-~]+)[!-~]{16,}$/,
        message: "please enter the correct password.",
      },
    },
    confirmPassword: {
      required: "please enter your new password again.",
      validate: (value: string) => value === getValues("newPassword") || "the password is different",
    },
  };

  const [challengeNewPassword] = useChallengeNewPasswordMutation();

  const onSubmitNewPassword: SubmitHandler<NewPasswordFormInputs> = async (data: NewPasswordFormInputs) => {
    // validationRulesを設定しているので、data.newPasswordは空ではない状態のはず。
    process.env.NODE_ENV === "development" &&
      console.log(`{"newPassword": "${data.newPassword ?? ""}", "confirmPassword": "${data.confirmPassword ?? ""}"}`);

    // 新パスワード設定を実行する
    await challengeNewPassword({
      USER_ID_FOR_SRP: userId,
      new_password: data.newPassword as string,
      Session: session,
    })
      .unwrap()
      .then((challengeResponse) => {
        // console.log({ challengeResponse }); // debug
        if ("ChallengeName" in challengeResponse) {
          // 新規にパスワード設定が必要
          newPasswordReset();
        } else if ("AuthenticationResult" in challengeResponse) {
          // 成功
          const idToken = challengeResponse["AuthenticationResult"]["IdToken"];
          const currentSeconds = Math.floor(new Date().valueOf() / 1000);
          dispach(setIdToken({ idToken: idToken, authTime: currentSeconds }));
          // console.log({ idToken }); // debug
          const refreshToken = challengeResponse["AuthenticationResult"]["RefreshToken"];
          dispach(setRefreshToken({ refreshToken: refreshToken }));
          // console.log({ refreshToken }); // debug
          // 作品一覧へ遷移
          navigate("/works");
        }
      })
      .catch((challengeNewPasswordError: unknown) => {
        // 失敗
        console.log({ challengeNewPasswordError });
        showMessage({ message: t("message.パスワード設定が失敗しました") });
      });
  };

  return (
    <div>
      <InitialPasswordTitle>
        <div>{t("phrase.初回パスワード設定")}</div>
      </InitialPasswordTitle>
      <RequirementContainer>
        {t("message.以下の条件を満たすパスワードを設定してください。")}
        <PasswordRequirement>
          <li>{t("message.英大文字、英小文字、数字、記号をそれぞれ1文字以上含む16文字以上")}</li>
          <li>{t("message.利用可能な記号は")}</li>
        </PasswordRequirement>
      </RequirementContainer>
      <form onSubmit={newPasswordHandleSubmit(onSubmitNewPassword)}>
        <div>
          <InputText
            name="newPassword"
            label={t("phrase.新しいパスワード")}
            control={newPasswordControl}
            defaultValue={newPasswordDefaultValues.newPassword}
            rules={newPasswordValidationRules.newPassword}
            type="password"
            autoFocus={true}
          />
        </div>
        <div>
          <InputText
            name="confirmPassword"
            label={t("phrase.新しいパスワード（確認用）")}
            control={newPasswordControl}
            defaultValue={newPasswordDefaultValues.confirmPassword}
            rules={newPasswordValidationRules.confirmPassword}
            type="password"
          />
        </div>
        <StyledNewPasswordButtonContainer>
          <Button variant="contained" type="submit">
            {t("phrase.パスワードを設定")}
          </Button>
        </StyledNewPasswordButtonContainer>
      </form>
    </div>
  );
};
