import React from "react";
import styled from "@emotion/styled";
import { ImageListItem, ImageListItemBar } from "@mui/material";
import CardViewItemBarText from "../../atoms/CardViewItemBarText";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ItemButton from "../../atoms/ItemButton";

const StyledImageListItemBar = styled(ImageListItemBar)({
  paddingTop: 6,
  textAlign: "center",
});

export interface AddContentCardProps {
  title: string;
  onClick?: () => void;
}

const AddContentCard: React.FC<AddContentCardProps> = (props) => {
  const { onClick, title } = props;
  return (
    <ItemButton
      selected={false}
      onClick={onClick}
      boxshadow={
        "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
      }
    >
      <ImageListItem>
        <AddOutlinedIcon style={{ color: "#666", width: "150px", height: "60px", margin: "45px" }} />
        <StyledImageListItemBar title={<CardViewItemBarText>{title}</CardViewItemBarText>} position="below" />
      </ImageListItem>
    </ItemButton>
  );
};

export default AddContentCard;
