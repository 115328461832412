import React from "react";
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { t } from "i18next";

import { Thumbnail } from "../../../modules/api.types";
import { getComparator } from "../../../utils/sortUtil";
import { CutOrderBy, selectCutSortOrder, setCutSortOrder } from "../../../modules/viewConfigSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/rtk-hooks";
import { useSelectedId } from "../../../hooks/useSelectedId";
import { ContainerMenuItem } from "../ContextMenu";
import { ListViewThumbnailImage } from "../../atoms/ListViewThumbnailImage";
import { StyledTableCell, StyledTableImageCell, StyledTableRow } from "../../atoms/StyledTableParts";
import { useClickEventsToActions } from "../../../hooks/useClickEventsToActions";
import { ComparableDate, FilePath, IconInterface } from "../../../common/DisplayInterface.types";
import { createSrc } from "../../../utils/thumbnailUtil";

export interface CutDetailTableComparableItem {
  title: FilePath;
  updatedAt: ComparableDate;
}

export interface CutDetailTableItem extends CutDetailTableComparableItem {
  id: string;
  thumbnail?: Thumbnail;
  icon: IconInterface;
}

interface CutDetailTableProps {
  cdnUrl: string;
  cutData: Array<CutDetailTableItem>;
  menuItems: Array<ContainerMenuItem>;
  handlePageTransition: (id: string, title: string) => void;
}

export const CutDetailTable: React.FC<CutDetailTableProps> = (props: CutDetailTableProps) => {
  const { cdnUrl, cutData, menuItems, handlePageTransition } = props;
  const dispatch = useAppDispatch();
  // ソート条件
  const { order, orderBy } = useAppSelector(selectCutSortOrder);
  const createSortHandler = (property: keyof CutDetailTableComparableItem) => () => {
    dispatch(setCutSortOrder({ order: order === "asc" ? "desc" : "asc", orderBy: property as CutOrderBy }));
  };
  // 選択中のID
  const { isSelectedId } = useSelectedId();
  // クリックイベントをアクションにマップする
  const { handleClick, handleDoubleClick, handleContextMenu } = useClickEventsToActions(
    handlePageTransition,
    menuItems,
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>
              <TableSortLabel
                active={orderBy === "title"}
                direction={orderBy === "title" ? order : "asc"}
                onClick={createSortHandler("title")}
              >
                {t("word.名前")}
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
              >
                {t("word.最終更新")}
              </TableSortLabel>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cutData.sort(getComparator<CutDetailTableComparableItem>(order, orderBy)).map((item) => (
            <StyledTableRow
              key={item.id}
              selected={isSelectedId(item.id)}
              onContextMenu={(e) => handleContextMenu(e, item.id, item.title.value)}
              onClick={(e) => handleClick(e, item.id, item.title.value)}
              onDoubleClick={(e) => handleDoubleClick(e, item.id, item.title.value)}
            >
              <StyledTableImageCell>
                {item.thumbnail ? (
                  <ListViewThumbnailImage
                    src={createSrc(cdnUrl, item.thumbnail, "small")}
                    alt={item.title.value}
                    style={
                      item.thumbnail.width / item.thumbnail.height > 1.4142 ? { width: "100%" } : { height: "100%" }
                    }
                  />
                ) : (
                  item.icon.listView
                )}
              </StyledTableImageCell>
              <StyledTableCell>{item.title.value}</StyledTableCell>
              <StyledTableCell width="25%">{item.updatedAt.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
