import React from "react";
import { viewMode } from "../../../modules/viewConfigSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/rtk-hooks";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import ViewModuleOutlinedIcon from "@mui/icons-material/ViewModuleOutlined";
import styled from "@emotion/styled";
import { RootState } from "../../../modules/store";
import { IconButton } from "../../atoms/IconButton";
import { t } from "i18next";

const ViewChangeButtonIcon = styled("div")({});

export interface ViewChangeButtonProps {
  selecter: (state: RootState) => viewMode;
  action: (payload: { view: viewMode }) => {
    payload: {
      view: viewMode;
    };
    type: string;
  };
}

export const ViewChangeButton: React.FC<ViewChangeButtonProps> = (props: ViewChangeButtonProps) => {
  const { selecter, action } = props;
  const viewMode = useAppSelector(selecter);
  const dispatch = useAppDispatch();

  const viewChange = () => {
    dispatch(action({ view: viewMode === "list" ? "thumbnail" : "list" }));
  };

  return (
    <ViewChangeButtonIcon onClick={viewChange}>
      {viewMode === "list" ? (
        <IconButton
          tooltip={t("phrase.画像を大きく表示")}
          icon={ViewModuleOutlinedIcon}
          buttonStyle={{
            padding: "0",
            cursor: "pointer",
          }}
        />
      ) : (
        <IconButton
          tooltip={t("phrase.リストで表示")}
          icon={ViewListOutlinedIcon}
          buttonStyle={{
            padding: "0",
            cursor: "pointer",
          }}
        />
      )}
    </ViewChangeButtonIcon>
  );
};
