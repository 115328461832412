import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "@emotion/styled";

const StyledErrorIcon = styled(ErrorOutlineIcon)({
  fontSize: 20,
  color: "#b71c1c",
});

export const ErrorIcon: React.FC = () => {
  return <StyledErrorIcon />;
};
