import React from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

interface NotImageFileIconProps {
  style?: React.CSSProperties;
}

export const NotImageFileIcon: React.FC<NotImageFileIconProps> = (props) => {
  return <InsertDriveFileOutlinedIcon {...props} />;
};
