import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import TreeView from "@mui/lab/TreeView";
import { useTreeViewExpanding } from "../../../hooks/contexts/TreeViewProvider";
import { useGetWorksAndEpisodesQuery } from "../../../modules/api";
import { CutProcessTreeComponent } from "../../molecules/CutProcessTreeItem";
import { EpisodeForTreeView, WorkForTreeView } from "../../../modules/api.types/worksAndEpisodes";
import { getComparator } from "../../../utils/sortUtil";
import { ExpandableTreeItem } from "../../atoms/ExpandableTreeItem";
import { TreeItemLabel } from "../../atoms/TreeItemLabel";
import { EpisodeTitle } from "../../../common/DisplayInterface.types";

const buildEpisodeTreeItems = (expandedIds: string[], episodes: EpisodeForTreeView[]) => {
  const renamedEpisodes = episodes.map((episode) => {
    return {
      id: episode.id,
      subtitle: new EpisodeTitle(episode.no, episode.subtitle),
      no: episode.no,
    };
  });

  const sortedEpisodes = renamedEpisodes.sort(getComparator<{ subtitle: EpisodeTitle }>("asc", "subtitle"));
  return sortedEpisodes.map((episode) => {
    const expandedEpisodeId = expandedIds.find((element) => element === episode.id);
    const episodeLabel = <TreeItemLabel to={`/episode/${episode.id}`} title={episode.subtitle.value} />;
    if (expandedEpisodeId) {
      return (
        <ExpandableTreeItem id={episode.id} label={episodeLabel} key={`treeItemEpisode-${episode.id}`}>
          <CutProcessTreeComponent episodeId={episode.id} />
        </ExpandableTreeItem>
      );
    } else {
      return <ExpandableTreeItem id={episode.id} label={episodeLabel} key={`treeItemEpisode-${episode.id}`} />;
    }
  });
};

const buildWorksTreeItem = (workData: WorkForTreeView[], expandedIds: string[]) => {
  const worksLabel = <TreeItemLabel to={`/works`} title={"作品"} />;
  return (
    <ExpandableTreeItem id={"works"} label={worksLabel} key={`treeItemWorks`}>
      {workData.map((work) => {
        const workLabel = <TreeItemLabel to={`/work/${work.id}`} title={work.title} />;
        return (
          <ExpandableTreeItem id={work.id} label={workLabel} key={`treeItemWork-${work.id}`}>
            {buildEpisodeTreeItems(expandedIds, work.episodes)}
          </ExpandableTreeItem>
        );
      })}
    </ExpandableTreeItem>
  );
};

export function TreeViewComponent() {
  const { expandedIds, setExpandedIds } = useTreeViewExpanding();
  const { data: workData } = useGetWorksAndEpisodesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      data:
        data?.works.map((work) => ({
          id: work.id,
          title: work.title,
          episodes: work.episodes,
        })) ?? [],
    }),
    refetchOnFocus: true,
  });

  const sortedWorkData = workData.sort((a, b) => a.title.localeCompare(b.title));
  const WorksTreeItem = buildWorksTreeItem(sortedWorkData, expandedIds);
  const expandedHandler = (event: React.SyntheticEvent, allExpandedIds: string[]) => {
    setExpandedIds(allExpandedIds);
  };

  return (
    <TreeView
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expandedIds}
      onNodeToggle={expandedHandler}
    >
      {WorksTreeItem}
    </TreeView>
  );
}
