import { rest } from "msw";
import {
  CutSummary,
  EpisodeSummary,
  ExportsRequest,
  ExportsResponse,
  ProcessSummary,
  WorkSummary,
  LoginRequest,
  SingleFileUploadRequest,
  SamlExchangeTokenRequest,
  UpdateWorkRequest,
  UpdateEpisodeRequest,
  UpdateCutRequest,
} from "../modules/api.types";
import { createDummyTokenAtNow } from "../test_env/dummyToken";
import { makeApiUrl } from "../test_env/makeApiUrl";

const thumbnailCommon = {
  ext: "jpg",
  height: 100.0,
  width: 141.42,
  variants: ["original", "large", "medium", "small"],
  updatedAt: "2022-01-01T12:34:56+09:00",
};

const makeWorkItem = (id: string, thumbnailNo: string, title: string, updatedAt: string, createdAt?: string) => {
  return {
    id: `11223344-5566-4788-99aa-bbcc${id}000000`,
    thumbnail:
      thumbnailNo.length > 0
        ? {
            path: makeApiUrl(`thumbnails/${thumbnailNo}/`),
            ...thumbnailCommon,
          }
        : undefined,
    title: title,
    updatedAt: updatedAt,
    // 作成日指定しない場合は更新日
    createdAt: createdAt ? createdAt : updatedAt,
  };
};

const works = {
  count: 13,
  cdnUrl: "",
  items: [
    makeWorkItem("01", "01", "作品名: Breakfast", "2022-01-01T12:34:56+09:00"),
    makeWorkItem("02", "02", "作品名: Burger", "2022-01-02T12:34:56+09:00"),
    makeWorkItem("03", "03", "作品名: Camera", "2022-01-03T12:34:56+09:00"),
    makeWorkItem("04", "04", "作品名: Coffee", "2022-01-04T12:34:56+09:00"),
    makeWorkItem("05", "05", "作品名: Hats", "2022-01-05T12:34:56+09:00"),
    makeWorkItem("06", "06", "作品名: Honey", "2022-01-06T12:34:56+09:00"),
    makeWorkItem("07", "07", "作品名: Basketball", "2022-01-07T12:34:56+09:00"),
    makeWorkItem("08", "08", "作品名: Fern", "2022-01-08T12:34:56+09:00"),
    makeWorkItem("09", "09", "作品名: Mushrooms", "2022-01-09T12:34:56+09:00"),
    makeWorkItem("10", "10", "作品名: Tomato basil", "2022-01-10T12:34:56+09:00"),
    makeWorkItem("11", "11", "作品名: Sea star", "2022-01-11T12:34:56+09:00"),
    makeWorkItem("12", "12", "作品名: Bike", "2022-01-12T12:34:56+09:00"),
    makeWorkItem("13", "01", "ソート確認用", "2022-01-13T12:34:56+09:00"),
    makeWorkItem("14", "02", "フォルダアイコン確認用", "2022-01-14T12:34:56+09:00"),
  ],
};

const makeEpisodeItem = (
  workId: string,
  id: string,
  thumbnailNo: string,
  no: string,
  subtitle: string,
  updatedAt: string,
  createdAt?: string,
  latestFileUpdatedAt: string | null = null,
) => {
  return {
    id: `11223344-5566-4788-99aa-bbcc${workId}${id}0000`,
    thumbnail:
      thumbnailNo.length > 0
        ? {
            path: makeApiUrl(`thumbnails/${thumbnailNo}/`),
            ...thumbnailCommon,
          }
        : undefined,
    no: no,
    subtitle: subtitle,
    updatedAt: updatedAt,
    createdAt: createdAt ? createdAt : updatedAt,
    latestFileUpdatedAt: latestFileUpdatedAt,
  };
};

const episodes = {
  "11223344-5566-4788-99aa-bbcc01000000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episodes: [
      makeEpisodeItem("01", "01", "02", "1", "「Burger」", "2022-02-01T12:34:56+09:00"),
      makeEpisodeItem("01", "02", "03", "2", "「Camera」", "2022-02-02T12:34:56+09:00"),
      makeEpisodeItem(
        "01",
        "03",
        "04",
        "3",
        "「Coffee」(カットが番号のみ)長いカット名が付いたらどうなるかをやってみよう！！これ以上長かったらどうなってしまうのだろうか？",
        "2022-02-03T12:34:56+09:00",
      ),
      makeEpisodeItem("01", "04", "05", "4", "「Hats」(カット0件)", "2022-02-04T12:34:56+09:00"),
      makeEpisodeItem("01", "05", "06", "5", "「Honey」", "2022-02-05T12:34:56+09:00"),
      makeEpisodeItem("01", "06", "07", "6", "「Basketball」", "2022-02-06T12:34:56+09:00"),
      makeEpisodeItem("01", "07", "08", "7", "「Fern」", "2022-02-07T12:34:56+09:00"),
      makeEpisodeItem("01", "08", "09", "8", "「Mushrooms」", "2022-02-08T12:34:56+09:00"),
      makeEpisodeItem("01", "09", "10", "9", "「Tomato basil」", "2022-02-09T12:34:56+09:00"),
      makeEpisodeItem("01", "10", "11", "10", "「Sea star」", "2022-02-10T12:34:56+09:00"),
      makeEpisodeItem("01", "11", "12", "11", "「Bike」", "2022-02-11T12:34:56+09:00"),
      makeEpisodeItem("01", "12", "01", "12", "「Breakfast」", "2022-02-12T12:34:56+09:00"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc02000000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc02000000") as WorkSummary) },
    episodes: [
      makeEpisodeItem("02", "01", "03", "1", "「Camera」", "2022-02-13T12:34:56+09:00"),
      makeEpisodeItem("02", "02", "04", "2", "「Coffee」", "2022-02-14T12:34:56+09:00"),
      makeEpisodeItem("02", "03", "05", "3", "「Hats」", "2022-02-15T12:34:56+09:00"),
      makeEpisodeItem("02", "04", "06", "4", "「Honey」", "2022-02-16T12:34:56+09:00"),
      makeEpisodeItem("02", "05", "07", "5", "「Basketball」", "2022-02-17T12:34:56+09:00"),
      makeEpisodeItem("02", "06", "08", "6", "「Fern」", "2022-02-18T12:34:56+09:00"),
      makeEpisodeItem("02", "07", "09", "7", "「Mushrooms」", "2022-02-19T12:34:56+09:00"),
      makeEpisodeItem("02", "08", "10", "8", "「Tomato basil」", "2022-02-20T12:34:56+09:00"),
      makeEpisodeItem("02", "09", "11", "9", "「Sea star」", "2022-02-21T12:34:56+09:00"),
      makeEpisodeItem("02", "10", "12", "10", "「Bike」", "2022-02-22T12:34:56+09:00"),
      makeEpisodeItem("02", "11", "01", "11", "「Breakfast」", "2022-02-23T12:34:56+09:00"),
      makeEpisodeItem("02", "12", "02", "12", "「Burger」", "2022-02-24T12:34:56+09:00"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc03000000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc03000000") as WorkSummary) },
    episodes: [
      makeEpisodeItem("03", "01", "04", "1", "「Coffee」", "2022-02-25T12:34:56+09:00"),
      makeEpisodeItem("03", "02", "05", "2", "「Hats」", "2022-02-26T12:34:56+09:00"),
      makeEpisodeItem("03", "03", "06", "3", "「Honey」", "2022-02-27T12:34:56+09:00"),
      makeEpisodeItem("03", "04", "07", "4", "「Basketball」", "2022-02-28T12:34:56+09:00"),
      makeEpisodeItem("03", "05", "08", "5", "「Fern」", "2022-03-01T12:34:56+09:00"),
      makeEpisodeItem("03", "06", "09", "6", "「Mushrooms」", "2022-03-02T12:34:56+09:00"),
      makeEpisodeItem("03", "07", "10", "7", "「Tomato basil」", "2022-03-03T12:34:56+09:00"),
      makeEpisodeItem("03", "08", "11", "8", "「Sea star」", "2022-03-04T12:34:56+09:00"),
      makeEpisodeItem("03", "09", "12", "9", "「Bike」", "2022-03-05T12:34:56+09:00"),
      makeEpisodeItem("03", "10", "01", "10", "「Breakfast」", "2022-03-06T12:34:56+09:00"),
      makeEpisodeItem("03", "11", "02", "11", "「Burger」", "2022-03-07T12:34:56+09:00"),
      makeEpisodeItem("03", "12", "03", "12", "「Camera」", "2022-03-08T12:34:56+09:00"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc13000000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc13000000") as WorkSummary) },
    episodes: [
      makeEpisodeItem("13", "01", "04", "1", "Episode_名前01_最終更新12", "2022-03-03T12:35:00+09:00"),
      makeEpisodeItem("13", "02", "05", "2", "Episode_名前02_最終更新10", "2022-03-03T12:34:50+09:00"),
      makeEpisodeItem("13", "03", "06", "3", "Episode_名前03_最終更新08", "2022-03-03T12:34:40+09:00"),
      makeEpisodeItem("13", "04", "07", "4", "Episode_名前04_最終更新06", "2022-03-03T12:34:30+09:00"),
      makeEpisodeItem("13", "05", "08", "5", "Episode_名前05_最終更新04", "2022-03-03T12:34:20+09:00"),
      makeEpisodeItem("13", "06", "09", "6", "Episode_名前06_最終更新02", "2022-03-03T12:34:10+09:00"),
      makeEpisodeItem("13", "07", "10", "", "Episode_名前A_最終更新01", "2022-03-03T12:34:05+09:00"),
      makeEpisodeItem("13", "08", "11", "", "Episode_名前B_最終更新03", "2022-03-03T12:34:15+09:00"),
      makeEpisodeItem("13", "09", "12", "", "Episode_名前C_最終更新05", "2022-03-03T12:34:25+09:00"),
      makeEpisodeItem("13", "10", "01", "", "Episode_名前D_最終更新07", "2022-03-03T12:34:35+09:00"),
      makeEpisodeItem("13", "11", "02", "", "Episode_名前E_最終更新09", "2022-03-03T12:34:45+09:00"),
      makeEpisodeItem("13", "12", "03", "", "Episode_名前F_最終更新11", "2022-03-03T12:34:55+09:00"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc14000000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc14000000") as WorkSummary) },
    // prettier-ignore
    episodes: [
      makeEpisodeItem("14", "01",   "",  "1", "名前01_サムネイル無し_ファイル無", "2022-03-12T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "02", "05",  "2", "名前02_サムネイルあり_ファイル無", "2022-03-10T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "03",   "",  "3", "名前03_サムネイル無し_ファイル03", "2022-03-08T00:00:00+09:00", "", "2022-03-08T00:01:00+09:00"),
      makeEpisodeItem("14", "04", "07",  "4", "名前04_サムネイルあり_ファイル04", "2022-03-06T00:00:00+09:00", "", "2022-03-06T00:01:00+09:00"),
      makeEpisodeItem("14", "05",   "",  "5", "名前05_サムネイル無し_ファイル無", "2022-03-04T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "06", "09",  "6", "名前06_サムネイルあり_ファイル無", "2022-03-02T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "07",   "",  "7", "名前07_サムネイル無し_ファイル07", "2022-03-01T00:00:00+09:00", "", "2022-03-01T00:01:00+09:00"),
      makeEpisodeItem("14", "08", "11",  "8", "名前08_サムネイルあり_ファイル08", "2022-03-03T00:00:00+09:00", "", "2022-03-03T00:01:00+09:00"),
      makeEpisodeItem("14", "09",   "",  "9", "名前09_サムネイル無し_ファイル無", "2022-03-05T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "10", "01", "10", "名前10_サムネイルあり_ファイル無", "2022-03-07T00:00:00+09:00", "", null),
      makeEpisodeItem("14", "11",   "", "11", "名前11_サムネイル無し_ファイル11", "2022-03-09T00:00:00+09:00", "", "2022-03-09T00:01:00+09:00"),
      makeEpisodeItem("14", "12", "03", "12", "名前12_サムネイルあり_ファイル12", "2022-03-11T00:00:00+09:00", "", "2022-03-11T00:01:00+09:00"),
    ],
  },
};

const makeCutItem = (
  workId: string,
  episodeId: string,
  id: string,
  thumbnailNo: string,
  title: string,
  updatedAt: string,
  createdAt?: string,
  status?: string,
  latestFiles?: Array<{ processId: string; type: string; latestFileUpdatedAt: string | null }>,
) => {
  return {
    id: `11223344-5566-4788-99aa-bbcc${workId}${episodeId}${id}00`,
    thumbnail:
      thumbnailNo.length > 0
        ? {
            path: makeApiUrl(`thumbnails/${thumbnailNo}/`),
            ...thumbnailCommon,
          }
        : undefined,
    title: title,
    updatedAt: updatedAt,
    createdAt: createdAt ? createdAt : updatedAt,
    status: status ? status : "",
    latestFiles: latestFiles,
  };
};

const cuts = {
  "11223344-5566-4788-99aa-bbcc01010000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cuts: [
      makeCutItem("01", "01", "01", "03", "カット1 「Camera」", "2022-03-01T12:34:56+09:00", "", "ステータス1"),
      makeCutItem("01", "01", "02", "04", "カット2 「Coffee」", "2022-03-02T12:34:56+09:00", "", "ステータス2"),
      makeCutItem("01", "01", "03", "05", "カット3 「Hats」", "2022-03-03T12:34:56+09:00", "", "ステータス3"),
      makeCutItem("01", "01", "04", "06", "カット4 「Honey」", "2022-03-04T12:34:56+09:00", "", "ステータス4"),
      makeCutItem("01", "01", "05", "07", "カット5 「Basketball」", "2022-03-05T12:34:56+09:00", "", "ステータス5"),
      makeCutItem("01", "01", "06", "08", "カット6 「Fern」", "2022-03-06T12:34:56+09:00", "", "ステータス6"),
      makeCutItem("01", "01", "07", "09", "カット7 「Mushrooms」", "2022-03-07T12:34:56+09:00", "", "ステータス7"),
      makeCutItem("01", "01", "08", "10", "カット8 「Tomato basil」", "2022-03-08T12:34:56+09:00", "", "ステータス8"),
      makeCutItem("01", "01", "09", "11", "カット9 「Sea star」", "2022-03-09T12:34:56+09:00", "", "ステータス9"),
      makeCutItem("01", "01", "10", "12", "カット10 「Bike」", "2022-03-10T12:34:56+09:00", "", "ステータス10"),
      makeCutItem("01", "01", "11", "01", "カット11 「Breakfast」", "2022-03-11T12:34:56+09:00", "", "ステータス11"),
      makeCutItem("01", "01", "12", "02", "カット12 「Burger」", "2022-03-12T12:34:56+09:00", "", "ステータス12"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01020000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01020000",
      ) as EpisodeSummary),
    },
    cuts: [
      makeCutItem("01", "02", "01", "04", "カット1 「Coffee」", "2022-03-13T12:34:56+09:00", "", "ステータス1"),
      makeCutItem("01", "02", "02", "05", "カット2 「Hats」", "2022-03-14T12:34:56+09:00", "", "ステータス2"),
      makeCutItem("01", "02", "03", "06", "カット3 「Honey」", "2022-03-15T12:34:56+09:00", "", "ステータス3"),
      makeCutItem("01", "02", "04", "07", "カット4 「Basketball」", "2022-03-16T12:34:56+09:00", "", "ステータス4"),
      makeCutItem("01", "02", "05", "08", "カット5 「Fern」", "2022-03-17T12:34:56+09:00", "", "ステータス5"),
      makeCutItem("01", "02", "06", "09", "カット6 「Mushrooms」", "2022-03-18T12:34:56+09:00", "", "ステータス6"),
      makeCutItem("01", "02", "07", "10", "カット7 「Tomato basil」", "2022-03-19T12:34:56+09:00", "", "ステータス7"),
      makeCutItem("01", "02", "08", "11", "カット8 「Sea star」", "2022-03-20T12:34:56+09:00", "", "ステータス8"),
      makeCutItem("01", "02", "09", "12", "カット9 「Bike」", "2022-03-21T12:34:56+09:00", "", "ステータス9"),
      makeCutItem("01", "02", "10", "01", "カット10 「Breakfast」", "2022-03-22T12:34:56+09:00", "", "ステータス10"),
      makeCutItem("01", "02", "11", "02", "カット11 「Burger」", "2022-03-23T12:34:56+09:00", "", "ステータス11"),
      makeCutItem("01", "02", "12", "03", "カット12 「Camera」", "2022-03-24T12:34:56+09:00", "", "ステータス12"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01030000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01030000",
      ) as EpisodeSummary),
    },
    cuts: [
      makeCutItem("01", "03", "01", "05", "1", "2022-03-25T12:34:56+09:00", "", "ステータス1"),
      makeCutItem("01", "03", "02", "06", "2", "2022-03-26T12:34:56+09:00", "", "ステータス2"),
      makeCutItem("01", "03", "03", "07", "3", "2022-03-27T12:34:56+09:00", "", "ステータス3"),
      makeCutItem("01", "03", "04", "08", "4", "2022-03-28T12:34:56+09:00", "", "ステータス4"),
      makeCutItem("01", "03", "05", "09", "5", "2022-03-29T12:34:56+09:00", "", "ステータス5"),
      makeCutItem("01", "03", "06", "10", "6", "2022-03-30T12:34:56+09:00", "", "ステータス6"),
      makeCutItem("01", "03", "07", "11", "7", "2022-03-31T12:34:56+09:00", "", "ステータス7"),
      makeCutItem("01", "03", "08", "12", "8", "2022-04-01T12:34:56+09:00", "", "ステータス8"),
      makeCutItem("01", "03", "09", "01", "9", "2022-04-02T12:34:56+09:00", "", "ステータス9"),
      makeCutItem("01", "03", "10", "02", "10", "2022-04-03T12:34:56+09:00", "", "ステータス10"),
      makeCutItem("01", "03", "11", "03", "11", "2022-04-04T12:34:56+09:00", "", "ステータス11"),
      makeCutItem("01", "03", "12", "04", "12", "2022-04-05T12:34:56+09:00", "", "ステータス12"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01040000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01040000",
      ) as EpisodeSummary),
    },
    cuts: [],
  },
  "11223344-5566-4788-99aa-bbcc13010000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc13000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc13000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010000",
      ) as EpisodeSummary),
    },
    cuts: [
      makeCutItem("13", "01", "01", "05", "Cut_名前01_最終更新12", "2022-03-03T12:35:00+09:00", "", "ステータス3", []),
      makeCutItem("13", "01", "02", "06", "Cut_名前02_最終更新10", "2022-03-03T12:34:50+09:00", "", "ステータス4", []),
      makeCutItem("13", "01", "03", "07", "Cut_名前03_最終更新08", "2022-03-03T12:34:40+09:00", "", "ステータス5", []),
      makeCutItem("13", "01", "04", "08", "Cut_名前04_最終更新06", "2022-03-03T12:34:30+09:00", "", "ステータス6", []),
      makeCutItem("13", "01", "05", "09", "Cut_名前05_最終更新04", "2022-03-03T12:34:20+09:00", "", "ステータス7", []),
      makeCutItem("13", "01", "06", "10", "Cut_名前06_最終更新02", "2022-03-03T12:34:10+09:00", "", "ステータス8", []),
      makeCutItem("13", "01", "07", "11", "Cut_名前07_最終更新01", "2022-03-03T12:34:05+09:00", "", "ステータス9", []),
      makeCutItem("13", "01", "08", "12", "Cut_名前08_最終更新03", "2022-03-03T12:34:15+09:00", "", "ステータス10", []),
      makeCutItem("13", "01", "09", "01", "Cut_名前09_最終更新05", "2022-03-03T12:34:25+09:00", "", "ステータス11", []),
      makeCutItem("13", "01", "10", "02", "Cut_名前10_最終更新07", "2022-03-03T12:34:35+09:00", "", "ステータス12", []),
      makeCutItem("13", "01", "11", "03", "Cut_名前11_最終更新09", "2022-03-03T12:34:45+09:00", "", "ステータス1", []),
      makeCutItem("13", "01", "12", "04", "Cut_名前12_最終更新11", "2022-03-03T12:34:55+09:00", "", "ステータス2", []),
    ],
  },
  "11223344-5566-4788-99aa-bbcc14010000": {
    count: 12,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc14000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc14000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc14010000",
      ) as EpisodeSummary),
    },
    // prettier-ignore
    cuts: [
      makeCutItem("14", "01", "01", "05", "001_サムネイルあり_なし", "2022-03-01T00:00:00+09:00", "", "ステータス3"),
      makeCutItem("14", "01", "02", "06", "002_サムネイルあり_レイアウト", "2022-03-03T00:00:00+09:00", "", "ステータス4", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "03", "", "003_サムネイル無し_原画", "2022-03-05T00:00:00+09:00", "", "ステータス5", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: null},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "04",   "", "004_サムネイル無し_動画",      "2022-03-07T00:00:00+09:00", "", "ステータス6", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: null},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "05", "09", "005_サムネイルあり_仕上げ",          "2022-03-09T00:00:00+09:00", "", "ステータス7", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: null},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},  
      ]),
      makeCutItem("14", "01", "06", "10", "006_サムネイルあり_原画",      "2022-03-11T00:00:00+09:00", "", "ステータス8", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画",       latestFileUpdatedAt: "2022-03-01T00:00:01+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "07",   "", "007_サムネイル無し_動画",            "2022-03-02T00:00:00+09:00", "", "ステータス9", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画",       latestFileUpdatedAt:"2022-03-01T00:00:01+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画",       latestFileUpdatedAt: "2022-03-01T00:00:02+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "08",   "", "008_サムネイル無し_仕上げ",      "2022-03-04T00:00:00+09:00", "", "ステータス10", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画",       latestFileUpdatedAt:"2022-03-01T00:00:01+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画",       latestFileUpdatedAt:"2022-03-01T00:00:02+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ",     latestFileUpdatedAt: "2022-03-01T00:00:03+09:00"},  
      ]),
      makeCutItem("14", "01", "09", "01", "009_サムネイルあり_レイアウト_動画", "2022-03-06T00:00:00+09:00", "", "ステータス11", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:03+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画",       latestFileUpdatedAt:"2022-03-01T00:00:01+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "10", "02", "010_サムネイルあり_レイアウト",      "2022-03-08T00:00:00+09:00", "", "ステータス12", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:03+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ",     latestFileUpdatedAt: "2022-03-01T00:00:01+09:00"},  
      ]),
      makeCutItem("14", "01", "11",   "", "011_サムネイル無し_動画",     "2022-03-10T00:00:00+09:00", "", "ステータス1", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: null},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt:"2022-03-01T00:00:01+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "12",   "", "012_サムネイル無し_未納",      "2022-03-12T00:00:00+09:00", "", "ステータス2", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: null},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
      makeCutItem("14", "01", "13",   "", "013_サムネイル無し_未納",      "2022-03-12T00:00:00+09:00", "", "ステータス2", []),
      makeCutItem("14", "01", "14",   "", "014_サムネイル無し_なし",      "2022-03-12T00:00:00+09:00", "", "ステータス2"),
      makeCutItem("14", "01", "15",   "", "015_サムネイル無し_レイアウト",     "2022-03-10T00:00:00+09:00", "", "ステータス1", [
        {processId: "11223344-5566-4788-99aa-bbcc14010100", type: "レイアウト", latestFileUpdatedAt: "2022-03-01T00:00:01+09:00"},
        {processId: "11223344-5566-4788-99aa-bbcc14010200", type: "原画", latestFileUpdatedAt: "2022-03-01T00:00:00+09:00"},  
        {processId: "11223344-5566-4788-99aa-bbcc14010300", type: "動画", latestFileUpdatedAt: null},  
        {processId: "11223344-5566-4788-99aa-bbcc14010400", type: "仕上げ", latestFileUpdatedAt: null},  
      ]),
    ],
  },
};

export const makeProcessesItem = (
  workId: string,
  episodeId: string,
  cutId: string,
  id: string,
  thumbnailNo: string,
  type: string,
  updatedAt: string,
  createdAt?: string,
  status?: string,
  latestFileUpdatedAt: string | null = null,
) => {
  return {
    id: `11223344-5566-4788-99aa-bbcc${workId}${episodeId}${cutId}${id}`,
    thumbnail:
      thumbnailNo.length > 0
        ? {
            path: makeApiUrl(`thumbnails/${thumbnailNo}/`),
            ...thumbnailCommon,
          }
        : undefined,
    type: type,
    updatedAt: updatedAt,
    createdAt: createdAt ? createdAt : updatedAt,
    status: status ? status : "",
    latestFileUpdatedAt: latestFileUpdatedAt,
  };
};

const processes = {
  "11223344-5566-4788-99aa-bbcc01010100": {
    count: 48,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010100",
      ) as CutSummary),
    },
    processes: [
      makeProcessesItem("01", "01", "01", "01", "03", "01 レイアウト", "2022-03-01T12:34:56+09:00", "", "ステータス1"),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "02",
        "04",
        "02 原画 (未アップロード)",
        "2022-03-02T12:34:56+09:00",
        "",
        "ステータス2",
      ),
      makeProcessesItem("01", "01", "01", "03", "05", "03 トレース", "2022-03-03T12:34:56+09:00", "", "ステータス3"),
      makeProcessesItem("01", "01", "01", "04", "06", "04 ペイント", "2022-03-04T12:34:56+09:00", "", "ステータス4"),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "05",
        "07",
        "工程5 「Basketball」",
        "2022-03-05T12:34:56+09:00",
        "",
        "ステータス5",
      ),
      makeProcessesItem("01", "01", "01", "06", "08", "工程6 「Fern」", "2022-03-06T12:34:56+09:00", "", "ステータス6"),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "07",
        "09",
        "工程7 「Mushrooms」",
        "2022-03-07T12:34:56+09:00",
        "",
        "ステータス7",
      ),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "08",
        "10",
        "工程8 「Tomato basil」",
        "2022-03-08T12:34:56+09:00",
        "",
        "ステータス8",
      ),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "09",
        "11",
        "工程9 「Sea star」",
        "2022-03-09T12:34:56+09:00",
        "",
        "ステータス9",
      ),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "10",
        "12",
        "工程10 「Bike」",
        "2022-03-10T12:34:56+09:00",
        "",
        "ステータス10",
      ),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "11",
        "01",
        "工程11 「Breakfast」",
        "2022-03-11T12:34:56+09:00",
        "",
        "ステータス11",
      ),
      makeProcessesItem(
        "01",
        "01",
        "01",
        "12",
        "02",
        "工程12 「Burger」",
        "2022-03-12T12:34:56+09:00",
        "",
        "ステータス12",
      ),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01010200": {
    count: 48,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010200",
      ) as CutSummary),
    },
    processes: [
      makeProcessesItem(
        "01",
        "01",
        "02",
        "01",
        "03",
        "工程1 「Camera」",
        "2022-03-13T12:34:56+09:00",
        "",
        "ステータス1",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "02",
        "04",
        "工程2 「Coffee」",
        "2022-03-14T12:34:56+09:00",
        "",
        "ステータス2",
      ),
      makeProcessesItem("01", "01", "02", "03", "05", "工程3 「Hats」", "2022-03-15T12:34:56+09:00", "", "ステータス3"),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "04",
        "06",
        "工程4 「Honey」",
        "2022-03-16T12:34:56+09:00",
        "",
        "ステータス4",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "05",
        "07",
        "工程5 「Basketball」",
        "2022-03-17T12:34:56+09:00",
        "",
        "ステータス5",
      ),
      makeProcessesItem("01", "01", "02", "06", "08", "工程6 「Fern」", "2022-03-18T12:34:56+09:00", "", "ステータス6"),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "07",
        "09",
        "工程7 「Mushrooms」",
        "2022-03-19T12:34:56+09:00",
        "",
        "ステータス7",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "08",
        "10",
        "工程8 「Tomato basil」",
        "2022-03-20T12:34:56+09:00",
        "",
        "ステータス8",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "09",
        "11",
        "工程9 「Sea star」",
        "2022-03-21T12:34:56+09:00",
        "",
        "ステータス9",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "10",
        "12",
        "工程10 「Bike」",
        "2022-03-22T12:34:56+09:00",
        "",
        "ステータス10",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "11",
        "01",
        "工程11 「Breakfast」",
        "2022-03-23T12:34:56+09:00",
        "",
        "ステータス11",
      ),
      makeProcessesItem(
        "01",
        "01",
        "02",
        "12",
        "02",
        "工程12 「Burger」",
        "2022-03-24T12:34:56+09:00",
        "",
        "ステータス12",
      ),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01010300": {
    count: 48,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010300",
      ) as CutSummary),
    },
    processes: [
      makeProcessesItem(
        "01",
        "01",
        "03",
        "01",
        "03",
        "工程1 「Camera」",
        "2022-03-25T12:34:56+09:00",
        "",
        "ステータス1",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "02",
        "04",
        "工程2 「Coffee」",
        "2022-03-26T12:34:56+09:00",
        "",
        "ステータス2",
      ),
      makeProcessesItem("01", "01", "03", "03", "05", "工程3 「Hats」", "2022-03-27T12:34:56+09:00", "", "ステータス3"),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "04",
        "06",
        "工程4 「Honey」",
        "2022-03-28T12:34:56+09:00",
        "",
        "ステータス4",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "05",
        "07",
        "工程5 「Basketball」",
        "2022-03-29T12:34:56+09:00",
        "",
        "ステータス5",
      ),
      makeProcessesItem("01", "01", "03", "06", "08", "工程6 「Fern」", "2022-03-30T12:34:56+09:00", "", "ステータス6"),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "07",
        "09",
        "工程7 「Mushrooms」",
        "2022-03-31T12:34:56+09:00",
        "",
        "ステータス7",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "08",
        "10",
        "工程8 「Tomato basil」",
        "2022-04-01T12:34:56+09:00",
        "",
        "ステータス8",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "09",
        "11",
        "工程9 「Sea star」",
        "2022-04-02T12:34:56+09:00",
        "",
        "ステータス9",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "10",
        "12",
        "工程10 「Bike」",
        "2022-04-03T12:34:56+09:00",
        "",
        "ステータス10",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "11",
        "01",
        "工程11 「Breakfast」",
        "2022-04-04T12:34:56+09:00",
        "",
        "ステータス11",
      ),
      makeProcessesItem(
        "01",
        "01",
        "03",
        "12",
        "02",
        "工程12 「Burger」",
        "2022-04-05T12:34:56+09:00",
        "",
        "ステータス12",
      ),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01010400": {
    count: 48,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010400",
      ) as CutSummary),
    },
    processes: [
      makeProcessesItem(
        "01",
        "01",
        "04",
        "01",
        "03",
        "工程1 「Camera」",
        "2022-04-06T12:34:56+09:00",
        "",
        "ステータス1",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "02",
        "04",
        "工程2 「Coffee」",
        "2022-04-07T12:34:56+09:00",
        "",
        "ステータス2",
      ),
      makeProcessesItem("01", "01", "04", "03", "05", "工程3 「Hats」", "2022-04-08T12:34:56+09:00", "", "ステータス3"),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "04",
        "06",
        "工程4 「Honey」",
        "2022-04-09T12:34:56+09:00",
        "",
        "ステータス4",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "05",
        "07",
        "工程5 「Basketball」",
        "2022-04-10T12:34:56+09:00",
        "",
        "ステータス5",
      ),
      makeProcessesItem("01", "01", "04", "06", "08", "工程6 「Fern」", "2022-04-11T12:34:56+09:00", "", "ステータス6"),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "07",
        "09",
        "工程7 「Mushrooms」",
        "2022-04-12T12:34:56+09:00",
        "",
        "ステータス7",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "08",
        "10",
        "工程8 「Tomato basil」",
        "2022-04-13T12:34:56+09:00",
        "",
        "ステータス8",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "09",
        "11",
        "工程9 「Sea star」",
        "2022-04-14T12:34:56+09:00",
        "",
        "ステータス9",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "10",
        "12",
        "工程10 「Bike」",
        "2022-04-15T12:34:56+09:00",
        "",
        "ステータス10",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "11",
        "01",
        "工程11 「Breakfast」",
        "2022-04-16T12:34:56+09:00",
        "",
        "ステータス11",
      ),
      makeProcessesItem(
        "01",
        "01",
        "04",
        "12",
        "02",
        "工程12 「Burger」",
        "2022-04-17T12:34:56+09:00",
        "",
        "ステータス12",
      ),
    ],
  },
  "11223344-5566-4788-99aa-bbcc13010100": {
    count: 4,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc13000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc13000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc13010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010100",
      ) as CutSummary),
    },
    processes: [
      makeProcessesItem(
        "13",
        "01",
        "01",
        "01",
        "03",
        "レイアウト_名前01_最終更新04",
        "2022-03-01T12:35:00+09:00",
        "",
        "ステータス3",
      ),
      makeProcessesItem(
        "13",
        "01",
        "01",
        "02",
        "04",
        "原画_名前02_最終更新01",
        "2022-03-01T12:34:00+09:00",
        "",
        "ステータス4",
      ),
      makeProcessesItem(
        "13",
        "01",
        "01",
        "03",
        "05",
        "動画_名前03_最終更新02",
        "2022-03-01T12:34:20+09:00",
        "",
        "ステータス1",
      ),
      makeProcessesItem(
        "13",
        "01",
        "01",
        "04",
        "06",
        "仕上げ_名前04_最終更新03",
        "2022-03-01T12:34:40+09:00",
        "",
        "ステータス2",
      ),
    ],
  },
  "11223344-5566-4788-99aa-bbcc14010100": {
    count: 4,
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc14000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc13000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc14010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc14010100",
      ) as CutSummary),
    },
    // prettier-ignore
    processes: [
      makeProcessesItem("14", "01", "01", "01",   "", "レイアウト_サムネイル無し_ファイル01", "2022-03-04T00:00:00+09:00", "", "ステータス3", "2022-03-04T00:01:00+09:00"),
      makeProcessesItem("14", "01", "01", "02", "04",       "原画_サムネイルあり_ファイル02", "2022-03-01T00:00:00+09:00", "", "ステータス4", "2022-03-01T00:01:00+09:00"),
      makeProcessesItem("14", "01", "01", "03",   "",       "動画_サムネイル無し_ファイル無", "2022-03-02T00:00:00+09:00", "", "ステータス1",  null),
      makeProcessesItem("14", "01", "01", "04", "06",     "仕上げ_サムネイルあり_ファイル無", "2022-03-03T00:00:00+09:00", "", "ステータス2",  null),
    ],
  },
};

const makeFilesItem = (
  id: string,
  path: string,
  name: string,
  dataSize: number,
  lastModified: string,
  thumbnailNo?: string,
  updatedAt?: string,
) => {
  let thumbnail = undefined;
  if (thumbnailNo) {
    thumbnail = {
      path: makeApiUrl(`thumbnails/${thumbnailNo}/`),
      ...thumbnailCommon,
      updatedAt: updatedAt ? updatedAt : undefined,
    };
  }
  return {
    id: id,
    path: path,
    name: name,
    dataSize: dataSize,
    lastModified: lastModified,
    thumbnail: thumbnail ? thumbnail : undefined,
  };
};

const files = {
  "11223344-5566-4788-99aa-bbcc01010101": {
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010100",
      ) as CutSummary),
    },
    process: {
      ...(processes["11223344-5566-4788-99aa-bbcc01010100"].processes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010101",
      ) as ProcessSummary),
    },
    files: [
      makeFilesItem("0", "", "タイムシート01010101.xdts", 1000000, "2021-06-29T12:16:24+09:00"),
      makeFilesItem(
        "1",
        "A",
        "A0101010101.tga",
        1000001,
        "2021-07-01T12:16:24+09:00",
        "01",
        "2021-07-01T12:16:24+09:00",
      ),
      makeFilesItem("2", "A", "A0101010102.tga", 1000002, "2021-07-02T12:16:24+09:00", "02"),
      makeFilesItem("3", "A", "A0101010103.tga", 1000003, "2021-07-03T12:16:24+09:00", "03"),
      makeFilesItem("4", "A", "A0101010104.tga", 1000004, "2021-07-04T12:16:24+09:00", "04"),
      makeFilesItem("5", "A", "A0101010105.tga", 1000005, "2021-07-05T12:16:24+09:00", "05"),
      makeFilesItem("6", "A", "A0101010106.tga", 1000006, "2021-07-06T12:16:24+09:00", "06"),
      makeFilesItem("7", "A", "A0101010107.tga", 1000007, "2021-07-07T12:16:24+09:00", "07"),
      makeFilesItem("8", "A", "A0101010108.tga", 1000008, "2021-07-08T12:16:24+09:00", "08"),
      makeFilesItem("9", "A", "A0101010109.tga", 1000009, "2021-07-09T12:16:24+09:00", "09"),
      makeFilesItem("10", "A", "A0101010110.tga", 1000010, "2021-07-10T12:16:24+09:00", "10"),
      makeFilesItem("11", "A", "A0101010111.tga", 1000011, "2021-07-11T12:16:24+09:00", "11"),
      makeFilesItem("12", "A", "A0101010112.tga", 1000012, "2021-07-12T12:16:24+09:00", "12"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc01010102": {
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010100",
      ) as CutSummary),
    },
    process: {
      ...(processes["11223344-5566-4788-99aa-bbcc01010100"].processes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010102",
      ) as ProcessSummary),
    },
    files: [
      // "タイムシート01010102.xdts",
      // "A0101010201.tga",
      // "A0101010202.tga",
      // "A0101010203.tga",
      // "A0101010204.tga",
      // "A0101010205.tga",
      // "A0101010206.tga",
      // "A0101010207.tga",
      // "A0101010208.tga",
      // "A0101010209.tga",
      // "A0101010210.tga",
      // "A0101010211.tga",
      // "A0101010212.tga",
    ],
  },
  "11223344-5566-4788-99aa-bbcc01010103": {
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc01000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc01000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc01010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010100",
      ) as CutSummary),
    },
    process: {
      ...(processes["11223344-5566-4788-99aa-bbcc01010100"].processes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc01010103",
      ) as ProcessSummary),
    },
    files: [
      makeFilesItem("0", "", "タイムシート01010103.xdts", 1000300, "2021-08-29T12:16:24+09:00"),
      makeFilesItem("1", "A", "A0101010301.tga", 1000301, "2021-09-01T12:16:24+09:00"),
      makeFilesItem("2", "A", "A0101010302.tga", 1000302, "2021-09-02T12:16:24+09:00"),
      makeFilesItem("3", "A", "A0101010303.tga", 1000303, "2021-09-03T12:16:24+09:00"),
      makeFilesItem("4", "A", "A0101010304.tga", 1000304, "2021-09-04T12:16:24+09:00"),
      makeFilesItem("5", "A", "A0101010305.tga", 1000305, "2021-09-05T12:16:24+09:00"),
      makeFilesItem("6", "A", "A0101010306.tga", 1000306, "2021-09-06T12:16:24+09:00"),
      makeFilesItem("7", "A", "A0101010307.tga", 1000307, "2021-09-07T12:16:24+09:00"),
      makeFilesItem("8", "A", "A0101010308.tga", 1000308, "2021-09-08T12:16:24+09:00"),
      makeFilesItem("9", "A", "A0101010309.tga", 1000309, "2021-09-09T12:16:24+09:00"),
      makeFilesItem("10", "A", "A0101010310.tga", 1000310, "2021-09-10T12:16:24+09:00"),
      makeFilesItem("11", "A", "A0101010311.tga", 1000311, "2021-09-11T12:16:24+09:00"),
      makeFilesItem("12", "A", "A0101010312.tga", 1000312, "2021-09-12T12:16:24+09:00"),
    ],
  },
  "11223344-5566-4788-99aa-bbcc13010101": {
    cdnUrl: "",
    work: { ...(works.items.find((v) => v.id === "11223344-5566-4788-99aa-bbcc13000000") as WorkSummary) },
    episode: {
      ...(episodes["11223344-5566-4788-99aa-bbcc13000000"].episodes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010000",
      ) as EpisodeSummary),
    },
    cut: {
      ...(cuts["11223344-5566-4788-99aa-bbcc13010000"].cuts.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010100",
      ) as CutSummary),
    },
    process: {
      ...(processes["11223344-5566-4788-99aa-bbcc13010100"].processes.find(
        (v) => v.id === "11223344-5566-4788-99aa-bbcc13010101",
      ) as ProcessSummary),
    },
    files: [
      makeFilesItem("0", "", "タイムシート13010113_updatedAt02.xdts", 1000000, "2021-07-01T12:16:05+09:00"),
      makeFilesItem(
        "1",
        "A",
        "A1301010101_updatedAt03.tga",
        1000001,
        "2021-07-01T12:16:10+09:00",
        "01",
        "2021-07-01T12:16:24+09:00",
      ),
      makeFilesItem("2", "B", "B1301010102_updatedAt04.tga", 1000002, "2021-07-01T12:16:15+09:00", "02"),
      makeFilesItem("3", "C", "C1301010103_updatedAt05.tga", 1000003, "2021-07-01T12:16:20+09:00", "03"),
      makeFilesItem("4", "D", "D1301010104_updatedAt06.tga", 1000004, "2021-07-01T12:16:25+09:00", "04"),
      makeFilesItem("5", "E", "E1301010105_updatedAt07.tga", 1000005, "2021-07-01T12:16:30+09:00", "05"),
      makeFilesItem("6", "F", "F1301010106_updatedAt08.tga", 1000006, "2021-07-01T12:16:35+09:00", "06"),
      makeFilesItem("7", "G", "G1301010107_updatedAt09.tga", 1000007, "2021-07-01T12:16:40+09:00", "07"),
      makeFilesItem("8", "H", "H1301010108_updatedAt10.tga", 1000008, "2021-07-01T12:16:45+09:00", "08"),
      makeFilesItem("9", "I", "I1301010109_updatedAt11.tga", 1000009, "2021-07-01T12:16:50+09:00", "09"),
      makeFilesItem("10", "J", "J1301010110_updatedAt12.tga", 1000010, "2021-07-01T12:16:55+09:00", "10"),
      makeFilesItem("11", "K", "K1301010111_updatedAt13.tga", 1000011, "2021-07-01T12:17:00+09:00", "11"),
      makeFilesItem("12", "L", "L1301010112_updatedAt01.tga", 1000012, "2021-07-01T12:16:00+09:00", "12"),
    ],
  },
};

export const handlers = [
  // login API
  rest.post(makeApiUrl("/api/user/login"), (req, res, ctx) => {
    const { email } = JSON.parse(req.body as string) as LoginRequest;
    if (email === "404") {
      return res(ctx.status(404), ctx.json("User Not Found."));
    }
    if (email === "403") {
      return res(ctx.status(403), ctx.json("Not Authorized."));
    }
    // need challenge
    if (email === "new") {
      return res(
        ctx.status(200),
        ctx.json({
          ChallengeName: "NEW_PASSWORD_REQUIRED",
          Session: "new-password-session",
          ChallengeParameters: {
            USER_ID_FOR_SRP: "new-password-user-id-for-SRP",
          },
        }),
      );
    }
    const { idToken, expIn } = createDummyTokenAtNow("IdToken", "login");
    return res(
      ctx.status(200),
      ctx.json({
        AuthenticationResult: {
          AccessToken: "AccessToken-login",
          ExpiresIn: expIn,
          TokenType: "TokenType-login",
          RefreshToken: "RefreshToken-login",
          IdToken: idToken,
        },
      }),
    );
  }),
  // Challenge New Password API
  rest.put(makeApiUrl("/api/user/challenge/new_password"), (req, res, ctx) => {
    const { idToken, expIn } = createDummyTokenAtNow("IdToken", "challenge-new-password");
    return res(
      ctx.status(200),
      ctx.json({
        AuthenticationResult: {
          AccessToken: "AccessToken-challenge-new-password",
          ExpiresIn: expIn,
          TokenType: "TokenType-challenge-new-password",
          RefreshToken: "RefreshToken-challenge-new-password",
          IdToken: idToken,
        },
      }),
    );
  }),
  // Refresh Token API （この関数の実装を変更する場合は api.test.tsxも変更すること）
  rest.post(makeApiUrl("/api/user/refresh_token"), (req, res, ctx) => {
    const refreshToken = req.body as string;
    console.log(`msw handled POST /api/user/refresh_token ${refreshToken}`); // debug
    const token = JSON.parse(refreshToken) as { token: string | null };
    if (!token?.token) {
      return res(ctx.status(400));
    }
    const { idToken, expIn } = createDummyTokenAtNow("IdToken", "refresh-token");
    return res(
      ctx.status(200),
      ctx.json({
        AuthenticationResult: {
          AccessToken: "AccessToken-refresh-token",
          ExpiresIn: expIn,
          TokenType: "TokenType-refresh-token",
          // RefreshToken: "RefreshToken-refresh-token",
          IdToken: idToken,
        },
      }),
    );
  }),
  // SAML 認可エンドポイント URL 取得
  rest.get(makeApiUrl("/api/user/saml/authorize_endpoint"), (req, res, ctx) => {
    const email = req.url.searchParams.get("email");
    if (email?.endsWith("@cloverworks.co.jp") || email?.endsWith("@a-1pictures.jp")) {
      const endpoint = encodeURI(
        makeApiUrl("/dummy/saml/authorize") + "?redirect_uri=http%3A%2F%2Flocalhost:3000%2Fsaml%2Fredirect",
      );
      // console.log(`dummy SAML authorize endpoint = ${endpoint}`); // debug
      return res(ctx.json({ endpoint }));
    }
    return res(ctx.status(200));
  }),
  // ダミーの SAML 認可エンドポイント（fetchするのではないので、ここには辿り着かないが、書いておく）
  rest.get(makeApiUrl("/dummy/saml/authorize"), (req, res, ctx) => {
    console.log("$$$$$$$$ /dummy/saml/authorize $$$$$$$$");
    const params = req.url.searchParams;
    const state = params.get("state");
    const code = "saml-authorize-ok-code";
    const uri = params.get("redirect_uri");
    const redirectUri = encodeURI(makeApiUrl(`${uri ?? "null"}`) + `?state=${state ?? "null"}&code=${code}`);
    // console.log(`dummy SAML authorize endpoint: redirects to ${redirectUri}`); // debug
    return res(
      ctx.status(301),
      ctx.set("Location", encodeURI(makeApiUrl(`${redirectUri}`) + `?state=${state ?? "null"}&code=${code}`)),
    );
  }),
  // SAML 認証コードを Cognito トークンと交換
  rest.post(makeApiUrl("/api/user/saml/exchange_token"), (req, res, ctx) => {
    const code: string = (JSON.parse(req.body as string) as SamlExchangeTokenRequest).code;
    if (code !== "saml-authorize-ok-code") {
      return res(ctx.status(Number.parseInt(code.replace(/[^0-9]+/g, ""))));
    }
    const accessToken = "AccessToken-saml-exchange";
    const refreshToken = "RefreshToken-saml-exchange";
    const expIn = 3600;
    const { idToken } = createDummyTokenAtNow("IdToken", "saml-exchange", expIn);
    const resp = ctx.json({
      AccessToken: accessToken,
      RefreshToken: refreshToken,
      IdToken: idToken,
      TokenType: "Bearer",
      ExpiresIn: expIn,
    });
    // 開発時はレスポンスを5秒遅延させる
    if (process.env.NODE_ENV === "development") {
      return res(resp, ctx.delay(5000));
    }
    return res(resp);
  }),
  // 作品一覧取得
  rest.get(makeApiUrl("/api/works"), (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(works));
  }),
  // 作品情報(エピソード一覧)取得
  rest.get(makeApiUrl("/api/work/:workId"), (req, res, ctx) => {
    const { workId } = req.params;
    const idx = Object.keys(episodes).findIndex((key) => {
      return key === workId;
    });
    if (idx < 0) {
      return res(ctx.status(404), ctx.text("Contents Not Found!"));
    }
    return res(ctx.status(200), ctx.json(Object.values(episodes)[idx]));
  }),
  // 作品 フォルダ情報変更
  rest.patch(makeApiUrl("/api/work/:workId"), (req, res, ctx) => {
    const { workId } = req.params;
    const newInfo = JSON.parse(req.body as string) as UpdateWorkRequest["info"];
    // console.log(`handled PATCH /api/cut/${cutId} ${req.body as string}`); // debug
    let newWork = undefined;
    for (const work of works.items) {
      if (work.id === workId) {
        newWork = { ...work, ...newInfo };
        // とりあえず title だけ変える
        work.title = newInfo.title;
        break;
      }
    }
    if (!newWork) {
      return res(ctx.status(404), ctx.text("404 Not Found"));
    }
    return res(ctx.status(200), ctx.json(newWork));
  }),
  // 話数情報(カット一覧)取得
  rest.get(makeApiUrl("/api/episode/:episodeId"), (req, res, ctx) => {
    const { episodeId } = req.params;
    const idx = Object.keys(cuts).findIndex((key) => key === episodeId);
    if (idx < 0) {
      return res(ctx.status(404), ctx.text("Contents Not Found!"));
    }
    return res(ctx.status(200), ctx.json(Object.values(cuts)[idx]));
  }),
  // エピソード情報変更
  rest.patch(makeApiUrl("/api/episode/:episodeId"), (req, res, ctx) => {
    const { episodeId } = req.params;
    const newInfo = JSON.parse(req.body as string) as UpdateEpisodeRequest["info"];
    // console.log(`handled PATCH /api/cut/${cutId} ${req.body as string}`); // debug
    let newEpisode = undefined;
    for (const work of Object.entries(episodes)) {
      for (const episode of work[1].episodes) {
        if (episode.id === episodeId) {
          newEpisode = { ...episode, ...newInfo };
          // とりあえず title だけ変える
          episode.no = newInfo.no;
          episode.subtitle = newInfo.subtitle;
          break;
        }
      }
    }
    if (!newEpisode) {
      return res(ctx.status(404), ctx.text("404 Not Found"));
    }
    return res(ctx.status(200), ctx.json(newEpisode));
  }),
  // 工程一覧取得
  rest.get(makeApiUrl("/api/cut/:cutId"), (req, res, ctx) => {
    const { cutId } = req.params;
    const idx = Object.keys(processes).findIndex((key) => key === cutId);
    if (idx < 0) {
      return res(ctx.status(404), ctx.text("Contents Not Found!"));
    }
    return res(ctx.status(200), ctx.json(Object.values(processes)[idx]));
  }),
  // カット情報変更
  rest.patch(makeApiUrl("/api/cut/:cutId"), (req, res, ctx) => {
    const { cutId } = req.params;
    const newInfo = JSON.parse(req.body as string) as UpdateCutRequest["info"];
    // console.log(`handled PATCH /api/cut/${cutId} ${req.body as string}`); // debug
    let newCut = undefined;
    for (const episode of Object.entries(cuts)) {
      for (const cut of episode[1].cuts) {
        if (cut.id === cutId) {
          newCut = { ...cut, ...newInfo };
          // とりあえず title だけ変える
          cut.title = newInfo.title;
          break;
        }
      }
    }
    if (!newCut) {
      return res(ctx.status(404), ctx.text("404 Not Found"));
    }
    return res(ctx.status(200), ctx.json(newCut));
  }),
  // カットデータ取得
  rest.get(makeApiUrl("/api/process/:processId"), (req, res, ctx) => {
    const { processId } = req.params;
    const idx = Object.keys(files).findIndex((key) => key === processId);
    if (idx < 0) {
      return res(ctx.status(404), ctx.text("Contents Not Found!"));
    }
    return res(ctx.status(200), ctx.json(Object.values(files)[idx]));
  }),
  // 一発アップロードURLの取得
  rest.post(makeApiUrl("/api/upload/single"), (req, res, ctx) => {
    const { Id, ObjectName } = JSON.parse(req.body as string) as SingleFileUploadRequest;
    const filename = ObjectName.replace(/ /g, "_").replace(/\//g, "-");
    // console.log(`/api/upload/single Id: ${Id}, ObjectName: ${ObjectName}, filename: ${filename}`);
    if (Id === "id-for-upload-failure") {
      return res(ctx.status(200), ctx.json({ Url: makeApiUrl(`/dummy/upload/failure_path/${filename}`) }));
    }
    // 開発時はレスポンスを0～1秒遅延させる
    if (process.env.NODE_ENV === "development") {
      return res(
        ctx.status(200),
        ctx.json({ Url: makeApiUrl(`/dummy/upload/success_path/${filename}`) }),
        ctx.delay(Math.floor(1000 * Math.random())),
      );
    }
    return res(ctx.status(200), ctx.json({ Url: makeApiUrl(`/dummy/upload/success_path/${filename}`) }));
  }),
  // 一発アップロード(成功用パス)
  rest.put(makeApiUrl("/dummy/upload/success_path/:filename"), (req, res, ctx) => {
    const { filename } = req.params;
    // 開発時はレスポンスを0～5秒遅延させる
    if (process.env.NODE_ENV === "development") {
      return res(
        ctx.status(200),
        ctx.json(`success! (${filename.toString()})`),
        ctx.delay(Math.floor(5000 * Math.random())),
      );
    }
    return res(ctx.status(200), ctx.json(`success! (${filename.toString()})`));
  }),
  // 一発アップロード(失敗用パス)
  rest.put(makeApiUrl("/dummy/upload/failure_path/:filename"), (req, res, ctx) => {
    const { filename } = req.params;
    return res(ctx.status(500), ctx.json(`failed...(${filename.toString()})`));
  }),
  // 1ファイルダウンロードURL取得
  rest.get(makeApiUrl("/api/file"), (req, res, ctx) => {
    const key = req.url.searchParams.get("key");
    if (key) {
      return res(ctx.status(200), ctx.json({ Url: makeApiUrl("/" + key) }));
    }
    return res(ctx.status(404));
  }),
  // 1ファイル削除
  rest.delete(makeApiUrl("/api/file"), (req, res, ctx) => {
    const key = req.url.searchParams.get("key");
    if (key === "key-for-non-existent-file") {
      return res(ctx.status(404), ctx.text("404 Not Found"));
    }
    // 開発時はレスポンスを0～1秒遅延させる
    if (process.env.NODE_ENV === "development") {
      return res(ctx.status(204), ctx.delay(Math.floor(1000 * Math.random())));
    }
    return res(ctx.status(204));
  }),
  // 作品追加
  rest.post(makeApiUrl("/api/works"), (req, res, ctx) => {
    console.log(`handled POST /api/works ${req.body as string}`);
    return res(ctx.status(201));
  }),
  // エピソード追加
  rest.post(makeApiUrl("/api/work/:workId/episodes"), (req, res, ctx) => {
    const { workId } = req.params;
    console.log(`handled POST /api/work/${workId.toString()}/episodes ${req.body as string}`);
    return res(ctx.status(201));
  }),
  // カット追加
  rest.post(makeApiUrl("/api/episode/:episodeId/cuts"), (req, res, ctx) => {
    const { episodeId } = req.params;
    console.log(`handled POST /api/episode/${episodeId.toString()}/cuts ${req.body as string}`);
    return res(
      ctx.status(201),
      ctx.json({
        cutId: "new-cut-id",
      }),
    );
  }),
  // 工程追加
  rest.post(makeApiUrl("/api/cut/:cutId/processes"), (req, res, ctx) => {
    const { cutId } = req.params;
    console.log(`handled POST /api/cut/${cutId.toString()}/processes ${req.body as string}`);
    return res(ctx.status(201));
  }),
  // フォルダエクスポート
  rest.post(makeApiUrl("/api/exports"), (req, res, ctx) => {
    const params = JSON.parse(req.body as string) as ExportsRequest;
    const fileName = params.archiveName ? params.archiveName + ".zip" : "archive.zip";
    const response: ExportsResponse = {
      numFetchedFiles: 1,
      exportJob: {
        id: "job1",
        status: "QUEUE",
        items: params.items,
        progress: 20,
        archive: {
          fileName: fileName,
          // storagePath: makeApiUrl("/thumbnails/1/1"), // 中身はjpgファイル(frontend\src\mocks\browser.ts参照)
        },
      },
    };
    // console.log(`/api/exports (ids: ${params.items}, fileName: ${fileName})`); // debug
    return res(ctx.status(200), ctx.json(response));
  }),
  // エクスポートジョブ進捗取得
  rest.get(makeApiUrl("/api/exports/job/:jobId"), (req, res, ctx) => {
    const { jobId } = req.params;
    const fileName = "archive.zip";
    const response: ExportsResponse = {
      numFetchedFiles: 1,
      exportJob: {
        id: jobId as string,
        status: "SUCCEEDED",
        items: [],
        progress: 100,
        archive: {
          fileName: fileName,
          storagePath: makeApiUrl("/thumbnails/1/1"), // 中身はjpgファイル(frontend\src\mocks\browser.ts参照)
        },
      },
    };
    return res(ctx.status(200), ctx.json(response));
  }),
  // 作品とエピソード一覧取得
  rest.get(makeApiUrl("/api/tree/works_and_episodes"), (req, res, ctx) => {
    const response = { worksCount: 0, works: [] };
    return res(ctx.status(200), ctx.json(response));
  }),
];
