import React from "react";
import { useGetCutsAndProcessesQuery } from "../../../modules/api";
import { ProcessForTreeView } from "../../../modules/api.types/cutsAndProcesses";
import { ExpandableTreeItem } from "../../atoms/ExpandableTreeItem";
import { NonExpandableTreeItem } from "../../atoms/NonExpandableTreeItem";
import { TreeItemLabel } from "../../atoms/TreeItemLabel";
import { CutTitle, ProcessType } from "../../../common/DisplayInterface.types";
import { getComparator } from "../../../utils/sortUtil";

interface CutProcessTreeComponentProps {
  episodeId: string;
}

export function CutProcessTreeComponent(props: CutProcessTreeComponentProps) {
  const buildProcessTreeItems = (processes: ProcessForTreeView[]) => {
    function sortProcess(processes: ProcessForTreeView[]) {
      return processes
        .map((v) => ({ id: v.id, type: new ProcessType(v.type) }))
        .sort(getComparator<{ type: ProcessType }>("asc", "type"));
    }
    const sortedProcesses = sortProcess(processes);
    return sortedProcesses.map((process) => {
      const processLabel = <TreeItemLabel to={`/process/${process.id}`} title={process.type.value} />;
      return <NonExpandableTreeItem id={process.id} label={processLabel} key={`treeItemProcess-${process.id}`} />;
    });
  };

  const { data: cuts } = useGetCutsAndProcessesQuery(props.episodeId, {
    selectFromResult: ({ data }) => ({
      data:
        data?.cuts.map((cut) => ({
          id: cut.id,
          title: cut.title,
          processes: cut.processes,
        })) ?? [],
    }),
    refetchOnFocus: true,
  });
  const renamedCuts = cuts.map((cut) => {
    return {
      id: cut.id,
      title: new CutTitle(cut.title),
      processes: cut.processes,
    };
  });
  const sortedCuts = renamedCuts.sort(getComparator<{ title: CutTitle }>("asc", "title"));
  return (
    <>
      {sortedCuts.map((cut) => {
        const cutLabel = <TreeItemLabel to={`/cut/${cut.id}`} title={cut.title.value} />;
        return (
          <ExpandableTreeItem id={cut.id} label={cutLabel} key={`treeItemCut-${cut.id}`}>
            {buildProcessTreeItems(cut.processes)}
          </ExpandableTreeItem>
        );
      })}
    </>
  );
}
