import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form";
import { t } from "i18next";

import { useGetSamlAuthorizeEndpointQuery } from "../../../modules/api";
import { selectSamlState } from "../../../modules/authSlice";
import { useAppSelector } from "../../../hooks/rtk-hooks";
import { InputText } from "../../atoms/InputText";
import AppLogo from "../../atoms/AppLogo";

interface EmailEntryProps {
  onSetNeedPasswordLogin: (email: string) => void;
}

const StyledTitle = styled("div")({
  marginBottom: 30,
  fontSize: "25px",
});
const StyledButtonContainer = styled("div")({
  marginTop: 10,
});

type EmailEntryFormInputs = {
  email?: string | undefined;
};

export const EmailEntry: React.FC<EmailEntryProps> = (props) => {
  const { onSetNeedPasswordLogin } = props;

  const [email, setEmail] = useState<string>();
  const defaultValues: DefaultValues<EmailEntryFormInputs> = { email: "" };
  const validationRules = { email: { required: t("message.メールアドレスを入力してください。") } };
  const { control, handleSubmit } = useForm<EmailEntryFormInputs>({ defaultValues });
  const onSubmit: SubmitHandler<EmailEntryFormInputs> = (data: EmailEntryFormInputs) => {
    setEmail(data.email);
  };

  const { data: samlAuthorize, isSuccess } = useGetSamlAuthorizeEndpointQuery(email ?? skipToken);
  const samlState = useAppSelector(selectSamlState);
  useEffect(() => {
    if (email && isSuccess) {
      const samlEndpoint =
        samlAuthorize?.endpoint && samlState ? `${samlAuthorize.endpoint}&state=${samlState}` : undefined;
      if (samlEndpoint) {
        // SAML 認可エンドポイントへ
        // console.log({ samlEndpoint }); // debug
        window.location.assign(samlEndpoint);
      } else {
        onSetNeedPasswordLogin(email);
      }
    }
  }, [email, isSuccess, onSetNeedPasswordLogin, samlAuthorize, samlState]);

  return (
    <>
      <StyledTitle>
        <div>
          <AppLogo />
        </div>
        <div>{t("word.ログイン")}</div>
      </StyledTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputText
            name="email"
            label={t("word.メールアドレス")}
            control={control}
            defaultValue={defaultValues.email}
            rules={validationRules.email}
            type="text"
            autoFocus={true}
          />
        </div>
        <StyledButtonContainer>
          <Button variant="contained" type="submit">
            {t("word.次へ")}
          </Button>
        </StyledButtonContainer>
      </form>
    </>
  );
};
