import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { isValidToken } from "../utils/tokenUtil";
import * as ApiTypes from "./api.types";
import * as ApiTags from "./api.tags";
import { initAuth, resetIdToken, setIdToken } from "./authSlice";
import { RootState } from "./store";
import { encodeUriForS3Key } from "../utils/cdnUtil";

// refresh_token処理なしのBaseQueryFn
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_BASE_URL : undefined,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.idToken;
    if (token) {
      headers.set("Authorization", `${token}`);
    }
    return headers;
  },
});

// RTK Query本家を参考にrefresh_token API処理中は排他する
const mutex = new Mutex();

// idTokenが無効になっていた場合に自動的にrefresh_token APIでidTokenを再取得するBaseQueryFn
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  // refresh_token API処理が終わるのを待つ
  await mutex.waitForUnlock();
  // idTokenが有効かチェック(除外APIは除く)
  const excludeApiUrl = [
    "/api/user/login",
    "/api/user/challenge/new_password",
    "/api/user/saml/authorize_endpoint",
    "/api/user/saml/exchange_token",
  ];
  if (
    excludeApiUrl.findIndex((url) => (args as FetchArgs).url.startsWith(url)) < 0 &&
    !isValidToken((api.getState() as RootState).auth.idToken, (api.getState() as RootState).auth.authTime)
  ) {
    // 有効ではなかったので、refresh_token APIでトークンの再取得
    let isSuccess = false;
    // 排他開始
    const release = await mutex.acquire();
    try {
      // 無効になったidTokenを削除
      api.dispatch(resetIdToken());
      // refresh_token APIでidToken再取得
      const refreshToken = (api.getState() as RootState).auth.refreshToken;
      const refreshResult = await baseQuery(
        {
          method: "POST",
          url: "/api/user/refresh_token",
          headers: { "content-type": "text/plain" },
          credentials: "include",
          body: JSON.stringify({ token: refreshToken }),
        },
        api,
        extraOptions,
      );
      if (refreshResult.data) {
        // LoginResponseと同じ形式でレスポンスが返ってくる(ただし、RefreshTokenは入っていない)
        const refreshTokenRes = refreshResult.data as ApiTypes.LoginResponse;
        if (!("ChallengeName" in refreshTokenRes) && "AuthenticationResult" in refreshTokenRes) {
          // 再取得できた（See: Login.tsx）
          // console.log("refres_token success!"); // debug
          const newIdToken = refreshTokenRes["AuthenticationResult"]["IdToken"];
          // console.log({ newIdToken }); // debug
          // idTokenを再設定
          const currentSeconds = Math.floor(new Date().valueOf() / 1000);
          api.dispatch(setIdToken({ idToken: newIdToken, authTime: currentSeconds }));
          // 成功にする
          isSuccess = true;
        } else {
          // dataは返してきたが再取得は失敗している
          const refreshTokenDataError = refreshResult.data;
          console.log({ refreshTokenDataError });
        }
      } else {
        // refresh_token APIエラー
        const refreshTokenError = refreshResult.error;
        console.log({ refreshTokenError });
      }
    } finally {
      // 排他終わり
      release();
    }
    if (!isSuccess) {
      // idTokenは削除しているのでログインしなおす必要がある
      console.log("refresh_token failed");
      // auth stateをクリアして、強制的にログイン画面に遷移
      api.dispatch(initAuth());
      window.location.href = "/";
      // 結局APIは実行できなかったので仮のエラーを返す
      // console.log("baseQueryWithReauth returned."); // debug
      return { error: { status: "CUSTOM_ERROR", error: "refresh_token failed" } };
    }
  }
  // API処理を実行
  return await baseQuery(args, api, extraOptions);
};

// API Gateway向けAPI
export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ApiTags.allTagTypes,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    // ログイン
    login: builder.mutation<ApiTypes.LoginResponse, ApiTypes.LoginRequest>({
      query: (params) => ({
        url: "/api/user/login",
        method: "POST",
        credentials: "include",
        body: JSON.stringify(params),
      }),
    }),
    // Challenge New Password
    challengeNewPassword: builder.mutation<ApiTypes.ChallengeNewPasswordResponse, ApiTypes.ChallengeNewPasswordRequest>(
      {
        query: (params) => ({
          url: "/api/user/challenge/new_password",
          method: "PUT",
          headers: {
            "content-type": "text/plain",
          },
          credentials: "include",
          body: JSON.stringify(params),
        }),
      },
    ),
    // SAML 認可エンドポイント URL 取得
    getSamlAuthorizeEndpoint: builder.query<
      ApiTypes.GetSamlAuthorizeEndpointResponse,
      ApiTypes.GetSamlAuthorizeEndpointRequest
    >({
      query: (email) => ({
        url: `/api/user/saml/authorize_endpoint?email=${email}`,
      }),
    }),
    // SAML 認証コードを Cognito トークンと交換
    samlExchangeToken: builder.mutation<ApiTypes.SamlExchangeTokenResponse, ApiTypes.SamlExchangeTokenRequest>({
      query: (params) => ({
        url: "/api/user/saml/exchange_token",
        method: "POST",
        credentials: "include",
        body: JSON.stringify(params),
      }),
    }),
    // 作品一覧取得
    getWorks: builder.query<ApiTypes.GetWorksResponse, ApiTypes.GetWorksRequest>({
      query: () => ({
        url: "/api/works",
      }),
      providesTags: ApiTags.providesWorksTags,
    }),
    // 作品追加
    addWork: builder.mutation<void, ApiTypes.AddWorkRequest>({
      query: (params) => ({
        url: "/api/works",
        method: "POST",
        body: JSON.stringify(params.item),
      }),
      invalidatesTags: ApiTags.invalidatesWorkListTags,
    }),
    // 作品情報（各話一覧）取得
    getWork: builder.query<ApiTypes.GetWorkResponse, ApiTypes.GetWorkRequest>({
      query: (queryString) => ({
        url: `/api/work/${queryString.workId}`,
      }),
      providesTags: ApiTags.providesWorkTags,
    }),
    // 作品情報変更
    updateWork: builder.mutation<ApiTypes.UpdateWorkResponse, ApiTypes.UpdateWorkRequest>({
      query: (params) => ({
        url: `/api/work/${params.workId}`,
        method: "PATCH",
        body: JSON.stringify(params.info),
      }),
      invalidatesTags: ApiTags.invalidatesUpdateWorkTags,
    }),
    // 作品サムネイル登録
    addWorkThumbnail: builder.mutation<ApiTypes.AddWorkThumbnailResponse, ApiTypes.AddWorkThumbnailRequest>({
      query: (params) => ({
        url: `/api/thumbnail/${params.workId}`,
        method: "POST",
        body: params.file,
      }),
      invalidatesTags: ApiTags.invalidatesAddWorkThumbnailTags,
    }),
    // エピソード追加
    addEpisode: builder.mutation<void, ApiTypes.AddEpisodeRequest>({
      query: (params) => ({
        url: `/api/work/${params.workId}/episodes`,
        method: "POST",
        body: JSON.stringify(params.episode),
      }),
      invalidatesTags: ApiTags.invalidatesEpisodeListTags,
    }),
    // エピソード情報（カット一覧）取得
    getEpisode: builder.query<ApiTypes.GetEpisodeResponse, ApiTypes.GetEpisodeRequest>({
      query: (queryString) => ({
        url: `/api/episode/${queryString.episodeId}`,
      }),
      providesTags: ApiTags.providesEpisodeTags,
    }),
    // エピソード情報変更
    updateEpisode: builder.mutation<ApiTypes.UpdateEpisodeResponse, ApiTypes.UpdateEpisodeRequest>({
      query: (params) => ({
        url: `/api/episode/${params.episodeId}`,
        method: "PATCH",
        body: JSON.stringify(params.info),
      }),
      invalidatesTags: ApiTags.invalidatesUpdateEpisodeTags,
    }),
    // カット追加
    addCut: builder.mutation<ApiTypes.AddCutResponse, ApiTypes.AddCutRequest>({
      query: (params) => ({
        url: `/api/episode/${params.episodeId}/cuts`,
        method: "POST",
        body: JSON.stringify(params.cut),
      }),
      invalidatesTags: ApiTags.invalidatesCutListTags,
    }),
    // カット情報（工程一覧）取得
    getCut: builder.query<ApiTypes.GetCutResponse, ApiTypes.GetCutRequest>({
      query: (queryString) => ({
        url: `/api/cut/${queryString.cutId}`,
      }),
      providesTags: ApiTags.providesCutTags,
    }),
    // カット情報変更
    updateCut: builder.mutation<ApiTypes.UpdateCutResponse, ApiTypes.UpdateCutRequest>({
      query: (params) => ({
        url: `/api/cut/${params.cutId}`,
        method: "PATCH",
        body: JSON.stringify(params.info),
      }),
      invalidatesTags: ApiTags.invalidatesUpdateCutTags,
    }),
    // 工程追加
    addProcess: builder.mutation<void, ApiTypes.AddProcessRequest>({
      query: (params) => ({
        url: `/api/cut/${params.cutId}/processes`,
        method: "POST",
        body: JSON.stringify(params.process),
      }),
      invalidatesTags: ApiTags.invalidatesProcessListTags,
    }),
    // 工程情報(ファイル一覧)取得
    getProcess: builder.query<ApiTypes.GetProcessResponse, ApiTypes.GetProcessRequest>({
      query: (queryString) => ({
        url: `/api/process/${queryString.processId}`,
      }),
      providesTags: ApiTags.providesProcessTags,
    }),
    // 一発アップロードURLの取得
    singleFileUpload: builder.mutation<ApiTypes.SingleFileUploadResponse, ApiTypes.SingleFileUploadRequest>({
      query: (params) => ({
        url: `/api/upload/single`,
        method: "POST",
        headers: {
          "content-type": "text/plain",
        },
        body: JSON.stringify(params),
      }),
    }),
    // 1ファイルダウンロードURLの取得
    getFile: builder.query<ApiTypes.SingleFileDownloadResponse, ApiTypes.SingleFileDownloadRequest>({
      query: (key) => ({
        url: `/api/file?key=${encodeUriForS3Key(key)}`,
      }),
    }),
    // 1ファイル削除
    singleFileDelete: builder.mutation<void, ApiTypes.SingleFileDeleteRequest>({
      query: (queryString) => ({
        url: `/api/file?key=${encodeUriForS3Key(queryString.key)}`,
        method: "DELETE",
      }),
      // refetchのタイミングはProcess.tsxで制御する
      // invalidatesTags: ApiTags.invalidatesFileListTags,
    }),
    // フォルダエクスポート
    exports: builder.mutation<ApiTypes.ExportsResponse, ApiTypes.ExportsRequest>({
      query: (params) => ({
        url: "/api/exports",
        method: "POST",
        body: JSON.stringify(params),
      }),
    }),
    // エクスポートジョブ進捗取得
    getExportsJob: builder.query<ApiTypes.GetExportsJobResponse, ApiTypes.GetExportsJobRequest>({
      query: (jobId) => ({
        url: `/api/exports/job/${jobId}`,
      }),
    }),
    // 作品とエピソード一覧取得
    getWorksAndEpisodes: builder.query<ApiTypes.GetWorksAndEpisodesResponse, ApiTypes.GetWorksAndEpisodesRequest>({
      query: () => ({
        url: "/api/tree/works_and_episodes",
      }),
      providesTags: ApiTags.providesWorksAndEpisodesTags,
    }),
    // カットと工程一覧取得
    getCutsAndProcesses: builder.query<ApiTypes.GetCutsAndProcessesResponse, ApiTypes.GetCutsAndProcessesRequest>({
      query: (episodeId) => ({
        url: `/api/tree/${episodeId}/cuts_and_processes`,
      }),
      providesTags: ApiTags.providesCutsAndProcessesTags,
    }),
  }),
});

export const {
  useLoginMutation,
  useChallengeNewPasswordMutation,
  useGetSamlAuthorizeEndpointQuery,
  useSamlExchangeTokenMutation,
  useGetWorksQuery,
  useAddWorkMutation,
  useGetWorkQuery,
  useUpdateWorkMutation,
  useAddEpisodeMutation,
  useGetEpisodeQuery,
  useUpdateEpisodeMutation,
  useAddCutMutation,
  useGetCutQuery,
  useUpdateCutMutation,
  useAddProcessMutation,
  useGetProcessQuery,
  useSingleFileUploadMutation,
  useGetFileQuery,
  useSingleFileDeleteMutation,
  useExportsMutation,
  useGetExportsJobQuery,
  useGetWorksAndEpisodesQuery,
  useGetCutsAndProcessesQuery,
  useAddWorkThumbnailMutation,
} = api;
