import React, { ReactNode, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import { Breadcrumbs } from "../organisms/Breadcrumbs";
import { ViewChangeButton, ViewChangeButtonProps } from "../organisms/ViewChangeButton";
import { AddContentButton, AddContentButtonProps } from "../atoms/AddContentButton";
import { FileUploadButton, FileUploadButtonProps } from "../atoms/UploadButton";
import { MessageBar, MessageBarProps } from "../molecules/MessageBar";
import { useScrollRestoration } from "../../hooks/contexts/ScrollRestorationProvider";
import { FolderUploadButton, FolderUploadButtonProps } from "../molecules/FolderUploadButton";
import { AppFrame } from "./AppFrame";
import { titleHeight } from "../../common/constants/TemplateConstants";

// layout
export const messageBarHeight = 36;

const ContentsContainer = styled("div")({
  width: "100%",
  paddingLeft: "16px",
});

const ContentsHeader = styled("div")({
  width: "100%",
  height: titleHeight,
  paddingTop: "20px",
  paddingBottom: "9px", // borderBottomWidth: "thin" == 1px
  borderBottom: "thin solid #ccc",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const ContentsHeaderTitle = styled("div")({
  float: "left",
});
const ContentsHeaderRightButtons = styled("div")({
  float: "right",
});

type AppContentsFrameProps = {
  children: ReactNode;
  viewChangeButtonProps?: ViewChangeButtonProps;
  addContentButtonProps?: AddContentButtonProps;
  folderUploadButtonProps?: FolderUploadButtonProps;
  fileUploadButtonProps?: FileUploadButtonProps;
  messageBarProps?: MessageBarProps;
};
export const AppContentsFrame: React.FC<AppContentsFrameProps> = (props) => {
  const {
    children,
    viewChangeButtonProps,
    addContentButtonProps,
    folderUploadButtonProps,
    fileUploadButtonProps,
    messageBarProps,
  } = props;
  const sideBarDummyLocation = "/TreeView";
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { restoreAndStartStoring } = useScrollRestoration();
  useEffect(() => {
    restoreAndStartStoring(sideBarDummyLocation, scrollElementRef);
  }, [restoreAndStartStoring]);
  return (
    <AppFrame>
      <ContentsContainer>
        <ContentsHeader>
          <ContentsHeaderTitle>
            <Breadcrumbs height={`${titleHeight}px`} />
          </ContentsHeaderTitle>
          <ContentsHeaderRightButtons>
            <Stack direction={"row"} sx={{ paddingRight: "6px" }}>
              {folderUploadButtonProps ? <FolderUploadButton {...folderUploadButtonProps} /> : null}
              {fileUploadButtonProps ? <FileUploadButton {...fileUploadButtonProps} /> : null}
              {addContentButtonProps ? <AddContentButton {...addContentButtonProps} /> : null}
              {viewChangeButtonProps ? <ViewChangeButton {...viewChangeButtonProps} /> : null}
            </Stack>
          </ContentsHeaderRightButtons>
        </ContentsHeader>
        {messageBarProps ? <MessageBar {...messageBarProps} height={messageBarHeight} /> : null}
        {children}
      </ContentsContainer>
    </AppFrame>
  );
};
