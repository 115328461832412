import React, { useState } from "react";
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useMessage } from "../../../hooks/useMessage";
import { useUpdateEpisodeMutation } from "../../../modules/api";
import { makeEpisodeTitle, paddingEpisodeNo } from "../../../utils/makeTitleUtil";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface RenameEpisodeDialogProps {
  open: boolean;
  titles: Array<{ no: string; subtitle: string }>;
  currentNo: number;
  currentSubTitle: string;
  id: string;
  onClose: () => void;
}

export const RenameEpisodeDialog: React.FC<RenameEpisodeDialogProps> = (props) => {
  const { open, titles, currentNo, currentSubTitle, id, onClose } = props;
  const minNo = 1;
  const maxNo = 99;
  const currentIsUseNo = !Number.isNaN(currentNo);
  const [isUseNo, setIsUseNo] = useState<boolean>(currentIsUseNo);
  const [newNo, setNewNo] = useState<number>(currentNo);
  const [newSubTitle, setNewSubTitle] = useState<string>(currentSubTitle);
  const [newNoErrorMessage, setNewNoErrorMessage] = useState<string | null>(null);
  const [newSubTitleErrorMessage, setNewSubTitleErrorMessage] = useState<string | null>(null);
  const showMessage = useMessage();
  const [updateEpisode] = useUpdateEpisodeMutation();

  const validate = (isUseNo: boolean, no: string | undefined, subTitle: string | undefined): string | null => {
    // console.log({ no }); // debug
    if (isUseNo) {
      if (!no || no.length === 0) return t("message.話数を入力してください");
      if (no.match(/[e.,+-]/i)) return t("message.1 ～ 99 の整数を入力してください");
      const num = Number.parseInt(no);
      if (num < 1 || 99 < num) return t("message.1 ～ 99 を入力してください");
      if (currentIsUseNo === isUseNo && currentNo === num) {
        return null;
      }
      return titles.every((item) => Number.parseInt(item.no?.replace(/[^0-9]/g, "")) !== num)
        ? null
        : t("message.number があるので登録できません", { number: paddingEpisodeNo(String(num)) });
    } else {
      if (!subTitle || subTitle.length === 0) return t("message.タイトルを入力してください");
      if (currentIsUseNo === isUseNo && currentSubTitle === subTitle) {
        return null;
      }
      return titles.every((item) => !(item.no === "" && item.subtitle === subTitle))
        ? null
        : t("message.title は既に登録済みです", { title: subTitle });
    }
  };

  const setValidationResult = (isCheckedUseNo: boolean, result: string | null) => {
    // console.log(`Episode setValidationResult( isUseNo: ${isUseNoChecked}, result: ${result})`); // debug
    if (isCheckedUseNo) {
      // noあり（対象はnoのみ。subTitleのエラーはクリア）
      setNewNoErrorMessage(result);
      setNewSubTitleErrorMessage(null);
    } else {
      // noなし（対象はsubtitleのみ。noのエラーはクリア）
      setNewNoErrorMessage(null);
      setNewSubTitleErrorMessage(result);
    }
  };

  const handleChangeCheck = (checked: boolean) => {
    // console.log(`RenameEpisodeDialog: onChange(checked: ${checked})); // debug
    setIsUseNo(checked);
    const result = validate(checked, newNo > 0 ? String(newNo) : undefined, newSubTitle);
    setValidationResult(checked, result);
  };
  const handleChangeNo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const no = e.target.value;
    setNewNo(Number.parseInt(no));
    const result = validate(isUseNo, no, newSubTitle);
    setValidationResult(isUseNo, result);
  };
  const handleChangeSubTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const subTitle = e.target.value;
    setNewSubTitle(subTitle);
    const result = validate(isUseNo, newNo > 0 ? String(newNo) : undefined, subTitle);
    setValidationResult(isUseNo, result);
  };

  const onSubmit = () => {
    // console.log(`Episode( isUseNo: ${isUseNo}, newNo: ${newNo}, newSubTitle: ${newSubTitle}) => ${newTitle}`); // debug
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(isUseNo, newNo > 0 ? String(newNo) : undefined, newSubTitle);
    if (newNoErrorMessage || newSubTitleErrorMessage || err) {
      return;
    }

    // 結果として同じ話数名になる場合は、APIを呼ばずに閉じる
    if (isUseNo === currentIsUseNo && newSubTitle === currentSubTitle) {
      // チェック状態とサブタイトルが同じ
      if (!currentIsUseNo) {
        // 未チェック。サブタイトルは同じなのでAPIを呼ばない
        onClose();
        return;
      } else if (newNo === currentNo) {
        // チェックされていて、話数(validateが通っているのでnewNoは有効な番号)が同じなのでAPIを呼ばない
        onClose();
        return;
      }
    }

    const no = isUseNo ? String(newNo) : "";
    const newTitle = makeEpisodeTitle({ id: id, no: no, subtitle: newSubTitle });
    const oldTitle = makeEpisodeTitle({
      id: id,
      no: currentIsUseNo ? String(currentNo) : "",
      subtitle: currentSubTitle,
    });
    // console.log(`RENAME an Episode: no="${newNo}", subtitle="${newSubTitle}"`); // debug
    updateEpisode({
      episodeId: id,
      info: { no: no, subtitle: newSubTitle ?? "" },
    })
      .unwrap()
      .then(() => {
        showMessage({
          message: t("message.currentTitle を newTitle に変更しました。", {
            currentTitle: oldTitle,
            newTitle: newTitle,
          }),
        });
      })
      .catch((updateEpisodeResError: unknown) => {
        console.log({ updateEpisodeResError });
        showMessage({
          message: t("message.currentTitle から newTitle への変更に失敗しました。", {
            currentTitle: oldTitle,
            newTitle: newTitle,
          }),
        });
      });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.名前を変更")}
      submitButtonText={t("word.変更")}
      submitButtonDisabled={newNoErrorMessage !== null || newSubTitleErrorMessage !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <Stack spacing={1}>
          <Stack direction={"row"}>
            <FormControlLabel
              id="add-episode-use-no"
              control={<Checkbox />}
              sx={{ paddingBottom: "10px" }}
              label={t("phrase.先頭に話数を表示")}
              checked={isUseNo}
              onChange={(e, checked) => handleChangeCheck(checked)}
            />
            <AutoFocusTextField
              id="rename-episode-new-no"
              sx={{ marginTop: "10px", height: "64px", width: "250px" }}
              type={"number"}
              inputProps={{ min: minNo, max: maxNo }}
              disabled={!isUseNo}
              error={newNoErrorMessage ? true : false}
              helperText={newNoErrorMessage}
              value={Number.isNaN(newNo) ? "" : newNo}
              onChange={(e) => handleChangeNo(e)}
            />
          </Stack>
          <TextField
            id="rename-episode-new-title"
            sx={{ height: "64px", width: "426px" }}
            label={t("phrase.話数のタイトル")}
            helperText={newSubTitleErrorMessage}
            inputProps={{ "aria-label": "episode-text-of-rename-episode" }}
            error={newSubTitleErrorMessage ? true : false}
            value={newSubTitle}
            onChange={(e) => handleChangeSubTitle(e)}
          />
        </Stack>
      </FormGroup>
    </FormDialog>
  );
};
