import React, { useState } from "react";
import { FormGroup } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useUpdateCutMutation } from "../../../modules/api";
import { useMessage } from "../../../hooks/useMessage";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface RenameCutDialogProps {
  open: boolean;
  titles: Array<{ title: string }>;
  currentTitle: string;
  id: string;
  onClose: () => void;
}

export const RenameCutDialog: React.FC<RenameCutDialogProps> = (props) => {
  const { open, titles, currentTitle, id, onClose } = props;
  const [newTitle, setNewTitle] = useState<string>(currentTitle);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const showMessage = useMessage();
  const [updateCut] = useUpdateCutMutation();

  const validate = (title: string | undefined): string | null => {
    if (!title || title.length === 0) {
      return t("message.カットフォルダ名を入力してください");
    }
    if (title === currentTitle) {
      return null;
    }
    // 入力が数字のみの場合は同一番号のカットが存在しないか数字に変換して比較する
    if (title.replace(/^\d+$/, "") === "") {
      const inputTitleDigits = String(Number.parseInt(title));
      const titleDigits = (title: string): string =>
        title.replace(/^\d+$/, "") === "" ? String(Number.parseInt(title)) : title;
      const registeredCutTitle = titles.find((v) => titleDigits(v.title) === inputTitleDigits);
      if (registeredCutTitle) {
        return t("message.number があるので登録できません", { number: registeredCutTitle.title });
      }
    }
    return titles.every((item) => item.title !== title)
      ? null
      : t("message.number があるので登録できません", { number: title });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const title = e.target.value;
    setNewTitle(title);
    const result = validate(title);
    setErrorMessage(result);
  };

  const onSubmit = () => {
    if (newTitle === currentTitle) {
      onClose();
      return;
    }
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(newTitle);
    if (errorMessage || err) {
      return;
    }
    // console.log(`カット(id: ${id})のタイトル「${currentTitle}」を「${newTitle}」に変更`); // debug
    updateCut({
      cutId: id,
      info: {
        title: newTitle,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          message: t("message.currentTitle を newTitle に変更しました。", {
            currentTitle: currentTitle,
            newTitle: newTitle,
          }),
        });
      })
      .catch((error: unknown) => {
        console.log({ error });
        showMessage({
          message: t("message.currentTitle から newTitle への変更に失敗しました。", {
            currentTitle: currentTitle,
            newTitle: newTitle,
          }),
        });
      });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.名前を変更")}
      submitButtonText={t("word.変更")}
      submitButtonDisabled={errorMessage !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <AutoFocusTextField
          id="rename-cut-new-title"
          sx={{ marginTop: "10px", height: "64px", width: "300px" }}
          value={newTitle}
          inputProps={{ "aria-label": "cut-text-of-rename-cut" }}
          error={errorMessage ? true : false}
          label={t("word.カット名")}
          helperText={errorMessage}
          onChange={(e) => handleChange(e)}
        />
      </FormGroup>
    </FormDialog>
  );
};
