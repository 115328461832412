import { Buffer } from "buffer";

/**
 * ダミーのID Tokenを生成する
 * ID Tokenの形式はBase64エンコーディングした3つのpayloadを"."で結合したもの。
 * 有効期限の情報は真ん中のpayloadに必要なもののみ入れる。
 * 前後のpayloadは引数で受けたダミーの文字列。
 * @param pre 前のダミーpayload
 * @param post 後ろのダミーpayload
 * @param iat issuedAt(UTC通算秒)
 * @param exp 有効期限(UTC通算秒)
 * @returns ダミーのID Token
 */
export const createDummyToken = (pre: string, post: string, iat: number, exp: number): string => {
  const payload = { iat, exp };
  const base64Payload = Buffer.from(JSON.stringify(payload)).toString("base64");
  const dummyToken = pre + "." + base64Payload + "." + post;
  // console.log(`createDummyToken(pre: ${pre}, post: ${post}, iat: ${iat}, exp: ${exp}) => ${dummyToken}`); // debug
  return dummyToken;
};

/**
 * 現在時刻を元にダミーのID Tokenを生成する
 * @param pre see createDummyToken
 * @param post see createDummyToken
 * @param expIn 有効期間(default=1時間)
 * @returns {idToken: ダミーのID Token, iat: 発行日時(UTC通算秒), exp: 有効期限(UTC通算秒), expIn: 有効期間(秒)}
 */
export const createDummyTokenAtNow = (
  pre: string,
  post: string,
  expIn = 3600,
): { idToken: string; iat: number; exp: number; expIn: number } => {
  const expires = new Date();
  const iat = Math.floor(expires.valueOf() / 1000);
  const exp = iat + expIn;
  return { idToken: createDummyToken(pre, post, iat, exp), iat: iat, exp: exp, expIn: expIn };
};
