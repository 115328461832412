import React, { useState } from "react";
import { FormGroup } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useAddWorkMutation } from "../../../modules/api";
import { useMessage } from "../../../hooks/useMessage";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface AddWorksDialogProps {
  open: boolean;
  workTitles: Array<{ title: string }>;
  onClose: () => void;
}

export const AddWorkDialog: React.FC<AddWorksDialogProps> = (props) => {
  const { open, workTitles, onClose } = props;
  const [newTitle, setNewTitle] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const showMessage = useMessage();
  const [addWork] = useAddWorkMutation();

  const validate = (title: string | undefined): string | null => {
    if (!title || title.length === 0) return t("message.作品名を入力してください");
    return workTitles.every((item) => item.title !== title) ? null : t("message.title は既に登録済みです", { title });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const title = e.target.value;
    setNewTitle(title);
    const result = validate(title);
    setErrorMessage(result);
  };

  const onSubmit = () => {
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(newTitle);
    if (errorMessage || err) {
      return;
    }
    addWork({
      item: { title: newTitle, onAir: "", status: "" },
    })
      .unwrap()
      .then(() => {
        showMessage({ message: t("message.msgTitle を追加しました。", { msgTitle: newTitle }) });
      })
      .catch((addWorkResError: unknown) => {
        console.log({ addWorkResError });
        showMessage({ message: t("message.msgTitle の追加に失敗しました。", { msgTitle: newTitle }) });
      });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.作品を追加")}
      submitButtonText={t("word.追加")}
      submitButtonDisabled={newTitle.length === 0 || errorMessage !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <AutoFocusTextField
          id="add-work-new-title"
          sx={{ marginTop: "10px", height: "64px", width: "300px" }}
          value={newTitle}
          inputProps={{ "aria-label": "work-text-of-additional-work" }}
          error={errorMessage ? true : false}
          label={t("word.作品名")}
          helperText={errorMessage}
          onChange={(e) => handleChangeTitle(e)}
        />
      </FormGroup>
    </FormDialog>
  );
};
