import React, { useState } from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, ListItem, ListSubheader } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";
import { DownloadListItem } from "./DownloadListItem";
import { t } from "i18next";

const Container = styled(List)(() => ({
  width: "400px",
  maxHeight: "370px",
  padding: "0px",
  position: "fixed",
  bottom: 20,
  right: 50,
  boxShadow: "0 0 5px #a9a9a9",
  zIndex: 1,
}));

const StyledListHeader = styled(ListSubheader)(() => ({
  height: "60px",
  paddingTop: "7px",
  paddingBottom: "10px",
  color: "#ffffff",
  backgroundColor: "#000000",
}));

const StyledListBody = styled(Box)(() => ({
  width: "100%",
  maxHeight: "300px",
  overflowX: "hidden",
  overflowY: "auto",
}));

const StyledListRow = styled(ListItem)(() => ({
  width: "100%",
  height: "60px",
  paddingRight: "40px",
  fontSize: "15px",
  borderBottom: " solid #E6E6E6",
}));

const StyledButton = styled("button")(() => ({
  paddingTop: "10px",
  color: "#ffffff",
  backgroundColor: "#000000",
  position: "absolute",
  border: "none",
}));

export type DownloadListJobsItem = {
  jobId: string;
  title: string;
};

interface DownloadListProps {
  jobs: Array<DownloadListJobsItem>;
  onClose: () => void;
  onComplete: (jobId: string) => void;
  onError: (jobId: string) => void;
}

export const DownloadList: React.FC<DownloadListProps> = (props) => {
  const { jobs, onClose, onComplete, onError } = props;
  const [openDownloadListBody, setOpenDownloadListBody] = useState<boolean>(true);

  const handleClick = () => {
    setOpenDownloadListBody(!openDownloadListBody);
  };

  return (
    <Container
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
      }}
    >
      <StyledListHeader>
        {t("phrase.ダウンロードの準備")}
        <StyledButton
          onClick={handleClick}
          style={{
            right: 50,
          }}
        >
          {openDownloadListBody ? <ExpandMore /> : <ExpandLess />}
        </StyledButton>
        <StyledButton
          onClick={() => {
            onClose();
          }}
          style={{
            right: 0,
            paddingRight: "10px",
          }}
        >
          <ClearIcon />
        </StyledButton>
      </StyledListHeader>
      <Collapse in={openDownloadListBody} timeout="auto" data-testid={"DownloadListCollapse"}>
        <StyledListBody>
          {jobs.map((job) => (
            <List component="div" disablePadding key={job.jobId}>
              <StyledListRow>
                <DownloadListItem jobId={job.jobId} title={job.title} onComplete={onComplete} onError={onError} />
              </StyledListRow>
            </List>
          ))}
        </StyledListBody>
      </Collapse>
    </Container>
  );
};
