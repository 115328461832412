import React from "react";
import styled from "@emotion/styled";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import ZoomOutTwoToneIcon from "@mui/icons-material/ZoomOutTwoTone";
import { IconButton } from "../../atoms/IconButton";
import { t } from "i18next";

interface ZoomButtonsProps {
  containerWidth: number;
  onZoomIn: (e: React.MouseEvent) => void;
  onZoomOut: (e: React.MouseEvent) => void;
  onZoomReset: (e: React.MouseEvent) => void;
}

const buttonSize = 44; // width, height  // default minWidth 64px

const StyledButtonFrame = styled("div")({
  position: "absolute",
  bottom: "16px",
});

const iconStyle = {
  minWidth: "24px",
};

const buttonStyle = {
  border: "none",
  borderRadius: "0%",
  minWidth: `${buttonSize}px`,
  maxWidth: `${buttonSize}px`,
  minHeight: `${buttonSize}px`,
  color: "#f5f5f5",
  backgroundColor: "rgba(0, 0, 0, .85)",
  zIndex: "1",
};

const ZoomButtons: React.FC<ZoomButtonsProps> = (props) => {
  const { containerWidth, onZoomIn, onZoomOut, onZoomReset } = props;
  return (
    <StyledButtonFrame style={{ left: `${containerWidth / 2 - (buttonSize * 3) / 2}px` }}>
      <IconButton
        tooltip={t("word.縮小")}
        icon={RemoveOutlinedIcon}
        iconStyle={iconStyle}
        buttonStyle={buttonStyle}
        onClick={(e) => {
          onZoomOut(e);
        }}
      />
      <IconButton
        tooltip={t("phrase.ズームをリセット")}
        icon={ZoomOutTwoToneIcon}
        iconStyle={iconStyle}
        buttonStyle={buttonStyle}
        onClick={(e) => {
          onZoomReset(e);
        }}
      />
      <IconButton
        tooltip={t("word.拡大")}
        icon={AddTwoToneIcon}
        iconStyle={iconStyle}
        buttonStyle={buttonStyle}
        onClick={(e) => {
          onZoomIn(e);
        }}
      />
    </StyledButtonFrame>
  );
};

export default ZoomButtons;
