import React from "react";
import { ReactComponent as IconImage } from "../../../assets/images/AManageIcon.svg";
import { t } from "i18next";

// SVGの属性を指定可能。サイズを変えたい場合は以下のように書く。(以下の値はAManageIcon.svgのデフォルトサイズ)
// <AppIcon width={36} height={36} />
const AppIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return <IconImage {...props} title={t("word.アマネージュ")} />;
};

export default AppIcon;
