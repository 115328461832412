import React, { createContext, useEffect, useRef } from "react";

export const ConfirmBeforeCloseContext = createContext<(consumerId: string, shouldConfirm: boolean) => void>(() => {
  // do nothing
});

export const ConfirmBeforeCloseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const consumerList = useRef<string[]>([]);

  useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  const onBeforeUnload = (e: BeforeUnloadEvent) => {
    if (consumerList.current.length > 0) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  const shouldConfirmBeforeClose = (consumerId: string, shouldConfirm: boolean) => {
    if (shouldConfirm) {
      if (!consumerList.current.includes(consumerId)) {
        consumerList.current.push(consumerId);
      }
    } else {
      const index = consumerList.current.findIndex((v) => v === consumerId);
      if (index >= 0) {
        consumerList.current.splice(index, 1);
      }
    }
  };

  return (
    <ConfirmBeforeCloseContext.Provider value={shouldConfirmBeforeClose}>{children}</ConfirmBeforeCloseContext.Provider>
  );
};
