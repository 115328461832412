import { store } from "../store";
import { api } from "../api";

export const fileDelete = (id: string, onSuccess: () => void, onError: () => void) => {
  store
    .dispatch(api.endpoints.singleFileDelete.initiate({ key: id }))
    .unwrap()
    .then(() => {
      // console.log(`single file delete Success (key: ${id})`); // debug
      onSuccess();
    })
    .catch((fileDeleteError: unknown) => {
      console.log(`single file delete Error (key: ${id})`);
      console.log({ fileDeleteError });
      onError();
    });
};
