import { ComparableString } from "./ComparableString";

export class FilePath extends ComparableString {
  protected readonly forCompareFilePath: string;
  constructor(protected readonly filePath: string) {
    const s = filePath.replace(/\//g, " / ");
    super(s);
    this.forCompareFilePath = this.toCompareString(filePath);
  }
  compareTo(target: FilePath): number {
    return this.compareString(this.forCompareFilePath, target.forCompareFilePath);
  }
  protected toCompareString(filePath: string): string {
    const folders = filePath.split("/");
    const fileName = folders.pop();
    return (folders.length > 0 ? " " + folders.join(" /  ") + " / " : "") + (fileName ? fileName : "");
  }
}
