import React, { useState } from "react";
import { FormGroup } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useUpdateWorkMutation } from "../../../modules/api";
import { useMessage } from "../../../hooks/useMessage";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface RenameWorkDialogProps {
  open: boolean;
  titles: Array<{ title: string }>;
  currentTitle: string;
  id: string;
  onClose: () => void;
}

export const RenameWorkDialog: React.FC<RenameWorkDialogProps> = (props) => {
  const { open, titles, currentTitle, id, onClose } = props;
  const [newTitle, setNewTitle] = useState<string>(currentTitle);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const showMessage = useMessage();
  const [updateWork] = useUpdateWorkMutation();

  const validate = (title: string | undefined): string | null => {
    if (!title || title.length === 0) {
      return t("message.作品名を入力してください");
    }
    if (title === currentTitle) {
      return null;
    }
    return titles.every((item) => item.title !== title) ? null : t("message.title は既に登録済みです", { title });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const title = e.target.value;
    setNewTitle(title);
    const result = validate(title);
    setErrorMessage(result);
  };

  const onSubmit = () => {
    if (newTitle === currentTitle) {
      onClose();
      return;
    }
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(newTitle);
    if (errorMessage || err) {
      return;
    }
    updateWork({
      workId: id,
      info: {
        title: newTitle,
      },
    })
      .unwrap()
      .then(() => {
        showMessage({
          message: t("message.currentTitle を newTitle に変更しました。", {
            currentTitle: currentTitle,
            newTitle: newTitle,
          }),
        });
      })
      .catch((error: unknown) => {
        console.log({ error });
        showMessage({
          message: t("message.currentTitle から newTitle への変更に失敗しました。", {
            currentTitle: currentTitle,
            newTitle: newTitle,
          }),
        });
      });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.名前を変更")}
      submitButtonText={t("word.変更")}
      submitButtonDisabled={newTitle.length === 0 || errorMessage !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <AutoFocusTextField
          id="rename-work-new-title"
          sx={{ marginTop: "10px", height: "64px", width: "300px" }}
          value={newTitle}
          inputProps={{ "aria-label": "work-text-of-rename-work" }}
          error={errorMessage ? true : false}
          label={t("word.作品名")}
          helperText={errorMessage}
          onChange={(e) => handleChange(e)}
        />
      </FormGroup>
    </FormDialog>
  );
};
