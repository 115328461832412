import styled from "@emotion/styled";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import React from "react";

const Container = styled("div")<NoImageProps>((props) => ({
  width: props.width,
  height: props.height,
  background: "#F6F7F9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
  whiteSpace: "nowrap",
  border: "thin solid #E6E6E6",
}));

interface NoImageProps {
  width: string;
  height: string;
  scale?: number;
  iconStyle?: React.CSSProperties;
}

const NoImage: React.FC<NoImageProps> = (props) => {
  const { iconStyle, ...rest } = props;
  return (
    <Container {...rest}>
      <ImageOutlinedIcon
        style={{
          color: "#C0C2C4",
          verticalAlign: "middle",
          margin: "auto",
          ...iconStyle,
        }}
      />
    </Container>
  );
};

export default NoImage;
