import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "@emotion/styled";

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  fontSize: 20,
  color: "#616161",
});

export const InfoIcon: React.FC = () => {
  return <StyledInfoIcon />;
};
