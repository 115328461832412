import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";

import { useAppDispatch, useAppSelector } from "../hooks/rtk-hooks";
import { SelectedIdProvider } from "../hooks/contexts/SelectedIdProvider";
import { setSelectedWork } from "../modules/titleSlice";
import { selectEpisodesSortOrder, selectEpisodesViewMode, setEpisodesViewMode } from "../modules/viewConfigSlice";
import { useGetWorkQuery } from "../modules/api";
import { AppDataFrame } from "../components/templates/AppDataFrame";
import { EpisodesTable, EpisodesTableComparableItem } from "../components/organisms/ListViews/EpisodesTable";
import { CardView } from "../components/organisms/CardView";
import { getComparator } from "../utils/sortUtil";
import { AddEpisodeDialog, RenameEpisodeDialog } from "../components/organisms/FormDialogs";
import AddContentCard from "../components/molecules/AddContentCard";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { EpisodeTitle, ComparableDate, FolderIcon } from "../common/DisplayInterface.types";
import { selectNewestDateString } from "../utils/dateUtil";

export const Work: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const viewMode = useAppSelector(selectEpisodesViewMode);
  const { order, orderBy } = useAppSelector(selectEpisodesSortOrder);
  const { workId } = useParams<{ workId: string | undefined }>();

  // 作品情報（エピソード一覧）取得
  const {
    data: episodes,
    cdnUrl,
    isSuccess,
    isUninitialized,
    isLoading,
    work,
  } = useGetWorkQuery(workId ? { workId: workId } : skipToken, {
    selectFromResult: ({ data, isSuccess, isUninitialized, isLoading }) => ({
      data:
        data?.episodes.map((episode) => ({
          id: episode.id,
          title: new EpisodeTitle(episode.no, episode.subtitle),
          thumbnail: episode.thumbnail,
          updatedAt: new ComparableDate(
            selectNewestDateString({
              candidates: [episode.latestFileUpdatedAt, episode.thumbnail?.updatedAt],
              default: episode.createdAt,
            }),
          ),
          no: episode.no ?? "",
          subtitle: episode.subtitle ?? "",
          icon: new FolderIcon(episode.latestFileUpdatedAt !== null),
        })) ?? [],
      cdnUrl: data?.cdnUrl ?? "",
      isSuccess,
      isUninitialized,
      isLoading,
      work: data?.work,
    }),
  });

  // パンくずリスト更新
  useEffect(() => {
    if (work) {
      dispatch(setSelectedWork({ work: { id: work.id, title: work.title } }));
    }
  }, [work, dispatch]);

  // リストビュー用データ
  const listViewData = useMemo(() => {
    return episodes;
  }, [episodes]);
  // サムネイルビュー用データ
  const thumbnailViewData = useMemo(() => {
    return episodes.sort(getComparator<EpisodesTableComparableItem>(order, orderBy)).map((value) => ({
      id: value.id,
      title: value.title,
      updatedAt: value.updatedAt,
      thumbnail: value.thumbnail,
      icon: value.icon,
    }));
  }, [episodes, order, orderBy]);
  // SelectedIdProvider 向け ID リスト
  const sortedIds = useMemo(() => {
    return episodes.sort(getComparator<EpisodesTableComparableItem>(order, orderBy)).map<string>((value) => value.id);
  }, [episodes, order, orderBy]);

  // ページ遷移
  const pageTransition = (episodeId: string) => {
    navigate("/episode/" + episodeId);
  };

  // 登録・追加ダイアログ関連
  const [openAddEpisodeDialog, setOpenAddEpisodeDialog] = useState<boolean>(false);
  const episodeTitles = useMemo(() => {
    return episodes.map((item) => {
      return { no: item.no, subtitle: item.subtitle };
    });
  }, [episodes]);

  // 話数名変更
  const [openRenameEpisodeDialog, setOpenRenameEpisodeDialog] = useState<boolean>(false);
  const [renameTarget, setRenameTarget] = useState<{ id: string; no: string; subtitle: string }>();
  const doRename = (targetIds: Array<string>): void => {
    if (targetIds.length === 1) {
      const renameTarget = episodes.find((episode) => episode.id === targetIds[0]);
      if (renameTarget) {
        setRenameTarget({ id: renameTarget.id, no: renameTarget.no, subtitle: renameTarget.subtitle });
        setOpenRenameEpisodeDialog(true);
      }
    }
  };

  // 右クリックメニューの設定
  const menuItems = [{ text: t("phrase.名前の変更"), icon: EditOutlinedIcon, handler: doRename }];

  return (
    <AppDataFrame
      viewChangeButtonProps={
        isSuccess && episodes.length > 0 && workId
          ? { selecter: selectEpisodesViewMode, action: setEpisodesViewMode }
          : undefined
      }
      addContentButtonProps={
        isSuccess && episodes.length > 0 && workId
          ? {
              tooltip: t("phrase.話数を追加"),
              onClick: () => setOpenAddEpisodeDialog(true),
            }
          : undefined
      }
    >
      {openAddEpisodeDialog && workId ? (
        <AddEpisodeDialog
          open={openAddEpisodeDialog}
          workId={workId}
          episodeTitles={episodeTitles}
          onClose={() => {
            // console.log("AddEpiisodeDialog closed."); // debug
            setOpenAddEpisodeDialog(false);
          }}
        />
      ) : null}
      {openRenameEpisodeDialog && renameTarget ? (
        <RenameEpisodeDialog
          open={openRenameEpisodeDialog}
          titles={episodeTitles}
          currentNo={Number.parseInt(renameTarget.no)}
          currentSubTitle={renameTarget.subtitle}
          id={renameTarget.id}
          onClose={() => {
            setOpenRenameEpisodeDialog(false);
          }}
        />
      ) : null}
      <div>
        <SelectedIdProvider sortedIds={sortedIds}>
          {isUninitialized || isLoading ? null : episodes.length === 0 ? (
            <AddContentCard title={t("phrase.話数を追加")} onClick={() => setOpenAddEpisodeDialog(true)} />
          ) : viewMode === "list" ? (
            <EpisodesTable
              cdnUrl={cdnUrl}
              episodes={listViewData}
              handlePageTransition={pageTransition}
              menuItems={menuItems}
            />
          ) : (
            <CardView
              cdnUrl={cdnUrl}
              items={thumbnailViewData}
              handlePageTransition={pageTransition}
              menuItems={menuItems}
            />
          )}
        </SelectedIdProvider>
      </div>
    </AppDataFrame>
  );
};
