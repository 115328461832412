import styled from "@emotion/styled";
import React from "react";
import { t } from "i18next";

const StyledStatusChips = styled("div")(() => ({
  borderRadius: "10px",
  width: "60px",
  height: "23px",
  paddingTop: "4px",
  paddingBottom: "2px",
  color: "#000000",
  fontSize: "12px",
  textAlign: "center",
  backgroundColor: "rgb(161,245,186)",
}));

export const ShiageStatusChips: React.FC = () => {
  return <StyledStatusChips>{t("word.仕上げ")}</StyledStatusChips>;
};
