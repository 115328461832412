import { setupWorker, rest } from "msw";
import { handlers } from "./handlers";
import { makeApiUrl } from "../test_env/makeApiUrl";

import base64Image01 from "./images/10962211375_1bf22311df_b.jpg";
import base64Image02 from "./images/16241258325_25fb1ff343_b.jpg";
import base64Image03 from "./images/16776743255_13a6868032_b.jpg";
import base64Image04 from "./images/16777473645_344a006fe5_b.jpg";
import base64Image05 from "./images/23612909143_6371baa9b1_b.jpg";
import base64Image06 from "./images/32007016811_518f9d7748_b.jpg";
import base64Image07 from "./images/4091176054_c713d23384_b.jpg";
import base64Image08 from "./images/42457888771_0ed548dd16_b.jpg";
import base64Image09 from "./images/4877968590_a3e22b2e5a_b.jpg";
import base64Image10 from "./images/8599997695_7ce6975420_b.jpg";
import base64Image11 from "./images/9550569119_163c96ff85_b.jpg";
import base64Image12 from "./images/9550569905_4743020635_b.jpg";

const base64Images = [
  base64Image01,
  base64Image02,
  base64Image03,
  base64Image04,
  base64Image05,
  base64Image06,
  base64Image07,
  base64Image08,
  base64Image09,
  base64Image10,
  base64Image11,
  base64Image12,
];
// サムネイル画像のキャッシュ
const arrayBufferImages: Array<ArrayBuffer> = [];
export const makeImages = () => {
  for (const base64Image of base64Images) {
    void fetch(base64Image)
      .then((res) => res.arrayBuffer())
      .then((v) => arrayBufferImages.push(v));
  }
};
makeImages();

export const worker = setupWorker(
  ...handlers,
  rest.get(makeApiUrl("/thumbnails/:imageId/*"), (req, res, ctx) => {
    const { imageId: imageIdParam } = req.params;
    const imageIdStr: string = typeof imageIdParam === "string" ? imageIdParam : imageIdParam[0];
    const imageId: number = parseInt(imageIdStr, 10) - 1;
    // console.log(`rest.get /thumbnails/${imageIdParam} -> /thumbnails/${imageIdStr}`); // debug
    if (imageId + 1 > arrayBufferImages.length) {
      // まだ読み込まれていないか、存在しないID。
      return res(ctx.status(404, "Not found"));
    }
    return res(
      ctx.set("Content-Length", arrayBufferImages[imageId].byteLength.toString()),
      ctx.set("Content-Type", "image/jpeg"),
      ctx.body(arrayBufferImages[imageId]),
    );
  }),
);
