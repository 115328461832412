import { useCallback, useEffect, useState } from "react";

// unmount 状態で呼ぶと例外発生等の弊害がある関数を実行する際の安全装置
// 例えば、Context Provider に登録する callback から refetch させたい場合に使用する
export const useSafetyTrigger: (func: () => void) => () => void = (func) => {
  const [triggerCounter, setTriggerCounter] = useState<number>(0);
  useEffect(() => {
    if (triggerCounter) func();
    // func は呼び出し元がメモ化していない可能性があるので、triggerCounter のみを deps にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCounter]);
  return useCallback(() => setTriggerCounter((prev) => prev + 1), []);
};
