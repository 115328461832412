import React from "react";
import { ImageListItem } from "@mui/material";
import CardViewItemBarText from "../../atoms/CardViewItemBarText";
import ThumbnailImage from "../../atoms/ThumbnailImage";
import CardViewItemBar from "../../atoms/CardViewItemBar";
import { IconInterface } from "../../../common/DisplayInterface.types";
interface CardViewItemProps {
  title: string;
  src: string;
  srcSet?: string;
  width: number;
  height: number;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  icon: IconInterface;
}

const CardViewItem: React.FC<CardViewItemProps> = (props) => {
  const { title, src, srcSet, width, height, thumbnailWidth, thumbnailHeight, icon } = props;
  const areaRatio = width / height;
  const thumbnailRatio = thumbnailWidth && thumbnailHeight ? thumbnailWidth / thumbnailHeight : 0;
  // console.log(`[${title}] areaRatio: ${areaRatio}, thumbnailRatio: ${thumbnailRatio}`); // debug
  // viewport に対する 設定
  return (
    <ImageListItem style={{ width: `${width}px` }}>
      {src ? (
        // 表示可能なサムネイルあり
        <ThumbnailImage
          src={src}
          srcSet={srcSet}
          alt={title}
          style={{
            position: "absolute",
            inset: "0px",
            width: `${thumbnailRatio === 0 ? "auto" : areaRatio > thumbnailRatio ? "auto" : "100%"}`,
            height: `${thumbnailRatio === 0 ? "auto" : areaRatio > thumbnailRatio ? "100%" : "auto"}`,
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          containerHeight={height}
          aspectRatio={areaRatio}
        />
      ) : (
        <div style={{ position: "relative", width: `${width}px`, height: `${height}px` }}>{icon.cardView}</div>
      )}
      <CardViewItemBar>
        <>
          <CardViewItemBarText>{title}</CardViewItemBarText>
        </>
      </CardViewItemBar>
    </ImageListItem>
  );
};

export default CardViewItem;
