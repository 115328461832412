import React from "react";
import TreeItem, { treeItemClasses, TreeItemContentProps, TreeItemProps, useTreeItem } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import styled from "@emotion/styled";

const CustomContent = React.forwardRef(function CustomContent(props: TreeItemContentProps, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer} aria-label={`icon`}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

const StyledTreeItem = styled(TreeItem)({
  [`& .${treeItemClasses.content}`]: {
    "&:hover": { backgroundColor: "#ECF4FE" },
  },
  [`& .${treeItemClasses.content}.${treeItemClasses.selected}`]: {
    backgroundColor: "transparent",
  },
  [`& .${treeItemClasses.content}.${treeItemClasses.focused}`]: {
    backgroundColor: "#E0EEFF",
  },
});

const CustomTreeItem = (props: TreeItemProps) => <StyledTreeItem ContentComponent={CustomContent} {...props} />;

interface ExpandableTreeItemProps {
  id: string;
  label: JSX.Element;
  children?: React.ReactNode;
}

export function ExpandableTreeItem(props: ExpandableTreeItemProps) {
  return (
    <CustomTreeItem nodeId={props.id} label={props.label}>
      {React.Children.count(props.children) > 0 ? props.children : [<div key={`treeItemNone-${props.id}`} />]}
    </CustomTreeItem>
  );
}
