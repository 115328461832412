import React from "react";
import { SvgIconComponent } from "@mui/icons-material";

interface ContentIconProps {
  icon:
    | SvgIconComponent
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >;
  iconStyle?: React.CSSProperties;
}

export const ContentIcon: React.FC<ContentIconProps> = (props) => {
  const { iconStyle, icon: Icon } = props;
  return (
    <Icon
      style={{
        color: "#7F7F7F",
        verticalAlign: "middle",
        margin: "auto",
        ...iconStyle,
      }}
    />
  );
};
