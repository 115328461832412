import React, { createContext, useContext, useMemo, useState } from "react";

export const TreeViewContext = createContext<{
  expandedIds: string[];
  setExpandedIds: (value: string[]) => void;
}>({
  expandedIds: [],
  setExpandedIds: () => {
    // do nothing
  },
});

export const useTreeViewExpanding = () => useContext(TreeViewContext);

export const TreeViewProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [expandedIds, setExpandedIds] = useState<string[]>(["works"]);
  const providerValue = useMemo(
    () => ({
      expandedIds: expandedIds,
      setExpandedIds,
    }),
    [expandedIds],
  );
  return <TreeViewContext.Provider value={providerValue}>{children}</TreeViewContext.Provider>;
};
