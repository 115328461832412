// Tag types
export const Works = "Works";
export const Work = "Work";
export const Episode = "Episode";
export const Cut = "Cut";
export const Process = "Process";
export const File = "File";

export const allTagTypes = [Works, Work, Episode, Cut, Process, File];

// Tag id
export const ListId = "LIST"; // 一覧（リスト）全体を表すTag id
