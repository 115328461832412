/**
 * ファイル名（拡張子含まず）に使える文字列に変換する
 * @param fileName 変換元文字列
 * @returns 保存可能なファイル名（拡張子含まず）
 */
export const convertToValidFileName = (fileName: string): string => {
  const validCharFileName = fileName
    .trim() // 無効ではないがやっておく
    .replace(/[\\/:*?"<>|]/g, "_") // Windows系/Mac系で使えない文字
    .replace(/^\./, "_"); // Mac系で無効なケース
  // Windows系で予約済みのファイル名（拡張子がついてもダメ）
  const invalidFileNames = [
    "CON",
    "PRN",
    "AUX",
    "NUL",
    "COM1",
    "COM2",
    "COM3",
    "COM4",
    "COM5",
    "COM6",
    "COM7",
    "COM8",
    "COM9",
    "LPT1",
    "LPT2",
    "LPT3",
    "LPT4",
    "LPT5",
    "LPT6",
    "LPT7",
    "LPT8",
    "LPT9",
  ];
  const invalidFileName = invalidFileNames.find((f) => f === validCharFileName);
  return invalidFileName || !validCharFileName ? "_" + validCharFileName : validCharFileName;
};

/**
 * タイムシートファイルか否かを拡張子から調べる
 * @param fileName ファイル名（拡張子付き）
 * @returns true: タイムシート、 false: タイムシートではない
 */
export const isFileTimeSheet = (fileName: string): boolean => {
  return fileName.match(/^.*\.xdts$/i) !== null;
};

/**
 * 表示可能なファイルであるかを拡張子で調べる
 * @param filePath 拡張子で終わるファイルパス（クエリ文字列は許可）
 * @returns true: 表示可能, false: 表示不可
 */
export const canBeDisplayed = (filePath: string): boolean => {
  return filePath.match(/^.*\.(jpe?g|png|webp)(\?.+)*$/i) !== null;
};
