import * as tagTypes from "./tagTypes";
import { GetWorksAndEpisodesResponse } from "../api.types";

const typeItems = tagTypes.Work;
const typeSubItems = tagTypes.Episode;

export const providesWorksAndEpisodesTags = (result: GetWorksAndEpisodesResponse | undefined) => {
  if (result) {
    const tags = [
      ...result.works.map(({ id }) => ({ type: typeItems, id })),
      { type: typeItems, id: tagTypes.ListId },
      { type: typeSubItems, id: tagTypes.ListId },
    ];
    result.works.forEach(({ episodes }) => tags.push(...episodes.map(({ id }) => ({ type: typeSubItems, id }))));
    return tags;
  }
  return [
    { type: typeItems, id: tagTypes.ListId },
    { type: typeSubItems, id: tagTypes.ListId },
  ];
};
