import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { t } from "i18next";

import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

import Canvas from "../../molecules/Canvas";
import { IconButton } from "../../atoms/IconButton";
import { NextButton, PrevButton } from "../../atoms/PagingButton";

const StyledImageArea = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  margin: 0,
  padding: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledTitleBar = styled(AppBar)({
  color: "#f5f5f5",
  backgroundColor: "rgba(0, 0, 0, .40)",
  height: 60,
  flexGrow: 1,
  position: "fixed",
  padding: 0,
});

const StyledToolBar = styled(Toolbar)({
  margin: 0,
});

const StyledFileName = styled(Typography)({
  margin: "0px 10px",
});

const StyledImagePreview = styled("div")({
  backgroundColor: "rgba(0, 0, 0, .85)",
  top: "50%",
  left: "50%",
  width: "100%",
  height: "100%",
  textAlign: "center",
});

type Size = {
  width: number;
  height: number;
};

type ImagePreviewItemType = {
  id: string;
  title: string;
  thumbnails: string[];
};
interface ImagePreviewProps {
  items: Array<ImagePreviewItemType>;
  initialIndex: number; // 0 ～
  onClose: () => void;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ items, initialIndex, onClose }) => {
  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [imgSize, setImgSize] = useState<Size>({ width: 1, height: 1 });

  // ImagePreview開閉
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // 表示するイメージ
  const [index, setIndex] = useState<number>(initialIndex);
  // 前へ
  const prev = () => {
    setIndex(index - 1);
  };
  // 次へ
  const next = () => {
    setIndex(index + 1);
  };
  // キーが押されたイベント(イメージ切り替え)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // console.log(e.code); // debug
    if (index > 0 && e.code === "ArrowLeft") {
      e.preventDefault();
      e.stopPropagation();
      prev();
    } else if (index < items.length - 1 && e.code === "ArrowRight") {
      e.preventDefault();
      e.stopPropagation();
      next();
    }
  };

  // 表示領域
  const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowSize, setWindowSize] = useState<Size>(getWindowSize());
  useEffect(() => {
    // console.log({ windowSize }); // debug
    const onResize = () => {
      const newWindowSize = getWindowSize();
      // console.log({ newWindowSize }); // debug
      setWindowSize(newWindowSize);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // devicePixelRatio (高解像度モニタ等への対応)
  const [devicePixelRatio, setDevicePixelRatio] = useState<number>(1);
  useEffect(() => {
    const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
    const updatePixelRatio = () => {
      const newRatio = window.devicePixelRatio;
      // console.log({ newRatio }); // debug
      setDevicePixelRatio(newRatio);
    };
    updatePixelRatio();
    // TODO: JSDOM に実装されていないメソッドのモックを下記を参考に試したがaddEventListenerが見つからないとなってしまうため、テスト時にはいったん外す。
    // https://jestjs.io/ja/docs/26.x/manual-mocks#jsdom-%E3%81%AB%E5%AE%9F%E8%A3%85%E3%81%95%E3%82%8C%E3%81%A6%E3%81%84%E3%81%AA%E3%81%84%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89%E3%81%AE%E3%83%A2%E3%83%83%E3%82%AF
    if (process.env.NODE_ENV !== "test") {
      matchMedia(mqString).addEventListener("change", updatePixelRatio);
      return () => matchMedia(mqString).removeEventListener("change", updatePixelRatio);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 表示領域サイズ変更
  useEffect(() => {
    // console.log("useEffect[windowSize]"); //debug
    // console.log({ windowSize }); //debug
    // console.log({ img }); //debug
    if (img && windowSize) {
      const newWindowSize = calcImgSize(img.naturalWidth, img.naturalHeight, windowSize.width, windowSize.height);
      // console.log({ newWindowSize }); // debug
      setImgSize(newWindowSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img, windowSize.width, windowSize.height]);

  // 画像ロード
  useEffect(() => {
    const img = new Image();
    if (!items[index] || !items[index].thumbnails) {
      // アップロード後にサムネイルができるまでポーリングしている間、あるいはファイル削除完了前に表示した場合に
      // itemsが無くなる、あるいはサムネイルが無くなる場合がある。
      // 画像ロードはサムネイルが出来てから実行する。
      return;
    }
    img.src = items[index].thumbnails.find((v) => v.includes("original")) ?? items[index].thumbnails[0];
    img.onload = () => {
      const size = calcImgSize(img.naturalWidth, img.naturalHeight, windowSize.width, windowSize.height);
      setImgSize(size);
      setImg(img);
      // console.log("image loaded."); // debug
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, index]);

  const calcImgSize = (srcWidth: number, srcHeight: number, windowWidth: number, windowHeight: number): Size => {
    // 表示領域
    const containerWidth = windowWidth; // window幅いっぱい
    const containerHeight = windowHeight - 120; // AppBarと拡縮ボタンの分を引く
    // console.log(
    //   `calcImgSize(src(w: ${srcWidth}, h: ${srcHeight}), window(w: ${windowWidth}, h: ${windowHeight}), container(w: ${containerWidth}, h: ${containerHeight}))`,
    // ); // debug
    // 表示領域に対する画像サイズの比率
    const widthRatio = srcWidth / containerWidth;
    const heightRatio = srcHeight / containerHeight;
    // 納まるか？
    if (widthRatio < 1.0 && heightRatio < 1.0) {
      // 縦横とも表示領域におさまっているなら原寸大で表示
      // console.log(`  returns {w: ${srcWidth}, h:${srcHeight}}`); // debug
      return { width: srcWidth, height: srcHeight };
    } else {
      // 比率が大きい方で設定
      if (widthRatio > heightRatio) {
        // console.log(`  returns {w: ${srcWidth / widthRatio}, h:${srcHeight / widthRatio}}`); // debug
        return { width: srcWidth / widthRatio, height: srcHeight / widthRatio };
      } else {
        // console.log(`  returns {w: ${srcWidth / heightRatio}, h:${srcHeight / heightRatio}}`); //debug
        return { width: srcWidth / heightRatio, height: srcHeight / heightRatio };
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="image-preview-modal-title"
      aria-describedby="image-preview-modal-description"
      onKeyDown={handleKeyDown}
      hideBackdrop
    >
      <StyledImagePreview>
        <StyledTitleBar>
          <StyledToolBar style={{ paddingLeft: 0, paddingRight: 0 }}>
            <IconButton
              tooltip={t("word.閉じる")}
              icon={ArrowBackSharpIcon}
              buttonStyle={{
                color: "#f5f5f5",
                backgroundColor: "inherit",
                minWidth: "64px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
              }}
            />
            {/* アップロード後にサムネイルができるまでポーリングしている間、
                あるいはファイル削除完了前に表示した場合、itemsが無くなる場合がある。
                この間は title 表示を抑止する */}
            <StyledFileName>{items[index]?.title ?? ""}</StyledFileName>
          </StyledToolBar>
        </StyledTitleBar>
        {index > 0 ? (
          <PrevButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              prev();
            }}
          />
        ) : null}
        {index < items.length - 1 ? (
          <NextButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              next();
            }}
          />
        ) : null}
        <StyledImageArea>
          <Canvas
            canvasWidth={windowSize.width}
            canvasHeight={windowSize.height}
            devicePixelRatio={devicePixelRatio}
            img={img}
            imgWidth={imgSize.width}
            imgHeight={imgSize.height}
          ></Canvas>
        </StyledImageArea>
      </StyledImagePreview>
    </Modal>
  );
};
