import React, { createContext, useState } from "react";
import { createPortal } from "react-dom";
import { PositionedSnackbar } from "../../components/atoms/Snackbar/PositionedSnackbar";

// export for testWrapper
export const MessageContext = createContext<(args: { message: string; duration?: number }) => void>(() => {
  // do nothing
});
MessageContext.displayName = "MessageContext";

export const MessageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [duration, setDuration] = useState<number>();
  const showMessage = ({ message, duration }: { message: string; duration?: number }): void => {
    setMessage(() => message);
    setDuration(() => duration);
    setShowSnackbar(true);
    console.log(`showMessage({message: "${message}"})`); // 表示したメッセージをログにも出力
  };
  return (
    <MessageContext.Provider value={showMessage}>
      {children}
      {showSnackbar
        ? createPortal(
            <PositionedSnackbar message={message} duration={duration} onExited={() => setShowSnackbar(false)} />,
            document.getElementById("root") as HTMLElement,
          )
        : null}
    </MessageContext.Provider>
  );
};
