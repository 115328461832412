import React from "react";
import styled from "@emotion/styled";

const StyledHeaderContainer = styled("div")<{ height?: number; backgroundColor?: string }>((props) => ({
  height: props.height !== undefined ? `${props.height}px` : undefined,
  backgroundColor: props.backgroundColor || "#F6F7F9",
  borderBottom: "thin solid #E6E6E6",
  display: "flex",
  alignItems: "center",
}));
const StyledHeaderUpload = styled("div")({
  fontSize: "13px", // default 16px
  marginLeft: "16px",
  paddingTop: "2px",
});
const StyledMessageIcon = styled("span")({
  display: "inline-flex",
  paddingBottom: "2px",
  marginRight: "8px",
  verticalAlign: "middle",
});
const StyledHeaderUploadProgress = styled("span")({
  marginRight: "20px",
});

export interface MessageBarMessageProps {
  messages: string[];
  height?: number;
}

interface BaseMessageBarProps extends MessageBarMessageProps {
  icon: JSX.Element;
  backgroundColor?: string;
}

export const BaseMessageBar: React.FC<BaseMessageBarProps> = (props) => {
  if (props.messages.length === 0) return null;
  return (
    <StyledHeaderContainer height={props.height} backgroundColor={props.backgroundColor} aria-label="message-bar">
      <StyledHeaderUpload>
        <StyledMessageIcon>{props.icon}</StyledMessageIcon>
        <StyledHeaderUploadProgress>{props.messages.join(", ")}</StyledHeaderUploadProgress>
      </StyledHeaderUpload>
    </StyledHeaderContainer>
  );
};
