import { useEffect, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetExportsJobQuery } from "../modules/api";
import { convertToValidFileName } from "../utils/fileNameUtil";

const pollingInterval = 2000;

export const useExportsJob = (targetJob: {
  jobId: string;
  onComplete: (jobId: string) => void;
  onError: (jobId: string) => void;
}): { progress: number } => {
  const { jobId, onComplete, onError } = targetJob;
  const [shouldPolling, setShouldPolling] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [archiveName, setArchiveName] = useState<string>();
  const [storagePath, setStoragePath] = useState<string>();
  const job = useGetExportsJobQuery(shouldPolling && jobId ? jobId : skipToken, {
    pollingInterval: pollingInterval,
  });

  // job進捗通知
  useEffect(() => {
    if (job.currentData?.exportJob.progress) {
      setProgress(job.currentData.exportJob.progress);
    }
  }, [job]);

  // job状態監視
  useEffect(() => {
    if (job.error) {
      console.error(JSON.parse(JSON.stringify(job.error)));
      setStoragePath(undefined);
      setShouldPolling(false);
      onError(jobId);
      return;
    }
    if (job.currentData?.exportJob.status === "SUCCEEDED") {
      if (job.currentData.exportJob.archive.storagePath) {
        setStoragePath(job.currentData.exportJob.archive.storagePath);
      }
      if (job.currentData.exportJob.archive.fileName) {
        setArchiveName(job.currentData.exportJob.archive.fileName);
      }
    }
  }, [jobId, job, onError]);

  // job完了。zipダウンロード
  useEffect(() => {
    if (!storagePath || !archiveName || !shouldPolling) return;
    setShouldPolling(false);
    fetch(storagePath)
      .then((res) => {
        if (!res.ok) {
          throw new Error("DownloadArchiveError");
        }
        return res.blob();
      })
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const aElement = document.createElement("a");
        document.body.appendChild(aElement);
        aElement.href = url;
        aElement.download = convertToValidFileName(archiveName);
        aElement.click();
        document.body.removeChild(aElement);
        setTimeout(() => {
          URL.revokeObjectURL(url);
        }, 1e4);
        onComplete(jobId);
      })
      .catch((error: unknown) => {
        console.error({ error });
        onError(jobId);
      })
      .finally(() => {
        setStoragePath(undefined);
        setArchiveName(undefined);
      });
  }, [storagePath, archiveName, shouldPolling, onComplete, onError, jobId]);

  return { progress };
};
