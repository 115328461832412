import React from "react";
import { BaseMessageBar, MessageBarMessageProps } from "../../atoms/BaseMessageBar";
import { ErrorIcon, InfoIcon, WarningIcon } from "../../atoms/AlertIcon";

export const AlertType = {
  INFO: 0,
  WARNING: 1,
  ERROR: 2,
} as const;

const alertElements: { [key: number]: { icon: JSX.Element; backgroundColor: string } } = {
  [AlertType.INFO]: { icon: <InfoIcon />, backgroundColor: "#F6F7F9" },
  [AlertType.WARNING]: { icon: <WarningIcon />, backgroundColor: "#fff9c4" },
  [AlertType.ERROR]: { icon: <ErrorIcon />, backgroundColor: "#ffebee" },
};

export interface AlertProps extends MessageBarMessageProps {
  alertType: (typeof AlertType)[keyof typeof AlertType];
}

export const AlertBar = (props: AlertProps) => {
  const messages = props.messages;
  const { icon, backgroundColor } = alertElements[props.alertType];
  return <BaseMessageBar messages={messages} icon={icon} backgroundColor={backgroundColor} height={props.height} />;
};
