import React from "react";
import styled from "@emotion/styled";
import { TableRow, TableRowProps } from "@mui/material";

const StyledMuiTableRow = styled(TableRow)({
  transition: "0s",
  background: "#FFFFFF",
  "&:hover": {
    background: "#ECF4FE",
  },
  "&:active": { background: "#E0EEFF" },
  "&.Mui-selected": {
    background: "#E0EEFF", // "#e1f5fe", // rgba(79, 129, 189, 0.10)  #4F81BD 透過率90%
    "&:hover": {
      background: "#E0EEFF",
    },
  },
  userSelect: "none",
});

export const StyledTableRow: React.FC<TableRowProps> = (props) => {
  return <StyledMuiTableRow {...props}></StyledMuiTableRow>;
};
