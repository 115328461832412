import React, { ReactNode, useEffect, useRef } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { TitleBar } from "../organisms/TitleBar";
import { TreeViewComponent } from "../organisms/TreeView";
import { useScrollRestoration } from "../../hooks/contexts/ScrollRestorationProvider";

// layout
export const titleBarHeight = 60;

const StyledAppContainer = styled(Container)({
  width: "100%",
  float: "left",
  paddingRight: "24px",
});

const MainContainer = styled("div")({
  display: "flex",
});

const SidebarContainer = styled("div")({
  height: `calc(100vh - ${titleBarHeight}px)`,
  minWidth: "250px",
  maxWidth: "250px",
  paddingTop: "20px",
  paddingBottom: "20px",
  paddingRight: "24px",
  overflow: "auto",
});

const ContentsContainer = styled("div")({
  width: "100%",
  paddingLeft: "16px",
});

type AppFrameProps = {
  children: ReactNode;
};
export const AppFrame: React.FC<AppFrameProps> = (props) => {
  const { children } = props;
  const sideBarDummyLocation = "/TreeView";
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { onScrollForRestoration, restoreAndStartStoring } = useScrollRestoration();
  useEffect(() => {
    restoreAndStartStoring(sideBarDummyLocation, scrollElementRef);
  }, [restoreAndStartStoring]);
  return (
    <StyledAppContainer
      maxWidth={false}
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
      }}
      disableGutters
    >
      <TitleBar height={`${titleBarHeight}px`} />
      <div style={{ height: `${titleBarHeight}px` }}></div>
      <MainContainer>
        <SidebarContainer ref={scrollElementRef} onScroll={(e) => onScrollForRestoration(sideBarDummyLocation, e)}>
          <TreeViewComponent />
        </SidebarContainer>
        <ContentsContainer>{children}</ContentsContainer>
      </MainContainer>
    </StyledAppContainer>
  );
};
