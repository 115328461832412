import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconButton } from "../IconButton";

export interface AddContentButtonProps {
  tooltip: string;
  onClick: () => void;
}

export const AddContentButton: React.FC<AddContentButtonProps> = (props) => {
  const { tooltip, onClick } = props;
  return (
    <IconButton
      tooltip={tooltip}
      icon={AddOutlinedIcon}
      buttonStyle={{
        padding: "0",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
};
