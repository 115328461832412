import { CutSummary, EpisodeSummary, EpisodeForTreeView } from "../modules/api.types";

// 話数の整形
export const paddingEpisodeNo = (no: string): string => "#" + no.padStart(2, "0");

// 表示する話数タイトル作成
export const makeEpisodeTitle = (episode: EpisodeSummary | EpisodeForTreeView): string => {
  if (episode.no && episode.no.length > 0) {
    if (episode.subtitle && episode.subtitle.length > 0) {
      return paddingEpisodeNo(episode.no) + " - " + episode.subtitle;
    } else {
      return paddingEpisodeNo(episode.no);
    }
  } else if (episode.subtitle && episode.subtitle.length > 0) {
    return episode.subtitle;
  }
  return "";
};

// カット番号の整形
export const makeCutTitle = (cut: CutSummary): string => {
  // 数字のみの場合は3桁で0パディングし、数字以外が含まれていた場合はそのまま
  return cut.title.replace(/^\d+$/, (v) => v.padStart(3, "0"));
};
