import { ComparableString } from "./ComparableString";
import { t } from "i18next";

const layout = new RegExp(t("word.レイアウト"));
const genga = new RegExp(t("word.原画"));
const douga = new RegExp(t("word.動画"));
const shiage = new RegExp(t("word.仕上"));

const typeToId = (type: string | undefined): number => {
  if (!type) {
    return 0;
  }
  switch (true) {
    case layout.test(type):
      return 1;
    case genga.test(type):
      return 2;
    case douga.test(type):
      return 3;
    case shiage.test(type):
      return 4;
    default:
      return 5;
  }
};

export class ProcessType extends ComparableString {
  protected readonly typeId: number;
  constructor(type: string) {
    super(type);
    this.typeId = typeToId(type);
  }
  compareTo(target: ProcessType): number {
    return this.typeId - target.typeId;
  }
}
