import { store } from "../store";
import { api } from "../api";
import { SingleFileUploadResponse } from "../api.types";

export type UploadStatus = "waiting" | "uploading" | "success" | "error";
export interface UploadFileInfo {
  id: string; // processId | folderId
  path: string; // path
  name: string; //filename
  file: File; // File object
  status: UploadStatus; // upload status
  startedAt?: Date; // upload 開始時間
  uploadedAt?: Date; // upload 完了時間 (status: "success" or "error")
  errorMessage?: string; // upload error message
}

// アップロード可能なファイルかを拡張子で判定
export const isValidUploadFileType = (filename: string): boolean => {
  // ファイル選択ダイアログでaccept指定しているファイル拡張子に合わせた。
  // 一般的な image/* と、.xdts, .tga を指定すると、ファイル選択ダイアログでは↓こうなる。これらを使いそうな順に並べて判定。
  // .tif, .pjp, .xbm, .jxl, .svgz, .jpg, .jpeg, .ico, .tiff, .gif, .jfif, .webp, .png, .bmp, .pjpeg, .avif, .xdts, .tga
  const fileext = filename.split(".");
  if (fileext.length < 2) {
    // 拡張子が無い
    return false;
  }
  const allowExt = [
    "tga",
    "xdts",
    "png",
    "jpg",
    "jpeg",
    "jfif",
    "tif",
    "tiff",
    "bmp",
    "gif",
    "pjp",
    "xbm",
    "jxl",
    "svgz",
    "webp",
    "pjpeg",
    "avif",
    "ico",
  ];
  return allowExt.includes(fileext[fileext.length - 1]);
};

// アップロードファイル情報追加関数(File用。第一引数の配列へ追加)
export const addUploadFileInfo = (
  uploadFiles: Array<UploadFileInfo>,
  id: string,
  file: File,
  path: string,
): Array<UploadFileInfo> => {
  const uploadFile: UploadFileInfo = {
    id: id,
    path: path,
    name: file.name,
    file: file,
    status: "waiting",
  };
  // console.log({ uploadFile }); // debug
  uploadFiles.push(uploadFile);
  return uploadFiles;
};

// ファイルアップロード実行
export const fileUpload = (
  uploadFile: UploadFileInfo,
  objectName: string,
  onSuccess: (uploadFile: UploadFileInfo) => void,
  onError: (uploadFile: UploadFileInfo) => void,
): void => {
  const { file, id } = uploadFile;
  // console.log(`upload(${objectName} -> id: ${id as string})`); // debug
  uploadFile.status = "uploading";
  const uploadUrl: Promise<SingleFileUploadResponse> = store
    .dispatch(api.endpoints.singleFileUpload.initiate({ Id: id, ObjectName: objectName }))
    .unwrap();
  // 上記APIのリクエスト送信後に呼び出し元へ制御を返す;
  uploadUrl
    .then((res) => {
      // console.log(`'${objectName}' upload to '${res.Url}'`); // debug
      uploadFile.startedAt = new Date();
      fetch(res.Url, {
        method: "PUT",
        body: file,
        headers: {
          "x-amz-meta-id": id,
        },
      })
        .then((putRes) => {
          if (putRes.ok) {
            // アップロード成功
            // console.log(`Upload Success!!! (${objectName})`); // debug
            // console.log({ putRes }); // debug
            uploadFile.uploadedAt = new Date();
            uploadFile.status = "success";
            onSuccess(uploadFile);
          } else {
            console.log(`Upload Error (${objectName})`);
            console.log({ putRes });
            uploadFile.uploadedAt = new Date();
            uploadFile.status = "error";
            uploadFile.errorMessage = `${putRes.status} ${String(putRes.statusText)}`;
            onError(uploadFile);
          }
        })
        .catch((putError: unknown) => {
          console.log(`PUT Error (${objectName})`);
          console.log({ putError });
          uploadFile.uploadedAt = new Date();
          uploadFile.status = "error";
          uploadFile.errorMessage = `${String(putError)}`;
          onError(uploadFile);
        });
    })
    .catch((singleFileUploadError: unknown) => {
      console.log(`single file upload Error (${objectName})`);
      console.log({ singleFileUploadError });
      uploadFile.uploadedAt = new Date();
      uploadFile.status = "error";
      uploadFile.errorMessage = `${JSON.stringify(singleFileUploadError)}`;
      onError(uploadFile);
    });
};
