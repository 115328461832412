import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

import { MessageProvider } from "./hooks/contexts/MessageProvider";
import { ConfirmDialogProvider } from "./hooks/contexts/ConfirmDialogProvider";
import { ContextMenuProvider } from "./hooks/contexts/ContextMenuProvider";
import { TreeViewProvider } from "./hooks/contexts/TreeViewProvider";
import { ScrollRestorationProvider } from "./hooks/contexts/ScrollRestorationProvider";
import { UploadProvider } from "./hooks/contexts/UploadProvider";
import { ExportsJobListProvider } from "./hooks/contexts/ExportsJobListProvider";
import { ExportsProvider } from "./hooks/contexts/ExportsProvider";
import { ConfirmBeforeCloseProvider } from "./hooks/contexts/ConfirmBeforeCloseProvider";
import { NavigationBlockerProvider } from "./hooks/contexts/NavigationBlockerProvider";
import { ConfirmDeletionProvider } from "./hooks/contexts/ConfirmDeletionProvider";

const StyledAppContainer = styled("div")({
  minHeight: "100vh",
});

export const App: React.FC = () => {
  return (
    <ConfirmBeforeCloseProvider>
      <MessageProvider>
        <ContextMenuProvider>
          <ConfirmDialogProvider>
            <NavigationBlockerProvider>
              <ConfirmDeletionProvider>
                <ExportsJobListProvider>
                  <ExportsProvider>
                    <UploadProvider>
                      <ScrollRestorationProvider>
                        <TreeViewProvider>
                          <StyledAppContainer>
                            <Outlet />
                          </StyledAppContainer>
                        </TreeViewProvider>
                      </ScrollRestorationProvider>
                    </UploadProvider>
                  </ExportsProvider>
                </ExportsJobListProvider>
              </ConfirmDeletionProvider>
            </NavigationBlockerProvider>
          </ConfirmDialogProvider>
        </ContextMenuProvider>
      </MessageProvider>
    </ConfirmBeforeCloseProvider>
  );
};
