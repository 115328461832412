import React, { createContext, useEffect, useState } from "react";
import { useMessage } from "../useMessage";
import { useConfirmDialog } from "../useConfirmDialog";
import { t } from "i18next";

export const ConfirmDeletionContext = createContext<
  (
    targetName: string,
    targetIds: string | string[],
    targetType: string,
    deleteFunction: (id: string, onSuccess: () => void, onError: () => void) => void,
    onSuccess: () => void,
    onError: () => void,
  ) => void
>(() => {
  // do nothing
});
ConfirmDeletionContext.displayName = "ConfirmDeletionContext";

export const ConfirmDeletionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const showMessage = useMessage();
  const showConfirmDialog = useConfirmDialog();
  const [results, setResults] = useState<{ success: number; failed: number; total: number }>({
    success: 0,
    failed: 0,
    total: 0,
  });
  // 複数ファイル削除完了時の処理
  useEffect(() => {
    if (results.total > 0 && results.total === results.success + results.failed) {
      // 削除完了
      if (results.failed === 0) {
        showMessage({ message: t("message.total ファイルを削除しました。", { total: results.total }) });
      } else {
        showMessage({
          message: t("message.total ファイルを削除しましたが、削除できなかったものがあります。", {
            total: results.total,
          }),
        });
      }
      setResults({ success: 0, failed: 0, total: 0 });
    }
  }, [results, showMessage]);

  const confirmDeletion = (
    targetName: string,
    targetIds: string | string[],
    targetType: string,
    deleteFunction: (id: string, onSuccess: () => void, onError: () => void) => void,
    onSuccess: () => void,
    onError: () => void,
  ): void => {
    const isOneFile = typeof targetIds === "string";
    if (!isOneFile && targetIds.length === 0) {
      return;
    }
    showConfirmDialog({
      title: t("message.targetTypeの削除", { targetType }),
      message: isOneFile
        ? t("message.「targetName」を削除しますか？", { targetName })
        : t("message.選択した length ファイルを削除しますか？", { length: targetIds.length }),
      buttons: [
        {
          text: t("word.キャンセル"),
          defaultButton: true,
          onClick: () => {
            // do nothing
          },
        },
        {
          text: t("word.削除"),
          defaultButton: false,
          onClick: () => {
            if (isOneFile) {
              deleteFunction(
                targetIds,
                () => {
                  showMessage({ message: t("message.「targetName」を削除しました。", { targetName }) });
                  onSuccess();
                },
                () => {
                  showMessage({ message: t("message.「targetName」の削除が失敗しました。", { targetName }) });
                  onError();
                },
              );
            } else {
              for (const id of targetIds) {
                setResults((prev) => ({ success: prev.success, failed: prev.failed, total: prev.total + 1 }));
                deleteFunction(
                  id,
                  () => {
                    setResults((prev) => ({ success: prev.success + 1, failed: prev.failed, total: prev.total }));
                    onSuccess();
                  },
                  () => {
                    setResults((prev) => ({ success: prev.success, failed: prev.failed + 1, total: prev.total }));
                    onError();
                  },
                );
              }
            }
          },
        },
      ],
    });
  };
  return <ConfirmDeletionContext.Provider value={confirmDeletion}>{children}</ConfirmDeletionContext.Provider>;
};
