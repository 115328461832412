import React from "react";
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { t } from "i18next";

import { Thumbnail } from "../../../modules/api.types";
import { getComparator } from "../../../utils/sortUtil";
import { ProcessesOrderBy, selectProcessesSortOrder, setProcessesSortOrder } from "../../../modules/viewConfigSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/rtk-hooks";
import { ListViewThumbnailImage } from "../../atoms/ListViewThumbnailImage";
import { StyledTableCell, StyledTableImageCell, StyledTableRow } from "../../atoms/StyledTableParts";
import { useSelectedId } from "../../../hooks/useSelectedId";
import { useClickEventsToActions } from "../../../hooks/useClickEventsToActions";
import { ContainerMenuItem } from "../ContextMenu";
import { ComparableDate, ProcessType, ComparableString, IconInterface } from "../../../common/DisplayInterface.types";
import { createSrc } from "../../../utils/thumbnailUtil";

export interface ProcessesTableComparableItem {
  title: ProcessType;
  updatedAt: ComparableDate;
  status: ComparableString;
}

export interface ProcessesTableItem extends ProcessesTableComparableItem {
  id: string;
  thumbnail?: Thumbnail;
  icon: IconInterface;
}

export interface ProcessesTableProps {
  cdnUrl: string;
  processes: Array<ProcessesTableItem>;
  menuItems: Array<ContainerMenuItem>;
  handlePageTransition: (id: string, title: string) => void;
}

export const ProcessesTable: React.FC<ProcessesTableProps> = (props: ProcessesTableProps) => {
  const { cdnUrl, processes, menuItems, handlePageTransition } = props;
  const dispatch = useAppDispatch();
  // ソート条件
  const { order, orderBy } = useAppSelector(selectProcessesSortOrder);
  const createSortHandler = (property: keyof ProcessesTableComparableItem) => () => {
    dispatch(setProcessesSortOrder({ order: order === "asc" ? "desc" : "asc", orderBy: property as ProcessesOrderBy }));
  };
  // 選択中のID
  const { isSelectedId } = useSelectedId();
  // クリックイベントをアクションにマップする
  const { handleClick, handleDoubleClick, handleContextMenu } = useClickEventsToActions(
    handlePageTransition,
    menuItems,
  );

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>
              <TableSortLabel
                active={orderBy === "title"}
                direction={orderBy === "title" ? order : "asc"}
                onClick={createSortHandler("title")}
              >
                {t("word.名前")}
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
              >
                {t("word.最終更新")}
              </TableSortLabel>
            </StyledTableCell>
            <StyledTableCell>
              <TableSortLabel
                active={orderBy === "status"}
                direction={orderBy === "status" ? order : "asc"}
                onClick={createSortHandler("status")}
              >
                {t("word.ステータス")}
              </TableSortLabel>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processes.sort(getComparator<ProcessesTableComparableItem>(order, orderBy)).map((item) => {
            return (
              <StyledTableRow
                key={item.id}
                onClick={(e) => handleClick(e, item.id, item.title.value)}
                onDoubleClick={(e) => handleDoubleClick(e, item.id, item.title.value)}
                onContextMenu={(e) => handleContextMenu(e, item.id, item.title.value)}
                selected={isSelectedId(item.id)}
              >
                <StyledTableImageCell>
                  {item.thumbnail ? (
                    <ListViewThumbnailImage
                      src={createSrc(cdnUrl, item.thumbnail, "small")}
                      alt={item.title.value}
                      style={
                        item.thumbnail.width / item.thumbnail.height > 1.4142 ? { width: "100%" } : { height: "100%" }
                      }
                    />
                  ) : (
                    item.icon.listView
                  )}
                </StyledTableImageCell>
                <StyledTableCell>{item.title.value}</StyledTableCell>
                <StyledTableCell width="25%">{item.updatedAt.value}</StyledTableCell>
                <StyledTableCell width="25%">{item.status.value}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
