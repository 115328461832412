import React from "react";
import { ReactComponent as LogoImage } from "../../../assets/images/AManageLogo.svg";
import { t } from "i18next";

// SVGの属性を指定可能。サイズを変えたい場合は以下のように書く。(以下の値はAManageLogo.svgのデフォルトサイズ)
// <AppLogo width={101} height={29} />
const AppLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return <LogoImage {...props} title={t("word.アマネージュ")} />;
};

export default AppLogo;
