import React from "react";
import TreeItem from "@mui/lab/TreeItem";

interface NonExpandableTreeItemProps {
  id: string;
  label: JSX.Element;
}

export function NonExpandableTreeItem(props: NonExpandableTreeItemProps) {
  return <TreeItem nodeId={props.id} label={props.label} />;
}
