import React from "react";
import { BaseMessageBar, MessageBarMessageProps } from "../../atoms/BaseMessageBar";
import { CircularProgress } from "@mui/material";

export type ProgressProps = MessageBarMessageProps;

export const ProgressBar = (props: ProgressProps) => {
  const messages = props.messages;
  const icon = <CircularProgress size={14} />;
  const backgroundColor = "#F6F7F9";
  return <BaseMessageBar messages={messages} icon={icon} backgroundColor={backgroundColor} height={props.height} />;
};
