import React from "react";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import { BaseButton } from "./BaseButton";
import { t } from "i18next";

interface PrevButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const PrevButton: React.FC<PrevButtonProps> = (props) => {
  return (
    <div style={{ position: "relative", top: "50%", float: "left", marginLeft: "20px" }}>
      <BaseButton {...props} tooltip={t("word.前へ")} icon={ChevronLeftOutlinedIcon} />
    </div>
  );
};
