import React from "react";
import { ContainerMenuItem, convertToContextMenuItems } from "../components/organisms/ContextMenu";
import { useContextMenu } from "./useContextMenu";
import { useSelectedId } from "./useSelectedId";

/**
 * [リストビュー/サムネイルビュー用]
 * マウスクリックイベントをアクションにマップするhooks
 * （クリックによる選択状態の変更も行う）
 * @param handlePageTransition ページ遷移
 * @param menuItems コンテキストメニュー
 * @returns {handleClick, handleDoubleClick, handleContextMenu}
 *    handleClick onClickハンドラ（左シングルクリック用）
 *    handleDoubleClick onDoubleClickハンドラ（左ダブルクリック用）
 *    handleContextMenu onContextMenuハンドラ（右クリック用）
 */
export const useClickEventsToActions = (
  handlePageTransition: (id: string, title: string) => void,
  menuItems?: Array<ContainerMenuItem>,
): {
  handleClick: (e: React.MouseEvent, id: string, title: string) => void;
  handleDoubleClick: (e: React.MouseEvent, id: string, title: string) => void;
  handleContextMenu: (e: React.MouseEvent, id: string, title: string) => void;
} => {
  // 選択中のID
  const {
    isSelectedId,
    selectedIds,
    setSelectedId,
    toggleSelectedId,
    addFromLastToSelectedId,
    setFromLastToSelectedId,
  } = useSelectedId();

  // 左シングルクリックイベント ⇒ 選択
  const handleClick = (e: React.MouseEvent, id: string): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.ctrlKey && e.shiftKey) {
      addFromLastToSelectedId(id);
    } else if (e.ctrlKey) {
      toggleSelectedId(id);
    } else if (e.shiftKey) {
      setFromLastToSelectedId(id);
    } else {
      setSelectedId(id);
    }
  };
  // 左ダブルクリックイベント ⇒ ページ遷移
  const handleDoubleClick = (e: React.MouseEvent, id: string, title: string): void => {
    e.preventDefault();
    e.stopPropagation();
    handlePageTransition(id, title);
  };

  // コンテキストメニューを表示するフック
  const showContextMenu = useContextMenu();

  // 右クリックイベントハンドラ
  //  menuItemsが渡された場合 ⇒ Context Menu
  //  menuItemsが渡されなかった場合 ⇒ 左シングルクリックと同じ
  const handleContextMenu = (e: React.MouseEvent, id: string): void => {
    // ブラウザデフォルトのコンテキストメニュー表示抑止
    e.preventDefault();
    if (menuItems && menuItems.length > 0) {
      const ids = [...selectedIds];
      if (!isSelectedId(id)) {
        // 右クリックの対象を単独で選択状態にする
        setSelectedId(id);
        ids.length = 0;
        ids.push(id);
      }
      // ContainerMenuItemをContextMenuItemに変換する
      const items = convertToContextMenuItems(menuItems, ids);
      if (items && items.length > 0) {
        showContextMenu({
          position: { x: e.clientX, y: e.clientY },
          handleClose: () => {
            // do nothing
          },
          items: items,
        });
        return;
      }
    } else {
      // 左シングルクリックイベント処理
      handleClick(e, id);
    }
  };

  return { handleClick, handleDoubleClick, handleContextMenu };
};
