import { compareString as ascendingComparator } from "../../utils/sortUtil";
import { DisplayInterface } from "./DisplayInterface";
import { ComparableInterface } from "./ComparableInterface";

export class ComparableString implements ComparableInterface, DisplayInterface {
  constructor(protected readonly displayData: string) {
    this.displayData = displayData ?? "";
  }
  get value(): string {
    return this.displayData;
  }
  compareTo(target: ComparableString): number {
    return this.compareString(this.displayData, target.displayData);
  }

  protected compareString(a: string, b: string): number {
    return ascendingComparator(a, b);
  }
}
