import i18next, { init } from "i18next";

import jaJson from "./locales/ja.json";

void init({
  lng: "ja",
  fallbackLng: "ja",
  resources: { ja: { translation: jaJson } },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18next;
