import React from "react";
import NoImage from "../NoImage";

const ListViewNoImage: React.FC = () => {
  return (
    <NoImage
      width="100%"
      height="100%"
      iconStyle={{
        width: "16px",
        height: "16px",
      }}
    />
  );
};

export default ListViewNoImage;
