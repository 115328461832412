import React, { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { addUploadFileInfo, fileUpload, UploadFileInfo } from "../../modules/api.funcs/fileUpload";
import { getFileSystemFileEntries } from "../../utils/fileAndDirectoryEntriesUtil";
import { useMessage } from "../../hooks/useMessage";
import { t } from "i18next";

// callback関数の型
type OnEachSuccess = (id: string, name: string, uploadedFile: UploadFileInfo) => Array<Promise<string>> | null;
type OnEachError = (id: string, name: string, uploadedFile: UploadFileInfo) => void;
type OnAllSuccess = (
  id: string,
  name: string,
  uploadedFiles: Array<UploadFileInfo>,
  uploadedAllAt: Date,
  uploadedTotal: number,
  succeedingTaskResults: Array<string>,
) => void;
type OnSomeErrors = (
  id: string,
  name: string,
  uploadedFiles: Array<UploadFileInfo>,
  uploadedAllAt: Date,
  uploadedTotal: number,
  uploadFailed: number,
) => void;

// アップロード状態
export interface UploadStatusesItem {
  id: string;
  name: string;
  uploadFiles: Array<UploadFileInfo>;
  uploadedCount: number;
  uploadTotal: number;
  onEachSuccess: OnEachSuccess;
  onEachError: OnEachError;
  onAllSuccess: OnAllSuccess;
  onSomeErrors: OnSomeErrors;
  eachSucceedingTasks: Array<Promise<string>>;
}

// ID毎のアップロード状態（ id は processId / folderId 等）
export interface UploadStatuses {
  [id: string]: UploadStatusesItem;
}

// upload依頼関数のパラメータ
export interface UploadParams {
  event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>; //  uploadイベント(ファイル/フォルダ選択ダイアログ / ファイル/フォルダDrop)
  name: string; // アップロード完了時に表示する「作品名/話数/カット番号/工程名」/「自動トレスフォルダ名」
  onEachSuccess: OnEachSuccess; // アップロード成功時のcallback（1ファイル完了）
  onEachError: OnEachError; // アップロード失敗時のcallback（1ファイル完了）
  onAllSuccess: OnAllSuccess; // アップロード成功時のcallback（全ファイル成功）
  onSomeErrors: OnSomeErrors; // アップロード失敗時のcallback（1ファイル以上失敗）
  isUploadFileType?: (filename: string) => boolean; // アップロード対象ファイル判定関数（指定しない場合は全てアップロード）
}

// コンテキスト
export const UploadContext = createContext<{
  upload: (id: string, params: UploadParams) => Promise<void>;
  statusUpdated: number;
  uploadStatuses: UploadStatuses;
}>({
  upload: () => {
    return new Promise<void>(() => {
      // do nothing
    });
  },
  statusUpdated: 0,
  uploadStatuses: {},
});
UploadContext.displayName = "UploadContext";

// プロバイダのProps
interface UploadProviderProps {
  children: ReactNode;
}
export const UploadProvider: React.FC<UploadProviderProps> = ({ children }) => {
  const uploadStatuses = useRef<UploadStatuses>({});
  const [statusUpdated, setStatusUpdated] = useState<number>(0);
  const showMessage = useMessage();

  const upload = async (id: string, params: UploadParams): Promise<void> => {
    // console.log(`useUpload.upload(id: ${id}) is called.`); // debug
    // イベントの判定
    if ("dataTransfer" in params.event) {
      // dropイベント
      const e = params.event;
      const dataTransferItems = e.dataTransfer.items;
      e.preventDefault();
      e.stopPropagation();
      // ファイル情報取得（次の処理までは同期的に実行）
      const fileEntries: Array<FileSystemFileEntry> = await getFileSystemFileEntries(dataTransferItems);
      // console.log(`useUpload.upload: Drop event (fileEntries.length: ${fileEntries.length} )`); // debug
      if (fileEntries && fileEntries.length > 0) {
        // ファイル読み込み以降は非同期に実行（呼び出し元へ制御を返す）
        void (async () => {
          const uploadFiles: Array<UploadFileInfo> = [];
          for (const fileEntry of fileEntries) {
            if (params.isUploadFileType && !params.isUploadFileType(fileEntry.name)) {
              // アップロードしないファイルなので無視
              continue;
            }
            const file = await new Promise<File>((resolve) => {
              fileEntry.file((f) => resolve(f));
            });
            const path = fileEntry.fullPath.split("/").slice(1, -1).join("/");
            addUploadFileInfo(uploadFiles, id, file, path);
          }
          // console.log(`Dropped Files or Folders -> upload request (count: ${uploadFiles.length})`); // debug
          // console.log(`uploadFiles: ${JSON.parse(JSON.stringify(uploadFiles))}`); // debug
          // 結果を集約するためのオブジェクトに設定
          appendUploadFiles(id, params, uploadFiles);
          // ファイルアップロードする(非同期)
          doUpload(id, params.name, uploadFiles, params.onEachSuccess, params.onEachError);
        })();
      }
    } else {
      // ファイル/フォルダ選択ダイアログイベント
      const e = params.event;
      const selectedFiles = e.target.files;
      e.preventDefault();
      e.stopPropagation();
      if (selectedFiles && selectedFiles.length > 0) {
        const uploadFiles: Array<UploadFileInfo> = [];
        // FileListはイテラブルじゃないのでfor ofが使えない
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          if (params.isUploadFileType && !params.isUploadFileType(file.name)) {
            // アップロードしないファイルなので無視
            continue;
          }
          // ファイル選択とフォルダ選択の違いはwebkitRelativePathに値が設定されているか否か
          // webkitRelativePathは path(には"/"が含まれているかも) + "/" + filename
          const paths = file.webkitRelativePath.split("/");
          const path = paths.splice(0, paths.length - 1).join("/");
          addUploadFileInfo(uploadFiles, id, file, path);
        }
        // console.log(`File or Folder select -> upload request (count: ${uploadFiles.length})`); // debug
        // console.log(`uploadFiles: ${JSON.parse(JSON.stringify(uploadFiles))}`); // debug
        // 結果を集約するためのオブジェクトに設定
        appendUploadFiles(id, params, uploadFiles);
        // ファイルアップロードする(非同期)
        doUpload(id, params.name, uploadFiles, params.onEachSuccess, params.onEachError);
      }
      // もう一度選択可能にするためにクリア
      e.target.value = "";
    }
    // console.log(`useUpload.upload(id: ${id}) return.`); // debug
  };

  // ファイルアップロード
  const doUpload = (
    id: string,
    name: string,
    uploadFiles: Array<UploadFileInfo>,
    onEachSuccess: OnEachSuccess,
    onEachError: OnEachError,
  ): void => {
    // console.log(`useUpload.doUpload(id: ${id}, uploadFiles.length: ${uploadFiles.length}) is called`); // debug
    if (uploadFiles.length > 0) {
      // アップロードするファイルあり
      for (const uploadFile of uploadFiles) {
        const { path, name: filename } = uploadFile;
        const objectName = (path.length > 0 ? path + "/" : "") + filename;
        // アップロードする（非同期で実行。アップロードの完了を待たずに並列実行する）
        fileUpload(
          uploadFile,
          objectName,
          (uploadedFile) => {
            // 1ファイルアップロード成功
            // console.log(`ファイルアップロード成功(upload to "${name}". (filename: ${objectName}, id: ${id}))`); // debug
            incrementUploadedCount(id);
            const succeedingTask = onEachSuccess(id, name, uploadedFile);
            appendSucceedingTask(id, succeedingTask);
          },
          (uploadedFile) => {
            // 1ファイルアップロード失敗
            console.error(
              t("message.ファイルアップロード失敗", {
                name: name,
                objectName: objectName,
                errorMessage: uploadedFile.errorMessage ?? "undefined",
                id: id,
              }),
            );
            incrementUploadedCount(id);
            onEachError(id, name, uploadedFile);
          },
        );
      }
    }
    // console.log(`useUpload.doUpload(id: ${id}) return.`); // debug
  };

  // アップロード状態の集約
  const appendUploadFiles = (id: string, params: UploadParams, uploadFiles: Array<UploadFileInfo>) => {
    // console.log(
    //   `useUpload.appendUploadFiles(id: ${id}, prev: {uploadedCount: ${String(
    //     uploadStatuses.current[id]?.uploadedCount,
    //   )}, uploadTotal: ${String(uploadStatuses.current[id]?.uploadTotal)}}, newRequest: {uploadFiles.length: ${
    //     uploadFiles.length
    //   }})`,
    // ); // debug
    if (!uploadStatuses.current[id]) {
      // 新規にkey/value追加
      uploadStatuses.current[id] = {
        id: id,
        name: params.name,
        uploadFiles: [...uploadFiles],
        uploadedCount: 0,
        uploadTotal: uploadFiles.length,
        onEachSuccess: params.onEachSuccess,
        onEachError: params.onEachError,
        onAllSuccess: params.onAllSuccess,
        onSomeErrors: params.onSomeErrors,
        eachSucceedingTasks: [],
      };
    } else {
      // 既にあるkeyのvalueを更新
      uploadStatuses.current[id].id = id;
      uploadStatuses.current[id].name = params.name;
      uploadStatuses.current[id].uploadFiles = [...uploadStatuses.current[id].uploadFiles, ...uploadFiles];
      // uploadStatuses.current[id].uploadedCount += 0;
      uploadStatuses.current[id].uploadTotal += uploadFiles.length;
      uploadStatuses.current[id].onEachSuccess = params.onEachSuccess;
      uploadStatuses.current[id].onEachError = params.onEachError;
      uploadStatuses.current[id].onAllSuccess = params.onAllSuccess;
      uploadStatuses.current[id].onSomeErrors = params.onSomeErrors;
      // uploadStatuses.current[id].eachSucceedingTasks = [...uploadStatuses.current[id].eachSucceedingTasks];
    }
    // console.log(
    //   `useUpload.appendUploadFiles(id: ${id}, newStatus : {uploadedCount: ${uploadStatuses.current[id].uploadedCount}, uploadTotal: ${uploadStatuses.current[id].uploadTotal}, uploadFile.length: ${uploadStatuses.current[id].uploadFiles.length}})`,
    // ); // debug
    setStatusUpdated((prev) => prev + 1);
  };

  // アップロード完了ファイル数をインクリメント
  const incrementUploadedCount = (id: string): void => {
    if (uploadStatuses.current[id]) {
      uploadStatuses.current[id].uploadedCount += 1;
      // console.log(
      //   `++ useUpload.incrementUploadedCount(id: ${id}, incremented uploadedCount: ${uploadStatuses.current[id].uploadedCount})`,
      // ); // debug
      setStatusUpdated((prev) => prev + 1);
    }
  };

  // 1ファイルアップロード成功時に実行した非同期処理のPromiseを状態に保存（onAllSuccessで結果を渡す）
  const appendSucceedingTask = (id: string, succeedingTask: Array<Promise<string>> | null): void => {
    if (uploadStatuses.current[id] && succeedingTask) {
      uploadStatuses.current[id].eachSucceedingTasks = [
        ...uploadStatuses.current[id].eachSucceedingTasks,
        ...succeedingTask,
      ];
    }
  };

  // 完了判定
  useEffect(() => {
    const completed = Object.entries(uploadStatuses.current).filter(
      (v) => v[1].uploadTotal > 0 && v[1].uploadedCount >= v[1].uploadTotal,
    );
    for (const [id, status] of completed) {
      // console.log(
      //   `check completion. completed(id: ${id}, uploadFiles.length: ${status.uploadFiles.length}, uploadedCount: ${status.uploadedCount}, uploadTotal: ${status.uploadTotal}})`,
      // ); // debug
      // 最後のアップロード完了日時
      const uploadedAllAt = status.uploadFiles.reduce<Date>(
        (pre, cur) => (cur.uploadedAt && pre.getTime() < cur.uploadedAt.getTime() ? cur.uploadedAt : pre),
        new Date(1970, 0, 1),
      );
      // エラーの有無
      let errorCount = 0;
      for (const uploadFile of status.uploadFiles) {
        if (uploadFile.status === "error") {
          errorCount += 1;
        }
      }
      if (errorCount === 0) {
        void (async () => {
          // 後続タスクの完了を待つ
          const results = await Promise.all(status.eachSucceedingTasks);
          showMessage({
            message: t("message.「name」へ uploadedCount ファイル アップロードしました。", {
              name: status.name,
              uploadedCount: status.uploadedCount,
            }),
          });
          // console.log(
          //   `useUpload calls onAllSuccess(id: ${status.id}, uploadFiles.length: ${status.uploadFiles.length}, uploadedCount: ${status.uploadedCount})`,
          // ); // debug
          status.onAllSuccess(status.id, status.name, status.uploadFiles, uploadedAllAt, status.uploadedCount, results);
          // ページ側で表示できなくならないように削除は完了通知の後に行う
          // console.log(`useUpload completed and delete status with delay (id: ${id})`); // debug
          delete uploadStatuses.current[id];
          setStatusUpdated((prev) => prev + 1);
        })();
      } else {
        showMessage({
          message: t("message.「name」へのアップロードが完了しましたが、アップロードできなかったものがあります。", {
            name: status.name,
          }),
        });
        status.onSomeErrors(
          status.id,
          status.name,
          status.uploadFiles,
          uploadedAllAt,
          status.uploadedCount,
          errorCount,
        );
        // console.log(
        //   `useUpload completed ${status.uploadedCount} files with ${errorCount} errors and delete status (id: ${id})`,
        // ); // debug
        delete uploadStatuses.current[id];
        setStatusUpdated((prev) => prev + 1);
      }
    }
  }, [showMessage, statusUpdated]);

  const contextValue = { upload, statusUpdated, uploadStatuses: uploadStatuses.current };
  return <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>;
};
