import React, { useEffect, useRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";

type AutoFocusTextFieldProps = TextFieldProps;

export const AutoFocusTextField: React.FC<AutoFocusTextFieldProps> = (props) => {
  const { ...textFieldProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  // dialogの中にtextFieldがある場合、StrictModeの影響でautoFocusが効かないためRefで対応
  // 参考：https://github.com/mui/material-ui/issues/33004
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, []);

  return <TextField {...textFieldProps} inputRef={inputRef} />;
};
