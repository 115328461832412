import { ProcessType } from "../common/DisplayInterface.types";
import { t } from "i18next";

interface ProcessFileInfo {
  type: string;
  latestFileUpdatedAt: string | null;
}

/**
 * ファイルがアップロードされている工程順で最後の工程名を返す
 * @param {Array<ProcessFileInfo> | undefined} processFiles 工程のファイル情報
 * @returns {string}
 *       工程の情報が無ければ（Chip表示対応前の古いカット） ""。
 *       ファイルがアップロードされている工程があれば、そのうちの工程順で最後の工程名。
 *       ファイルがアップロードされている工程がなければ 「未納」。
 */
export const selectLatestFileUploadedProcessType = (processFiles: Array<ProcessFileInfo> | undefined): string => {
  if (!processFiles) {
    // 工程のファイル情報がない
    return "";
  }
  // 工程を降順に並べ、latestFileUpdatedAtが設定されている最新工程を取得
  const latestProcess = processFiles
    .map<{ type: ProcessType; latestFileUpdatedAt: string | null }>((v) => ({
      type: new ProcessType(v.type),
      latestFileUpdatedAt: v.latestFileUpdatedAt,
    }))
    .sort((a, b) => b.type.compareTo(a.type))
    .find((v) => v.latestFileUpdatedAt !== null);
  return latestProcess ? latestProcess.type.value : t("word.未納");
};
