import React from "react";
import { Box } from "@mui/material";
import { ProgressCircular } from "../../atoms/Progress/ProgressCircular";
import { useExportsJob } from "../../../hooks/useExportsJob";
import { t } from "i18next";

interface DownloadListItemProps {
  title: string;
  jobId: string;
  onComplete: (jobId: string) => void;
  onError: (jobId: string) => void;
}

export const DownloadListItem: React.FC<DownloadListItemProps> = (props) => {
  const { title, ...rest } = props;
  const { progress } = useExportsJob({ ...rest });
  return (
    <>
      {progress < 100 ? (
        <Box style={{ width: "330px" }}>{t("message.「title」を圧縮しています", { title })}</Box>
      ) : (
        <Box style={{ width: "330px" }}>{t("message.「title」をダウンロード中です", { title })}</Box>
      )}
      <ProgressCircular progress={progress} iconStyle={{ position: "absolute", top: "20px", right: "15px" }} />
    </>
  );
};
