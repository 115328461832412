import React, { useEffect, useState } from "react";
import { FormGroup } from "@mui/material";
import { FormDialog } from "../../molecules/FormDialog";
import { useAddCutMutation, useAddProcessMutation } from "../../../modules/api";
import { useMessage } from "../../../hooks/useMessage";
import { AddCutRequest, AddProcessRequest } from "../../../modules/api.types";
import { compareString } from "../../../utils/sortUtil";
import { AutoFocusTextField } from "../../atoms/AutoFocusTextField";
import { t } from "i18next";

interface AddCutDialogProps {
  open: boolean;
  episodeId: string;
  cutTitles: Array<{ title: string }>;
  onClose: () => void;
}

const num2str = (n: number): string => {
  return String(n).padStart(3, "0");
};

export const AddCutDialog: React.FC<AddCutDialogProps> = (props) => {
  const { open, episodeId, cutTitles, onClose } = props;
  const minNo = 1;
  const [newNo, setNewNo] = useState<string>(num2str(minNo));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const showMessage = useMessage();
  const [addCut] = useAddCutMutation();
  const [addProcess] = useAddProcessMutation();

  // newNoの初期値設定
  useEffect(() => {
    if (cutTitles.length > 0) {
      const last = cutTitles
        .map((item) => {
          return { title: item.title.match(/[0-9]+/)?.at(0) ?? "0" };
        })
        .sort((a, b) => compareString(b.title, a.title))
        .at(0);
      setNewNo(num2str((last?.title ? Number.parseInt(last.title) : 0) + 1));
    } else {
      setNewNo(num2str(minNo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (title: string | undefined): string | null => {
    // console.log({ no }); // debug
    if (!title || title.length === 0) return t("message.カットフォルダ名を入力してください");

    // 入力が数字のみの場合は同一番号のカットが存在しないか数字に変換して比較する
    if (title.replace(/^\d+$/, "") === "") {
      const inputTitleDigits = String(Number.parseInt(title));
      const titleDigits = (title: string): string =>
        title.replace(/^\d+$/, "") === "" ? String(Number.parseInt(title)) : title;
      const registeredCutTitle = cutTitles.find((v) => titleDigits(v.title) === inputTitleDigits);
      if (registeredCutTitle) {
        return t("message.number があるので登録できません", { number: registeredCutTitle.title });
      }
    }
    return cutTitles.every((item) => item.title !== title)
      ? null
      : t("message.number があるので登録できません", { number: title });
  };

  const handleChangeNo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const no = e.target.value;
    // console.log(`handleChangeNo to "${String(no)}"`); // debug
    setNewNo(no);
    const result = validate(no);
    setErrorMessage(result);
  };

  const addDefaultProcess = (cutId: string): boolean => {
    const defaultProcesses: Array<AddProcessRequest> = [
      { cutId: cutId, process: { type: t("word.レイアウト") } },
      { cutId: cutId, process: { type: t("word.原画") } },
      { cutId: cutId, process: { type: t("word.動画") } },
      { cutId: cutId, process: { type: t("word.仕上げ") } },
    ];
    for (const process of defaultProcesses) {
      addProcess(process)
        .unwrap()
        .then(() => {
          // do nothing
        })
        .catch((addProcessError: unknown) => {
          console.log({ addProcessError });
          // return false; エラーが発生しても全工程の追加を試みる
        });
    }
    return true;
  };
  const addCutWithDefaultProcesses = (newCut: AddCutRequest) => {
    addCut(newCut)
      .unwrap()
      .then((addCutResp) => {
        // console.log({ addCutResp }); // debug
        const cutId = addCutResp.cutId;
        if (addDefaultProcess(cutId)) {
          showMessage({ message: t("message.msgTitle を追加しました。", { msgTitle: newNo }) });
        } else {
          showMessage({ message: t("message.msgTitleの工程の追加に失敗しました。", { msgTitle: newNo }) });
        }
      })
      .catch((addCutError: unknown) => {
        console.log({ addCutError });
        showMessage({ message: t("message.msgTitle の追加に失敗しました。", { msgTitle: newNo }) });
      });
  };

  const onSubmit = () => {
    // submitボタンは押せないはずだが念のため確認だけする
    const err = validate(String(newNo));
    if (errorMessage || err) {
      return;
    }
    // console.log(`ADD an Cut( newNo: ${newNo})`); // debug
    addCutWithDefaultProcesses({ episodeId: episodeId, cut: { title: String(newNo) } });
    // 閉じる
    onClose();
  };

  return (
    <FormDialog
      open={open}
      title={t("phrase.カットを追加")}
      submitButtonText={t("word.追加")}
      submitButtonDisabled={errorMessage !== null}
      onSubmit={onSubmit}
      onCancel={() => onClose()}
    >
      <FormGroup>
        <AutoFocusTextField
          id="add-cut-new-title"
          sx={{ marginTop: "10px", height: "64px", width: "300px" }}
          type={"text"}
          inputProps={{ "aria-label": "cut-number-of-additional-cut" }}
          value={newNo}
          error={errorMessage ? true : false}
          helperText={errorMessage}
          onChange={(e) => handleChangeNo(e)}
        />
      </FormGroup>
    </FormDialog>
  );
};
