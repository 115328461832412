import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";

export interface ConfirmDialogButton {
  text: string;
  onClick: () => void;
  defaultButton: boolean;
}
export interface ConfirmDialogProps {
  title: string;
  message: string | string[];
  onClose: () => void;
  buttons: Array<ConfirmDialogButton>;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props: ConfirmDialogProps) => {
  const { title, message, onClose, buttons } = props;
  const [open, setOpen] = useState(true);
  const defaultButton = buttons.find((button) => button.defaultButton);
  if (!defaultButton) {
    // 一つはdefaultButtonが必要
    console.log("ConfirmDialog: no default button."); // debug
    return null;
  }
  const OnClickDefaultButton = defaultButton.onClick;

  return (
    <Dialog
      open={open}
      onClose={() => {
        OnClickDefaultButton();
        setOpen(false);
        onClose();
      }}
      fullWidth
      maxWidth="xs"
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      onDragOver={(e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "none";
      }}
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {typeof message === "string"
            ? message
            : message.map((v, i, a) => (
                <React.Fragment key={i}>
                  {v}
                  {a.length > i + 1 ? <br /> : null}
                </React.Fragment>
              ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons.map((button, index) => (
          <Button
            key={`confirm-dialog-button-${index}`}
            onClick={() => {
              button.onClick();
              setOpen(false);
              onClose();
            }}
            autoFocus={button.defaultButton}
          >
            {button.text}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
