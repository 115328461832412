import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

interface CardViewItemBarTextProps {
  children: React.ReactNode;
  fontSize?: string;
}

const StyledContainer = styled("div")<{ fontSize: string }>(({ fontSize }) => ({
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontSize: fontSize,
  textAlign: "center",
}));

const CardViewItemBarText: React.FC<CardViewItemBarTextProps> = (props) => {
  const { children, fontSize = "14px" } = props;
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (elementRef.current && elementRef.current.offsetWidth < elementRef.current.scrollWidth && !isEllipsis) {
      setIsEllipsis(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef]);
  return (
    <>
      {isEllipsis ? (
        <Tooltip title={String(children)}>
          <StyledContainer fontSize={fontSize} ref={elementRef}>
            {children}
          </StyledContainer>
        </Tooltip>
      ) : (
        <StyledContainer fontSize={fontSize} ref={elementRef}>
          {children}
        </StyledContainer>
      )}
    </>
  );
};

export default CardViewItemBarText;
