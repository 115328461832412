import React from "react";
import { IconInterface } from "./IconInterface";
import { ContentIcon, FolderWithFileIcon, FolderIcon as EmptyFolderIcon } from "../../components/atoms/ContentIcons";
import { ListViewIcon } from "../../components/atoms/ListViewIcon";

export class FolderIcon implements IconInterface {
  protected listViewIcon: React.ReactNode = null;
  protected cardViewIcon: React.ReactNode = null;
  protected iconStyleSize: React.CSSProperties = {};
  constructor(protected readonly isUploaded: boolean) {
    // リストビュー用アイコン
    if (this.isUploaded) {
      //中身ありフォルダアイコン
      this.listViewIcon = <ListViewIcon icon={FolderWithFileIcon} />;
    } else {
      //中身なしフォルダアイコン
      this.listViewIcon = <ListViewIcon icon={EmptyFolderIcon} />;
    }
  }
  setSize(iconStyleSize: React.CSSProperties): IconInterface {
    this.iconStyleSize = iconStyleSize;
    // カードビュー用アイコン
    if (this.isUploaded) {
      //中身ありフォルダアイコン
      this.cardViewIcon = <ContentIcon icon={FolderWithFileIcon} iconStyle={this.iconStyleSize} />;
    } else {
      //中身なしフォルダアイコン
      this.cardViewIcon = <ContentIcon icon={EmptyFolderIcon} iconStyle={this.iconStyleSize} />;
    }
    return this;
  }
  get listView(): React.ReactNode {
    return this.listViewIcon;
  }
  get cardView(): React.ReactNode {
    return this.cardViewIcon;
  }
}
