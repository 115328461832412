import React, { createContext, useState } from "react";
import { createPortal } from "react-dom";
import { ConfirmDialog, ConfirmDialogButton } from "../../components/organisms/ConfirmDialog";
import { useLeaveLocation } from "../useLeaveLocation";

export interface ConfirmDialogArgs {
  title: string;
  message: string | string[];
  buttons: Array<ConfirmDialogButton>;
  ignoreLocationChanges?: boolean;
}

// export for testWrapper
export const ConfirmDialogContext = createContext<(confirmDialogArgs: ConfirmDialogArgs) => void>(() => {
  // do nothing
});
ConfirmDialogContext.displayName = "ConfirmDialogContext";

export const ConfirmDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmDialogArgs, setConfirmDialogArgs] = useState<ConfirmDialogArgs>({
    title: "",
    message: "",
    buttons: [],
  });
  const setOpenedLocationPath = useLeaveLocation(() => {
    // console.log("ConfirmDialogProvider.useLeaveLocation.onLeave is called"); // debug
    setOpenConfirmDialog(false);
  });
  const showConfirmDialog = ({ title, message, buttons, ignoreLocationChanges }: ConfirmDialogArgs): void => {
    // console.log(
    //   `showConfirmDialog is called with {title: ${title}, message: ${String(message)}, buttons: {length: ${
    //     buttons.length
    //   }}, ignoreLocationChanges: ${String(ignoreLocationChanges)}}, and current location: ${String(
    //     window.location.pathname,
    //   )}.`,
    // ); // debug
    setConfirmDialogArgs({ title, message, buttons });
    if (ignoreLocationChanges) {
      setOpenedLocationPath(null);
    } else {
      setOpenedLocationPath(window.location.pathname);
    }
    setOpenConfirmDialog(true);
  };
  return (
    <ConfirmDialogContext.Provider value={showConfirmDialog}>
      {children}
      {openConfirmDialog
        ? createPortal(
            <ConfirmDialog
              {...confirmDialogArgs}
              onClose={() => {
                setOpenConfirmDialog(false);
                setOpenedLocationPath(null);
              }}
            />,
            document.getElementById("root") as HTMLElement,
          )
        : null}
    </ConfirmDialogContext.Provider>
  );
};
