import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortOrder } from "../utils/sortUtil";
import { RootState } from "./store";

export type viewMode = "list" | "thumbnail";

export type EpisodesOrderBy = "title" | "updatedAt";
export type CutsOrderBy = "title" | "updatedAt" | "status";
export type ProcessesOrderBy = "title" | "updatedAt" | "status";
export type CutOrderBy = "title" | "updatedAt";

export interface ViewConfigState {
  episodes: {
    viewMode: viewMode;
    sort: {
      order: SortOrder;
      orderBy: EpisodesOrderBy;
    };
  };
  cuts: {
    viewMode: viewMode;
    sort: {
      order: SortOrder;
      orderBy: CutsOrderBy;
    };
  };
  processes: {
    viewMode: viewMode;
    sort: {
      order: SortOrder;
      orderBy: ProcessesOrderBy;
    };
  };
  cut: {
    viewMode: viewMode;
    sort: {
      order: SortOrder;
      orderBy: CutOrderBy;
    };
  };
}

const initialState: ViewConfigState = {
  episodes: {
    viewMode: "list",
    sort: {
      order: "asc",
      orderBy: "title",
    },
  },
  cuts: {
    viewMode: "list",
    sort: {
      order: "asc",
      orderBy: "title",
    },
  },
  processes: {
    viewMode: "list",
    sort: {
      order: "asc",
      orderBy: "title",
    },
  },
  cut: {
    viewMode: "list",
    sort: {
      order: "asc",
      orderBy: "title",
    },
  },
};

const slice = createSlice({
  name: "viewConfig",
  initialState,
  reducers: {
    initViewConfig: () => initialState,
    // 各ページのビュー（リスト／サムネイル）切り替え
    setEpisodesViewMode: (state, action: PayloadAction<{ view: viewMode }>) => {
      state.episodes = {
        ...state.episodes,
        viewMode: action.payload.view,
      };
    },
    setCutsViewMode: (state, action: PayloadAction<{ view: viewMode }>) => {
      state.cuts = {
        ...state.cuts,
        viewMode: action.payload.view,
      };
    },
    setProcessesViewMode: (state, action: PayloadAction<{ view: viewMode }>) => {
      state.processes = {
        ...state.processes,
        viewMode: action.payload.view,
      };
    },
    setCutViewMode: (state, action: PayloadAction<{ view: viewMode }>) => {
      state.cut = {
        ...state.cut,
        viewMode: action.payload.view,
      };
    },
    // 各ページのソート条件(方向、項目)切り替え
    setEpisodesSortOrder: (state, action: PayloadAction<{ order: SortOrder; orderBy: EpisodesOrderBy }>) => {
      state.episodes = {
        ...state.episodes,
        sort: {
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      };
    },
    setCutsSortOrder: (state, action: PayloadAction<{ order: SortOrder; orderBy: CutsOrderBy }>) => {
      state.cuts = {
        ...state.cuts,
        sort: {
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      };
    },
    setProcessesSortOrder: (state, action: PayloadAction<{ order: SortOrder; orderBy: ProcessesOrderBy }>) => {
      state.processes = {
        ...state.processes,
        sort: {
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      };
    },
    setCutSortOrder: (state, action: PayloadAction<{ order: SortOrder; orderBy: CutOrderBy }>) => {
      state.cut = {
        ...state.cut,
        sort: {
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      };
    },
  },
});

export const viewConfigReducer = slice.reducer;

export const {
  initViewConfig,
  setEpisodesViewMode,
  setCutsViewMode,
  setProcessesViewMode,
  setCutViewMode,
  setEpisodesSortOrder,
  setCutsSortOrder,
  setProcessesSortOrder,
  setCutSortOrder,
} = slice.actions;

export const selectEpisodesViewMode = (state: RootState) =>
  state.viewConfig.episodes?.viewMode ?? initialState.episodes.viewMode;
export const selectCutsViewMode = (state: RootState) => state.viewConfig.cuts?.viewMode ?? initialState.cuts.viewMode;
export const selectProcessesViewMode = (state: RootState) =>
  state.viewConfig.processes?.viewMode ?? initialState.processes.viewMode;
export const selectCutViewMode = (state: RootState) => state.viewConfig.cut?.viewMode ?? initialState.cut.viewMode;

export const selectEpisodesSortOrder = (state: RootState) =>
  state.viewConfig.episodes?.sort ?? initialState.episodes.sort;
export const selectCutsSortOrder = (state: RootState) => state.viewConfig.cuts?.sort ?? initialState.cuts.sort;
// viewConfig.processes.sort.orderBy の型 ProcessesOrderBy の "typeId" を "title" に変更。
// "typeId" が local storage に保存されていた場合、存在しない"typeId"へアクセスすることを抑止。
export const selectProcessesSortOrder = (state: RootState) =>
  state.viewConfig.processes?.sort
    ? (state.viewConfig.processes.sort.orderBy as string) === "typeId"
      ? { ...state.viewConfig.processes.sort, orderBy: "title" as ProcessesOrderBy }
      : state.viewConfig.processes.sort
    : initialState.processes.sort;
export const selectCutSortOrder = (state: RootState) => state.viewConfig.cut?.sort ?? initialState.cut.sort;
