// File and Directory Entries API 用の関数群

// readEntries関数は同期的に動くが時間がかかるのでasync関数でラップする
//   readEntriesの第1引数は成功時のcallback関数で、この関数の引数はFileSystemEntryの配列。
//   readEntriesはディレクトリ内のファイル情報を一度に全部返してくれるわけではなく、
//   成功時のcallback関数の引数の配列が[]になるまで呼び出さなければならない。
const readEntriesAsync = async (directoryReader: FileSystemDirectoryReader): Promise<Array<FileSystemEntry>> => {
  return await new Promise((resolve) => {
    directoryReader.readEntries(resolve);
  });
};

// ファイルあるいはディレクトリを１つ処理する関数（再帰的に使う）
const readFileSystemEntry = async (entry: FileSystemEntry, fileEntries: Array<FileSystemFileEntry>): Promise<void> => {
  if (entry.isDirectory) {
    const directoryEntry = entry as FileSystemDirectoryEntry;
    // console.log({ directoryEntry }); // debug
    const directoryReader = directoryEntry.createReader();
    const fsEntries: Array<FileSystemEntry> = [];
    let tempEntries: Array<FileSystemEntry> = [];
    do {
      fsEntries.push(...tempEntries);
      tempEntries = await readEntriesAsync(directoryReader);
    } while (tempEntries.length > 0);
    // console.log(`Directory(${directoryEntry.fullPath}) has ${fsEntries.length} file or folder entries.`); // debug
    // ディレクトリ内のファイル情報取得を待つ
    await Promise.all(fsEntries.map((entry) => readFileSystemEntry(entry, fileEntries)));
  } else if (entry.isFile) {
    const fileEntry = entry as FileSystemFileEntry;
    // console.log({ fileEntry }); // debug
    fileEntries.push(fileEntry);
  }
};

/**
 * ドロップされたファイルの情報を取得
 *   ドロップイベントのevent.dataTransfer.itemsからFileSystemFileEntryの配列を取得する
 *   ただし、直接ドロップされたフォルダ内のファイルまでで、フォルダを再帰的に潜ることはしない
 * @param items: DataTransferItemList
 * @returns Promise<Array<FileSystemFileEntry>>
 */
export const getFileSystemFileEntries = async (items: DataTransferItemList): Promise<Array<FileSystemFileEntry>> => {
  // console.log(`getFileSystemFileEntries is called with items: {length: ${items.length}}`); // debug
  const fileEntries: Array<FileSystemFileEntry> = [];
  const readEntries = [];
  for (let i = 0; i < items.length; i++) {
    const entry = items[i].webkitGetAsEntry();
    entry && readEntries.push(readFileSystemEntry(entry, fileEntries));
  }
  await Promise.all(readEntries);
  return fileEntries;
};
