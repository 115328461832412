import React from "react";
import ListViewNoImage from "../../components/atoms/ListViewNoImage";
import NoImage from "../../components/atoms/NoImage";
import { IconInterface } from "./IconInterface";

export class NoImageIcon implements IconInterface {
  protected listViewIcon: React.ReactNode = null;
  protected cardViewIcon: React.ReactNode = null;
  protected iconStyleSize: React.CSSProperties = {};
  protected width = 0;
  protected height = 0;
  constructor() {
    // リストビュー用アイコン
    this.listViewIcon = <ListViewNoImage />;
  }
  setSize(iconStyleSize: React.CSSProperties, width: number, height: number): IconInterface {
    this.iconStyleSize = iconStyleSize;
    this.width = width;
    this.height = height;
    // カードビュー用アイコン
    this.cardViewIcon = (
      <NoImage width={`${this.width}px`} height={`${this.height}px`} iconStyle={this.iconStyleSize} />
    );
    return this;
  }
  get listView(): React.ReactNode {
    return this.listViewIcon;
  }
  get cardView(): React.ReactNode {
    return this.cardViewIcon;
  }
}
